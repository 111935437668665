import { Athlete } from './../classes/Athlete/Athlete';
import { FilteredOrderedListRequest } from './../table-directives/filtered-ordered-list-request';
import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LaufAthleteEditService {

  constructor(private http: HttpClient) { }

  listNations() {
    return this.http.get(environment.nationLink, {}).pipe(first());
  }
  listDisciplines() {
    return this.http.post(environment.baseUrl + 'disciplines/list-by-event/{:eventId}', new FilteredOrderedListRequest(), {})
                    .pipe(first());
  }
  get(id: number) {
    return this.http.get(environment.baseUrl + 'athletes/' + id, {}).pipe(first());
  }
  edit(value: Athlete) {
    return this.http.put(environment.baseUrl + 'athletes' , value, {}).pipe(first());
  }
  add(value: Athlete) {
    return this.http.post(environment.baseUrl + 'athletes' , value, {}).pipe(first());
  }
  listTeams(){
    return this.http.get(environment.baseUrl + 'athletes/get-teamnames-by-event/{:eventId}', {}).pipe(first());
  }
}
