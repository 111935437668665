export class EventTranslation  {
    translation: any = {
    'IMAGE_SIZE_ATTENTION_TEXT' : 'Das Logo darf maximal 160px breit und 50px hoch sein. Erlaubte Formate sind Jpeg und PNG.',
    'EVENT_STAMMDATEN' : 'Event Stammdaten',
    'BITTE_BEZEICHNUNG_EINGEBEN' : 'Bitte geben Sie eine Bezeichnung für das Event ein.',
    'BEZEICHNUNG' : 'Bezeichnung',
    'STARTTIME' : 'Startdatum',
    'ENDTIME' : 'Enddatum',
    'WEBSMS_PHONENUMBER' : 'Websms Telefonnummer',
    'TEILNEHMER_URL' : 'Race-Result Teilnehmerurl'
    };
}
