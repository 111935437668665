export class LaufverwaltungSponsorsTranslation {
    translation = {
        de: {
            'EDIT_SPONSOR' : 'Sponsor bearbeiten',
            'NAME_ALERT' : 'BItte geben Sie einen Namen an',
            'WEBSITE_ALERT' : 'Bitte geben Sie eine Webseite an',
            'LOGOALERT' : 'Bitte wählen Sie ein Logo aus',
            'LOGO' : 'Logo'
        }
      };
}
