import { environment } from './../../environments/environment';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Discipline } from '../classes/Disciplines/Discipline';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

@Injectable({
  providedIn: 'root'
})
export class DisciplineListService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  public list(filteredOrderedListRequest: FilteredOrderedListRequest): Observable<Discipline[]>{
    return this.http.post<Discipline[]>(this.baseUrl + 'disciplines/list-by-event/{:eventId}', filteredOrderedListRequest);
  }

  public delete(disciplineId: number) {
    return this.http.delete(this.baseUrl + 'disciplines/' + disciplineId);
  }
}
