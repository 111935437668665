import { User } from './../classes/Users/User';
import { EventSelectorService } from './../global-services/event-selector.service';
import { Router } from '@angular/router';
import { AuthenticationFactoryService } from './../global-services/authentication-factory.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { UserEditService } from './user-edit.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-user-edit',
  templateUrl: './page-user-edit.component.html',
  styleUrls: ['./page-user-edit.component.scss']
})
export class PageUserEditComponent implements OnInit, OnDestroy {

  public role  = ['ADMIN', 'EVENTMANAGER', 'RESCUER', 'PRESENTER', 'SOCIALMEDIAPRESENTER'];

  private sub: Subscription;
  private eventSubscription: Subscription;

  public id: number;
  public editUser: any;
  private user: any;

  public mandantList: any[];

  public deviceList: any[];
  public locationList: any[];
  public indicatorList: any[];

  public triedToSubmit = false;

  public activeUserIsAdmin = false;

  public draggables = {
    effectAllowed: 'move',
    disable: false,
    handle: false,
  };


  constructor(private fb: FormBuilder,
    private toasterService: ToasterService,
    private userService: UserEditService,
    private router: Router,
    private eventService: EventSelectorService,
    private authFactory: AuthenticationFactoryService,
    private route: ActivatedRoute) {

      this.editUser = this.fb.group({
        'id': [0, Validators.compose([])],
        'username': ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
        'role': ['', Validators.compose([])],
        'password': this.fb.group({
          'password': ['', Validators.compose([Validators.required])],
          'password_repeat': ['', Validators.compose([Validators.required])]
        }),
        'surename': ['', Validators.compose([Validators.maxLength(255)])],
        'lastname': ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
        'phone': ['', Validators.compose([Validators.maxLength(255)])],
        'postcode':['', Validators.compose([Validators.maxLength(5)])],
        'town':['', Validators.compose([Validators.maxLength(255)])],
        'street':['', Validators.compose([Validators.maxLength(255)])],
        'housenumber':['', Validators.compose([Validators.maxLength(255)])],
        'email':['', Validators.compose([Validators.maxLength(255)])],
        'eventId': [0, Validators.compose([])]
      });
    }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];

      if (this.id !== 0) {
        this.loadUser(this.id);

        this.editUser.controls.password.controls.password.setValidators(null);
        this.editUser.controls.password.controls.password_repeat.setValidators(null);
        this.editUser.controls.password.controls.password.updateValueAndValidity();
        this.editUser.controls.password.controls.password_repeat.updateValueAndValidity();
      } else {
      }
     });

     this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId) => {
      this.editUser.patchValue({eventId: eventId});
     });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

  loadUser(id: number) {
      this.userService.get(id).subscribe((success: User) => {
        this.editUser.patchValue(success);
      });
  }

  changeUser() {
    const value = this.editUser.value;
    const valid = this.editUser.valid;

    if (valid) {
      value.password = value.password.password;
      // Do something
      if (value.id === 0) {
        this.userService.add(value).subscribe((success) => {
          this.toasterService.pop('success', 'User wurde erfolgreich gespeichert!', '');
          this.router.navigate(['users']);
        });
      } else {
        this.userService.edit(value).subscribe((success) => {
          this.toasterService.pop('success', 'User wurde erfolgreich gespeichert!', '');
          this.router.navigate(['users']);
        });
      }
    } else {
      console.log(this.editUser);
      this.toasterService.pop('error', 'Füllen Sie alle benötigten Felder aus', '');
    }

    this.triedToSubmit = true;
   }

   passwordChanged(obj) {
    this.editUser.controls.password = obj;
    this.editUser.updateValueAndValidity();
  }

}
