import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { User } from '../classes/Users/User';

@Injectable({
  providedIn: 'root'
})
export class UserEditService {

  constructor(private http: HttpClient) {

  }

  public get(id: number) {
    return this.http.get(environment.baseUrl + 'users/' + id, {}).pipe(first());
  }
  public add(value: User) {
    return this.http.post(environment.baseUrl + 'users', value, {}).pipe(first());
  }
  public edit(value: User) {
    return this.http.put(environment.baseUrl + 'users', value, {}).pipe(first());
  }
}
