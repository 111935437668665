import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './global-services/authentication.service';
import { AuthenticationFactoryService } from './global-services/authentication-factory.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isLoggedInState: boolean;
  public showBreadcrumb: boolean;
  public containerType: string;

  private authenticationService: AuthenticationService;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private authenticationFactory: AuthenticationFactoryService) {
    this.isLoggedInState = false;

    this.authenticationService = authenticationFactory.getAuthenticationService();
    this.authenticationService.isAuthenticated().subscribe(success => {
      this.isLoggedInState = success;
    }, error => {});

    router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        if(e.url.startsWith('/login')){
          this.isLoggedInState = false;
        }

        if(this.activatedRoute.children.length > 0){
          this.showBreadcrumb = this.activatedRoute.children[0].snapshot.data.show_breadcrumbs;
          this.containerType = this.activatedRoute.children[0].snapshot.data.container_type;
        }
      }
    });
  }
}
