export class LaufmanagementCheckpointsTranslation {
    translation = {
        de: {
            'BITTE_BEZEICHNUNG_EINGEBEN' : 'Bitte Bezeichnung eingeben',
            'BEZEICHNUNG' : 'Bezeichnung',
            'TOILETTEN' : 'Toiletten',
            'CATERING' : 'Verpflegung',
            'POSITION' : 'Position',
            'AKTIONS' : 'Aktions',
            'CHECKPOINT_STAMMDATEN' : ' Stammdaten Checkpoint',
            'BEZEICHNUNG_ALERT' : 'Bitte geben Sie einen Namen für den Checkpoint an'
        }
      };
}
