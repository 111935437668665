import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthorizationGuard } from './guards/authorization.guard';

import { PageLoginComponent } from './page-login/page-login.component';
import { PageLaufDisciplineEditComponent } from './page-lauf-discipline-edit/page-lauf-discipline-edit.component';
import { PageLaufCheckpointmanagerEditComponent } from './page-lauf-checkpointmanager-edit/page-lauf-checkpointmanager-edit.component';
import { PageLaufCheckpointEditComponent } from './page-lauf-checkpoint-edit/page-lauf-checkpoint-edit.component';
import { PageLaufAthleteEditComponent } from './page-lauf-athlete-edit/page-lauf-athlete-edit.component';
import { PageLaufverwaltungComponent } from './page-laufverwaltung/page-laufverwaltung.component';
import { PageLaufEditComponent } from './page-lauf-edit/page-lauf-edit.component';
import { PageLaufAthleteListComponent } from './page-lauf-athlete-list/page-lauf-athlete-list.component';
import { PageLaufDisciplineListComponent } from './page-lauf-discipline-list/page-lauf-discipline-list.component';
import { PageLaufCheckpointListComponent } from './page-lauf-checkpoint-list/page-lauf-checkpoint-list.component';
import { PageLaufCheckpointmanagerListComponent } from './page-lauf-checkpointmanager-list/page-lauf-checkpointmanager-list.component';
import { PageLaufAthleteImportComponent } from './page-lauf-athlete-import/page-lauf-athlete-import.component';
import { PageLaufContactListComponent } from './page-lauf-contact-list/page-lauf-contact-list.component';
import { PageLaufContactEditComponent } from './page-lauf-contact-edit/page-lauf-contact-edit.component';
import { PageLaufSponsorListComponent } from './page-lauf-sponsor-list/page-lauf-sponsor-list.component';
import { PageLaufSponsorEditComponent } from './page-lauf-sponsor-edit/page-lauf-sponsor-edit.component';
import { PageLaufGpsDeviceAllocationComponent } from './page-lauf-gps-device-allocation/page-lauf-gps-device-allocation.component';
import { PageUserEditComponent } from './page-user-edit/page-user-edit.component';
import { PageGpsDeviceListComponent } from './page-gps-device-list/page-gps-device-list.component';
import { PageGpsDeviceEditComponent } from './page-gps-device-edit/page-gps-device-edit.component';
import { PageNotificationsComponent } from './page-notifications/page-notifications.component';
import { PageSmsProtocolComponent } from './page-sms-protocol/page-sms-protocol.component';
import { PageLiveAthleteListComponent } from './page-live-athlete-list/page-live-athlete-list.component';
import { PageLiveDisciplineDetailsComponent } from './page-live-discipline-details/page-live-discipline-details.component';
import { PageLiveCheckpointDetailsComponent } from './page-live-checkpoint-details/page-live-checkpoint-details.component';
import { PageLiveAthleteRecordingComponent } from './page-live-athlete-recording/page-live-athlete-recording.component';
import { PageUserListComponent } from './page-user-list/page-user-list.component';
import { PageLiveDashboardComponent } from './page-live-dashboard/page-live-dashboard.component';

const routes: Routes = [
  {path: 'laufverwaltung',
    component: PageLaufverwaltungComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Laufverwaltung',
            eventIdNeeded : true,
            show_breadcrumbs: true,
            container_type: 'fluid'
    }
  },

  {path: 'laufverwaltung/edit/:id',
    component: PageLaufEditComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Lauf Bearbeiten',
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/athletes',
    component: PageLaufAthleteListComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Athleten',
            eventIdNeeded : true,
            button: {
              title: 'Neuer Athlet',
              url: 'laufverwaltung/athletes/edit/0',
              icon: 'ti-plus'
            },
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/athletes/edit/:athleteId',
    component: PageLaufAthleteEditComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            eventIdNeeded : true,
            title: 'Athleten',
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/athletes/import',
    component: PageLaufAthleteImportComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            eventIdNeeded : true,
            title: 'Athleten Importieren',
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/disciplines',
    component: PageLaufDisciplineListComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Disziplinen',
            eventIdNeeded : true,
            button: {
              title: 'Neue Disziplin',
              url: 'laufverwaltung/disciplines/edit/0',
              icon: 'ti-plus'
            },
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/disciplines/edit/:disciplineId',
    component: PageLaufDisciplineEditComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Disziplinen Bearbeiten',
            eventIdNeeded : true,
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/checkpoints',
    component: PageLaufCheckpointListComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Checkpoints',
            eventIdNeeded : true,
            button: {
              title: 'Neuen Checkpoint',
              url: 'laufverwaltung/checkpoints/edit/0',
              icon: 'ti-plus'
            },
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/checkpoints/edit/:checkpointId',
    component: PageLaufCheckpointEditComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Checkpoint Bearbeiten',
            eventIdNeeded : true,
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/checkpointmanagers',
    component: PageLaufCheckpointmanagerListComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Checkpointmanagers',
            eventIdNeeded : true,
            button: {
              title: 'Neuer Checkpointmanager',
              url: 'laufverwaltung/checkpointmanagers/edit/0',
              icon: 'ti-plus'
            },
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/checkpointmanagers/edit/:checkpointmanagerId',
    component: PageLaufCheckpointmanagerEditComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Checkpoint Bearbeiten',
            eventIdNeeded : true,
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/contacts',
    component: PageLaufContactListComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Kontakte',
            eventIdNeeded : true,
            button: {
              title: 'Neuer Kontakt',
              url: 'laufverwaltung/contacts/edit/0',
              icon: 'ti-plus'
            },
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/contacts/edit/:contactsId',
    component: PageLaufContactEditComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Kontakt Bearbeiten',
            eventIdNeeded : true,
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/sponsors',
    component: PageLaufSponsorListComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Sponsoren',
            eventIdNeeded : true,
            button: {
              title: 'Neuer Sponsor',
              url: 'laufverwaltung/sponsors/edit/0',
              icon: 'ti-plus'
            },
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/sponsors/edit/:sponsorsId',
    component: PageLaufSponsorEditComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Sponsor Bearbeiten',
            eventIdNeeded : true,
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'laufverwaltung/gpsdeviceallocation',
    component: PageLaufGpsDeviceAllocationComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'GPS Geräte zuordnen',
            eventIdNeeded : true,
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'users',
    component: PageUserListComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Benutzer',
            button: {
              title: 'Neuer Benutzer',
              url: 'users/edit/0',
              icon: 'ti-plus'},
              show_breadcrumbs: true,
              container_type: 'fluid'
          }
  },

  {path: 'users/edit/:id',
    component: PageUserEditComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Benutzer bearbeiten',
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'gpsdevices',
    component: PageGpsDeviceListComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'GPS Geräte',
            button: {
              title: 'Neues Device',
              url: 'gpsdevices/edit/0',
              icon: 'ti-plus'},
              show_breadcrumbs: true,
              container_type: 'fluid'
          }
  },

  {path: 'gpsdevices/edit/:id',
    component: PageGpsDeviceEditComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'GPS Device bearbeiten',
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'notifications',
    component: PageNotificationsComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Benachrichtigungen',
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  {path: 'sms',
    component: PageSmsProtocolComponent,
    canActivate: [AuthorizationGuard],
    data: {role: 'master',
            title: 'Sms',
            show_breadcrumbs: true,
            container_type: 'fluid'
          }
  },

  /*
   * All Live Routes
   */
   {path: 'live',
     component: PageLiveDashboardComponent,
     canActivate: [AuthorizationGuard],
     data: {role: 'master',
             title: 'Liveüberwachung',
             eventIdNeeded : true,
             show_breadcrumbs: true,
             container_type: 'fluid'
           }
   },

   {path: 'live/athletes',
     component: PageLiveAthleteListComponent,
     canActivate: [AuthorizationGuard],
     data: {role: 'master',
             title: 'Live Läuferübersicht',
            eventIdNeeded : true,
            show_breadcrumbs: true,
            container_type: 'fluid'
           }
   },

   {path: 'live/disciplines/:disciplineId',
     component: PageLiveDisciplineDetailsComponent,
     canActivate: [AuthorizationGuard],
     data: {role: 'master',
             title: 'Live Disziplin Details',
             eventIdNeeded : true,
             show_breadcrumbs: false,
             container_type: 'full'
           }
   },

   {path: 'live/checkpoints/:checkpointId',
     component: PageLiveCheckpointDetailsComponent,
     canActivate: [AuthorizationGuard],
     data: {role: 'master',
             title: 'Live Checkpoint Details',
             eventIdNeeded : true,
             show_breadcrumbs: true,
             container_type: 'fluid'
           }
   },

   {path: 'live/athleterecording',
     component: PageLiveAthleteRecordingComponent,
     canActivate: [AuthorizationGuard],
     data: {role: 'master',
             title: 'Live Athleten Aufzeichnung',
             eventIdNeeded : true,
             show_breadcrumbs: true,
             container_type: 'fluid'
           }
   },

  {path: 'login',
    component: PageLoginComponent,
    data: {show_breadcrumbs: false,
            container_type: 'none'
          }
  },

  {path: '', redirectTo: '/live', pathMatch: 'full'},
  {path: '**', redirectTo: '/live', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
