import { Injectable, OnDestroy } from '@angular/core';
import { BaseService } from '../global-services/base-service';
import { Subject, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DisciplineLiveinfoService extends BaseService implements OnDestroy {

  private socket: Array<Subject<MessageEvent>>;

  constructor() {
    super();

    this.socket = new Array();
  }

  subscribeToEvent(eventId: number): Subject<any> {
    return this.connect(eventId).pipe(map((response: MessageEvent): any => {
      return JSON.parse(response.data);
    })) as Subject<any>;
  }

  ngOnDestroy() {

  }

  public connect(eventId: number): Subject<MessageEvent> {
    if (!this.socket[eventId]) {
      this.socket[eventId] = this.create(eventId);
    }

    return this.socket[eventId];
  }

  public create(eventId: number): Subject<MessageEvent> {
    const ws = new WebSocket(this.BASE_WS_URL + 'discipline-liveinfos/socket/' + eventId);

    const observable = Observable.create((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);

      return ws.close.bind(ws);
    });

    const observer = {
      next: (data: any) => {
        if (ws.readyState === WebSocket.OPEN) {
          console.log(data);
          ws.send(JSON.stringify(data));
        }
      }
    };

    return Subject.create(observer, observable);
  }
}
