import { Component, OnInit, OnChanges, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TableSortFilterPaginate } from '../table-sort-filter-paginate';
import {DatepickerOptions} from 'ng2-datepicker';
import { DefaultFormatter, NouisliderModule, NouisliderComponent  } from 'ng2-nouislider';
import {ViewEncapsulation} from '@angular/core';
import { FilteredOrderedListRequest } from '../filtered-ordered-list-request';

import * as deLocale from 'date-fns/locale/de';

@Component({
  selector: '[app-table-sortheader]',
  templateUrl: './table-sortheader.component.html',
  styleUrls: ['./table-sortheader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableSortheaderComponent implements OnInit {
  @Input() sortTitle : string;
  @Input() sortFieldId : string;
  @Input() sortData : FilteredOrderedListRequest;
  @Input() filterData : any;

  @Output() sortChanged = new EventEmitter<FilteredOrderedListRequest>();

  public closed:boolean;
  public startdate: Date;
  public enddate: Date;
  public booleanvalue:boolean;
  public someRange=[0,60];
  private actFilter:string;
  private valuechecked=[];
  public calendarOptions:DatepickerOptions =
  {
    locale: deLocale
  };

  constructor(private route: ActivatedRoute,
              private router: Router){
      this.closed = true;
      this.startdate = new Date();
      this.enddate = new Date();
      this.booleanvalue=false;
  }

  ngOnInit(){

  }

  isSortActive(){
    return this.sortData.sorterForFieldExists(this.sortFieldId);
  }

  isSortdirectionAsc(){
    return this.sortData.getSorterForField(this.sortFieldId).direction;
  }

  changeSorting(){
    this.sortData.changeFieldSorter(this.sortFieldId);
    this.sortChanged.emit(this.sortData);
    //this.router.navigate([this.route.snapshot.url[0].path, this.sortData.getNavigationParameter()]);
  }

  filterableListContains(name:string)
  {
    if(this.filterData[name] != undefined)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  isSelected(value:string){
    let index =this.valuechecked.indexOf(value);

    if (index > -1){
      return true;
    }else{
      return false;
    }
  }

  getDataType(name:string){
    if(this.filterData[name] != undefined){
      return this.filterData[name].type;
    }

    return undefined;
  }
  getFilterData(name:string)
  {
    if(this.filterData[name] != undefined)
    {

      return this.filterData[name].values;
    }
    return undefined;
  }

  ngOnChanges(changes: any){
    // if(changes.paginationData != null){
    //   this.sortData = changes.paginationData.currentValue;
    // }
    //
    // if(changes.sortTitle != null){
    //   this.sortTitle = changes.sortTitle.currentValue;
    // }
    //
    // if(changes.sortFieldId != null){
    //   this.sortFieldId = changes.sortFieldId.currentValue;
    // }
  }

  toggleFilterOverlay(id:any){
    this.closed = !this.closed;
  }

  getCalendarOptions(sortFieldId)
  {
    var calendarOptions:DatepickerOptions =
    {
      locale: deLocale,
      minDate: new Date(this.filterData[sortFieldId].min),
      maxDate: new Date(this.filterData[sortFieldId].max),
    };
  }

  filter(field:string){
//       this.closed = !this.closed;
//     switch(this.getDataType(field)) {
//        case "boolean": {
//          this.sortData.filter=field;
//
//      if(this.booleanvalue){
//        this.sortData.filterValues=  ["true"];
//      }
//      else{
//        this.sortData.filterValues=  ["nottrue"];
//      }
//
//
//      if(this.sortData.filterValues==null){
//        this.sortData.filterValues=[false];
//      }
//       break;
//    }
//    case "values": {
//      this.sortData.filter=field;
//      this.sortData.filterValues=this.valuechecked;
//       break;
//    }
//    case "daterange": {
//       this.sortData.filter=field;
//       this.sortData.filterValues=[this.formatDate(this.startdate),this.formatDate(this.enddate)];
//       break;
//    }
//    case "valuesspec": {
//      this.sortData.filter=field;
//      this.sortData.filterValues=this.valuechecked;
//       break;
//     }
//     case "amount": {
//       this.sortData.filter=field;
//       this.sortData.filterValues=this.someRange;
//       break;
//      }
// }
//
//     this.router.navigate([this.route.snapshot.url[0].path, this.sortData.getNavigationParameter()]);
  }

  addFilterValue(){

  }

  removeFilter(){
    // this.booleanvalue=false;
    // this.closed = true;
    //
    // this.sortData.removeFilters();
    // this.sortData.sort = '';
    // this.sortData.direction = '';
    // this.sortData.filter='';
    // this.valuechecked =[];
    //
    // this.router.navigate([this.route.snapshot.url[0].path, this.sortData.getNavigationParameter()]);
  }

  toggleSelection(possibleFilterValue:any){
    // let index =this.valuechecked.indexOf(possibleFilterValue);
    //
    // if (index > -1) {
    //   this.valuechecked.splice(index, 1);
    // }else{
    //   this.valuechecked.push(possibleFilterValue);
    // }
  }

  togglebool(){
    this.booleanvalue=!this.booleanvalue;
  }

formatDate(date:Date) {
    var now = date;
    var y = now.getFullYear();
    var m = now.getMonth() + 1;
    var d = now.getDate();
    var mm = m < 10 ? '0' + m : m;
    var dd = d < 10 ? '0' + d : d;
    return '' + y +'-'+ mm +'-'+ dd;
}
}
