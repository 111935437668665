import { Router } from '@angular/router';
import { Checkpoint } from './../classes/Checkpoints/Checkpoint';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CheckpointEditService } from './checkpoint-edit.service';
import { ToasterService } from 'angular2-toaster';
import { EventSelectorService } from '../global-services/event-selector.service';
import { Subscription } from 'rxjs';
import { DisciplineListService } from '../page-lauf-discipline-list/discipline-list.service';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';
import { isArray } from 'util';
import { IMapDataCollection } from '../geography/imap-data-collection';
import { IMapData } from '../geography/imap-data';
import { FixGeojsonService } from '../geography/fix-geojson.service';

@Component({
  selector: 'app-page-lauf-checkpoint-edit',
  templateUrl: './page-lauf-checkpoint-edit.component.html',
  styleUrls: ['./page-lauf-checkpoint-edit.component.scss'],
  providers: [
    CheckpointEditService
  ]
})
export class PageLaufCheckpointEditComponent implements OnInit, OnDestroy {
  public id: number;
  public triedToSubmit: boolean;
  public editCheckpointEdit: FormGroup;

  public position: {lng: number, lat: number}  = {lng: 11.393970497172887, lat: 47.262024107782594};
  public mapZenter: {lng: number, lat: number} = {lng: 11.393970497172887, lat: 47.262024107782594};
  public mapZoom = 9;

  public geoJson: IMapDataCollection;

  private routeSubscription: Subscription;
  private eventSubscription: Subscription;

  constructor(private fb: FormBuilder,
              private toasterService: ToasterService,
              private eventService: EventSelectorService,
              private disciplineListService: DisciplineListService,
              private route: ActivatedRoute,
              private router: Router,
              private fixGeojsonService: FixGeojsonService,
              private checkpointService: CheckpointEditService) {
    this.editCheckpointEdit = this.fb.group({
      'id': [0, Validators.compose([])],
      'name': ['', Validators.compose([Validators.required])],
      'toilets': [false, Validators.compose([])],
      'catering': [false, Validators.compose([])],
      'altitude': [0],
      'eventId': [0, Validators.compose([Validators.min(1)])]
    });

    this.geoJson = {
      data: new Array()
    };
  }

  ngOnInit() {
    this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId) => {
      this.editCheckpointEdit.patchValue({eventId: eventId});
    });

    this.routeSubscription = this.route.params.subscribe((params) => {
      console.log(params);
      this.id = +params.checkpointId;

      if (this.id !== 0) {
        this.loadCheckpoint(this.id);
      }
    });

    this.disciplineListService.list(new FilteredOrderedListRequest()).subscribe(success => {
      this.geoJson.data = new Array();
      this.geoJson.data = this.fixGeojsonService.fixGeoJson(success);
    }, error => {});
  }

  trackFn(idx: number, data: IMapData) {
    return data.id;
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  loadCheckpoint(id: number) {
    this.checkpointService.get(id).subscribe((checkpoint: Checkpoint) => {
      console.log(checkpoint);
      this.editCheckpointEdit.patchValue(checkpoint);

      this.position.lng = checkpoint.longitude;
      this.position.lat = checkpoint.latitude;

      this.mapZenter.lng = checkpoint.longitude;
      this.mapZenter.lat = checkpoint.latitude;
    });
  }

  mapClick(event) {
    if (event.lngLat !== undefined) {
      this.position = event.lngLat;
    }
  }

  changeCheckpoint() {
    const value = this.editCheckpointEdit.value;
    const valid = this.editCheckpointEdit.valid && this.position.lng !== 0 && this.position.lat !== 0;

    if (valid) {
      value.latitude = this.position.lat;
      value.longitude = this.position.lng;
      console.log(value);
      if (value.id !== 0) {
        this.checkpointService.edit(value).subscribe((success) => {
          this.toasterService.pop('success', 'Checkpoint wurde erfoglreich gespeichert!' , '');
          this.router.navigate(['laufverwaltung/checkpoints/']);
        });
      } else {
        this.checkpointService.add(value).subscribe((success) => {
          this.toasterService.pop('success', 'Checkpoint wurde erfoglreich gespeichert!' , '');
          this.router.navigate(['laufverwaltung/checkpoints/']);
        });
      }
    } else {
      this.toasterService.pop('error', 'Füllen Sie alle benötigten Felder aus!', '');
    }
  }

}
