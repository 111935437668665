import { environment } from './../../environments/environment';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Checkpoint } from '../classes/Checkpoints/Checkpoint';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

@Injectable({
  providedIn: 'root'
})
export class CheckpointListService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient){}

  public list(filteredOrderedListRequest:FilteredOrderedListRequest): Observable<Checkpoint[]>{
    return this.http.post<Checkpoint[]>(this.baseUrl + 'checkpoints/list-by-event/{:eventId}', filteredOrderedListRequest);
  }

  public delete(checkpointId:number){
    return this.http.delete(this.baseUrl + 'checkpoints/' + checkpointId);
  }
}
