import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LiveAthleteRecordingService {

  constructor(private http: HttpClient) { }

  sendCheckpointPass(value: any) {
    return this.http.post(environment.baseUrl + 'checkpointpasses/fastinput/add', value).pipe(first());
  }
}
