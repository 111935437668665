export class LaufmanagementEventselectionTranslation {
    translation = {
        de: {
            'LIVEUEBERWACHUNG - VERANSTALTUNGSAUSWAHL' : 'Liveüberwachung - Veranstaltungsauswahl',
            'LIVEUEBERWACHUNG_VERANSTALTUNGSAUSWAHL_BESCHREIBUNG' : 'Liveüberwachung Veranstaltungsauswahl Beschreibung',
            'LAEUFERANZAHL' : 'Läuferanzahl',
            'LIVEUEBERWACHUNG' : 'Liveüberwachung',
            'LIVEUEBERWACHUNG_BESCHREIBUNG' : 'Liveüberwachung Beschreibung',
            'LAEUFERLISTE' : 'Läuferliste',
            'CHECKPOINT_AUSWAEHLEN' : 'Checkpoint auswählen',
            'DISZIPLIN_AUSWAEHLEN' : 'Disziplin auswählen',
            'KARTE' : 'Karte',
            'LAEUFEREINGABE' : 'Läufereingabe'
        }
      };
}
