import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Sponsor } from '../classes/Events/Sponsor';

@Injectable({
  providedIn: 'root'
})
export class LaufSponsorEditService {

  constructor(private http: HttpClient) { }

  get(id: number) {
    return this.http.get(environment.baseUrl + 'sponsors/' + id, {}).pipe(first());
  }

  add(value: Sponsor) {
    return this.http.post(environment.baseUrl + 'sponsors', value, {}).pipe(first());
  }

  edit(value: Sponsor) {
    return this.http.put(environment.baseUrl + 'sponsors', value, {}).pipe(first());
  }
}
