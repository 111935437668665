import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CheckpointmanagerListService } from './checkpointmanager-list.service';
import { Checkpointmanager} from '../classes/Checkpointmanagers/Checkpointmanager';
import { first } from 'rxjs/operators';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';
import { ToasterService } from 'angular2-toaster';

declare var Swal;

@Component({
  selector: 'app-page-lauf-checkpointmanager-list',
  templateUrl: './page-lauf-checkpointmanager-list.component.html',
  styleUrls: ['./page-lauf-checkpointmanager-list.component.scss'],
  providers: [CheckpointmanagerListService]
})
export class PageLaufCheckpointmanagerListComponent implements OnInit {
  public loading: boolean;
  public checkpointmanagers: Checkpointmanager[];
  public filteredOrderedListRequest: FilteredOrderedListRequest;
  public searchString: string;

  constructor(private checkpointmanagerListService: CheckpointmanagerListService,
              private router: Router,
              private toasterService: ToasterService) {
                this.filteredOrderedListRequest = new FilteredOrderedListRequest();
              }

  ngOnInit() {
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
    this.loadCheckpointmanagers();
  }

  public loadCheckpointmanagers(filteredOrderedListRequest?: FilteredOrderedListRequest){
    this.loading = true;
    const reqFilterParam = filteredOrderedListRequest != null ? filteredOrderedListRequest : this.filteredOrderedListRequest;

    this.checkpointmanagerListService.list(reqFilterParam).pipe(first()).subscribe(success => {
      this.checkpointmanagers = success;
      this.loading = false;
    }, error => {
      this.loading = false;
      console.log(error);
      this.toasterService.pop('error', 'Checkpointmanagerliste konnte nicht geladen werden', '');
    });
  }

  public edit(checkpointManager: Checkpointmanager) {
    this.router.navigate(['/laufverwaltung/checkpointmanagers/edit/' + checkpointManager.id]);
  }

  onSearchStrChange() {
    this.filteredOrderedListRequest.searchstring = this.searchString;
    if (this.filteredOrderedListRequest.searchstring === undefined) {
      this.filteredOrderedListRequest.searchstring = '';
    }
    this.loadCheckpointmanagers();
  }


  public deleteCheckpointmanager(checkpointmanagerId:number){
    Swal.fire({
              title: 'Sind Sie sicher',
              text: 'Möchten Sie den checkpointmanagern wirklich löschen?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#D32F2F',
              cancelButtonColor: '#bdbdbd',
              confirmButtonText: 'Löschen',
              cancelButtonText: 'Abbrechen'
              }).then((result) => {
                this.checkpointmanagerListService.delete(checkpointmanagerId).pipe(first()).subscribe(success => {
                  this.checkpointmanagers = new Array();
                  this.loadCheckpointmanagers();
                }, error => {});
              });
  }
}
