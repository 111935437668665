import { Injectable } from '@angular/core';
import { IMapData } from './imap-data';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class FixGeojsonService {

  constructor() { }

  public fixGeoJson(old: any): Array<IMapData> {
    if (old === undefined ) {
      return new Array<IMapData>();
    }
    let ret = new Array<IMapData>();
    if (isArray(old)) {
      old.forEach((el) => {
        ret = ret.concat(this.fixGeoJson(el));
      });
    } else {
      ret.push({
        data: old.geojson,
        length: old.length,
        // id used to identify on map
        id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
      });
    }
    return ret;
  }
}
