
import { Injectable, OnDestroy } from '@angular/core';
import { BaseService } from '../global-services/base-service';
import { Subject, Observable, Observer, BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Notification } from '../classes/Notifications/Notification';
import { isArray } from 'util';


@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService implements OnDestroy {
  private socket: Array<BehaviorSubject<Array<Notification>>>;

  private notificationSubscription: Subscription;
  private notifications: Array<Notification>;

  constructor(private http: HttpClient) {
    super();

    this.socket = new Array();
    this.notifications = new Array();
  }

  subscribeToEvent(eventId: number) {
    return this.connect(eventId);
  }

  ngOnDestroy() {

  }

  public connect(eventId: number): BehaviorSubject<Array<Notification>> {
    if (!this.socket[eventId]) {
      this.socket[eventId] = this.create(eventId);
    }

    return this.socket[eventId];
  }

  public create(eventId: number): BehaviorSubject<Array<Notification>> {
    const ws = new WebSocket(this.BASE_WS_URL + 'messages/socket/' + eventId);

    const observable = Observable.create((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);

      return ws.close.bind(ws);
    });

    const observer = {
      next: (data: any) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    };

    this.notificationSubscription = (Subject.create(observer, observable) as Subject<MessageEvent>)
                                            .subscribe((data: MessageEvent) => {
      const notifications = JSON.parse(data.data);
      if ( isArray(notifications)) {
        notifications.forEach((notification: Notification) => {
          this.checkMessageInArray(notification);
        });
      } else {
        this.checkMessageInArray(notifications);
      }
      this.socket[eventId].next(this.notifications);
    });
    return new BehaviorSubject<Array<Notification>>([]); // Subject.create(observer, observable);
  }

  checkMessageInArray(newNotification: Notification) {
    let found = false;
    this.notifications.forEach((oldNotification: Notification) => {
      if (newNotification.id === oldNotification.id) {
        found = true;
        oldNotification.finished = newNotification.finished;
        oldNotification.remember = newNotification.remember;
      }
    });
    if (found === false) {
      this.notifications.push(newNotification);
    }
  }

  setAsFinsihed(id: number) {
    return this.http.put(this.baseUrl + 'messages/' + id, null, {});
  }

  setToRemember(id: number) {
    return this.http.put(this.baseUrl + 'messages/setRemember/' + id, null, {});
  }

}
