import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../global-services/authentication.service';
import { AuthenticationFactoryService } from '../global-services/authentication-factory.service';

import { NgSelectizeModule} from 'ng-selectize';
import { Checkpoint } from '../classes/Checkpoints/Checkpoint';
import { CheckpointmanagerEditService } from './checkpointmanager-edit.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Checkpointmanager } from '../classes/Checkpointmanagers/Checkpointmanager';
import { EventSelectorService } from '../global-services/event-selector.service';
import { Checkpointmanagerresponsibility } from '../classes/Checkpointmanagers/Checkpointmanagerresponsibility';

@Component({
  selector: 'app-page-lauf-checkpointmanager-edit',
  templateUrl: './page-lauf-checkpointmanager-edit.component.html',
  styleUrls: ['./page-lauf-checkpointmanager-edit.component.scss'],
  providers: [
    CheckpointmanagerEditService
  ]
})
export class PageLaufCheckpointmanagerEditComponent implements OnInit, OnDestroy {

  public nation = ['Austria', 'Germany', 'United Kingdom', 'Italy', 'Australia'];

  public id: number;
  public checkpointmanagerForm: FormGroup;
  public checkpoints: Array<Checkpoint>;
  public checkpointmanagerresponsibilities: Array<Checkpointmanagerresponsibility>;

  public eventId: number;

  private sub: Subscription;
  private eventSubscription: Subscription;

  public mandantList: any[];

  public deviceList: any[];
  public locationList: any[];
  public indicatorList: any[];

  public triedToSubmit = false;

  public activeUserIsAdmin = false;

  public draggables = {
    effectAllowed: 'move',
    disable: false,
    handle: false,
  };

  private authenticationService: AuthenticationService;

  constructor(private fb: FormBuilder,
    private toasterService: ToasterService,
    private router: Router,
    private checkpointmanagerEditService: CheckpointmanagerEditService,
    private authFactory: AuthenticationFactoryService,
    private eventService: EventSelectorService,
    private route: ActivatedRoute) {

      this.authenticationService = this.authFactory.getAuthenticationService();
      this.checkpointmanagerForm = this.fb.group({
        'id': [0, Validators.compose([])],
        'eventId': [null, Validators.compose([Validators.required])],
        'entrycode': ['', Validators.compose([Validators.required, Validators.maxLength(45)])],
        'discipline': ['', Validators.compose([])],
        'surname': ['', Validators.compose([Validators.maxLength(255)])],
        'lastname': ['', Validators.compose([Validators.maxLength(255)])],
        'phone': ['', Validators.compose([Validators.maxLength(255)])],
        'email': ['', Validators.compose([Validators.maxLength(255), Validators.email])]
      });

      this.checkpoints = new Array();
      this.checkpointmanagerresponsibilities = new Array();
    }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['checkpointmanagerId'];

      if (this.id !== 0) {
        this.checkpointmanagerEditService.get(this.id).subscribe((success: Checkpointmanager) => {
          console.log(success);
          this.checkpointmanagerForm.patchValue(success);
          this.checkpointmanagerresponsibilities = success.checkpointmanagerresponsibilities;
        });
      }
      // TODO: Anschauen
      this.authenticationService.getUserRole().then(resolve => {
        this.activeUserIsAdmin = (resolve === 'admin');
      });
     });

     this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId: number) => {
        this.eventId = eventId;
        this.loadCheckpoints();
     });
  }

  loadCheckpoints() {
    this.checkpointmanagerEditService.listCheckpoints()
                                     .subscribe((checkpoints: Array<Checkpoint>) => {
      this.checkpoints = checkpoints;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

  changeUser() {
    this.checkpointmanagerForm.patchValue({eventId: this.eventId});
    const value = this.checkpointmanagerForm.value;
    const valid = this.checkpointmanagerForm.valid; // && this.checkpointmanagerresponsibilities.length > 0;

    value.checkpointmanagerresponsibilities = new Array();
    if (valid) {
      // Do something

      console.log(value.id);
      if (value.id === 0) {
        this.checkpointmanagerresponsibilities.forEach((checkpoint: Checkpointmanagerresponsibility) => {
          value.checkpointmanagerresponsibilities.push(checkpoint);
        });
        this.checkpointmanagerEditService.add(value)
                                        .subscribe((checkpointmanager: Checkpointmanager) => {
                                          console.log(checkpointmanager);
                                          this.toasterService.pop('success', 'Checkpointmanager wurde erfolgreich gespeichert!', '');
                                          this.router.navigate(['/laufverwaltung/checkpointmanagers']);
                                        });
      } else {
        this.checkpointmanagerresponsibilities.forEach((checkpoint: Checkpointmanagerresponsibility) => {
          value.checkpointmanagerresponsibilities.push(checkpoint);

          console.log(value);
        });
        this.checkpointmanagerEditService.edit(value)
                                        .subscribe((checkpointmanager: Checkpointmanager) => {
                                          console.log(checkpointmanager);
                                          this.toasterService.pop('success', 'Checkpointmanager wurde erfolgreich gespeichert!', '');
                                          this.router.navigate(['/laufverwaltung/checkpointmanagers']);
                                        });
      }

    } else {
      console.log(this.checkpointmanagerForm);
      this.toasterService.pop('error', 'Füllen Sie alle benötigten Felder aus', '');
    }

    this.triedToSubmit = true;
   }


   hasAssigned(checkpointid: number) {
     let found = false;
     this.checkpointmanagerresponsibilities.forEach((checkpoint: Checkpointmanagerresponsibility) => {
       if (checkpoint.checkpoint.id === checkpointid && checkpoint.deleteflag === false) {
         found = true;
       }
     });
     return found;
   }

   assign(checkpoint) {
     this.checkpointmanagerresponsibilities.push({
       checkpoint: checkpoint,
       deleteflag: false,
       id: 0,
       checkpointmanager: null,
       checkpointmanager_id: this.id
     });
   }

   remove(checkpointid) {
     let index = -1;
     this.checkpointmanagerresponsibilities.forEach((checkpoint, i) => {
       if (checkpoint.checkpoint.id === checkpointid) {
         index = i;
         checkpoint.deleteflag = true;
       }
     });
   }

  passwordChanged(obj) {
    this.checkpointmanagerForm.controls.password = obj;
  }

  onDrop(event: any, assign: boolean) {
    console.log('Here', event, assign);
    if (event.data.assigned === false && assign === true) {
      this.assign(event.data.checkpoint);
      console.log('Here1');
    } else if (event.data.assigned === true && assign === false) {
      this.remove(event.data.checkpoint.id);
      console.log('Here2');
    } else {
      console.log('Here3');
    }

  }
}
