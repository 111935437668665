import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../global-services/authentication.service';
import { AuthenticationFactoryService } from '../global-services/authentication-factory.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public showNavigationElements: any = {
                                          'mandatare'       : false,
                                          'users'           : false,
                                          'locations'       : false,
                                          'logs'            : false,
                                          'actions'         : false,
                                          'indicators'      : false,
                                          'indicatortypes'  : false
                                        };

  private authenticationService: AuthenticationService;

  constructor(private authFactory: AuthenticationFactoryService) {
    this.authenticationService = authFactory.getAuthenticationService();
  }

  ngOnInit() {
    this.authenticationService.isUserAdmin().subscribe((isAdmin) => {
      if (isAdmin) {
        this.showNavigationElements.mandatare = true;
        this.showNavigationElements.users = true;
        this.showNavigationElements.mandatare = true;
        this.showNavigationElements.locations = true;
        this.showNavigationElements.indicatortypes = true;
        this.showNavigationElements.indicators = true;
        this.showNavigationElements.actions = true;
        this.showNavigationElements.logs = true;
      }
    });

    this.authenticationService.isUserMasterAdmin().subscribe((isMaster) => {
      if (isMaster) {
        this.showNavigationElements.mandatare = false;
        this.showNavigationElements.users = true;
        this.showNavigationElements.locations = false;
        this.showNavigationElements.indicators = false;
        this.showNavigationElements.indicatortypes = false;
        this.showNavigationElements.actions = true;
        this.showNavigationElements.logs = true;
      }
    });

    this.authenticationService.isUserMaster().subscribe((isMaster) => {
      if (isMaster) {
        this.showNavigationElements.mandatare = false;
        this.showNavigationElements.users = true;
        this.showNavigationElements.locations = false;
        this.showNavigationElements.indicators = false;
        this.showNavigationElements.indicatortypes = false;
        this.showNavigationElements.actions = true;
        this.showNavigationElements.logs = true;
      }
    });

    this.authenticationService.isUserOperator().subscribe((isOperator) => {
      if (isOperator) {
        this.showNavigationElements.mandatare = false;
        this.showNavigationElements.users = false;
        this.showNavigationElements.locations = false;
        this.showNavigationElements.indicators = false;
        this.showNavigationElements.indicatortypes = false;
        this.showNavigationElements.actions = false;
        this.showNavigationElements.logs = false;
      }
    });
  }
}
