import { environment } from './../../environments/environment';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Gpsdevice } from '../classes/Gpsdevices/Gpsdevice';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

@Injectable({
  providedIn: 'root'
})
export class GpsdeviceListService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient){}

  public list(filteredOrderedListRequest:FilteredOrderedListRequest): Observable<Gpsdevice[]>{
    return this.http.post<Gpsdevice[]>(this.baseUrl + 'gpsdevice/list/', filteredOrderedListRequest);
  }

  public delete(gpsdeviceId:number){
    return this.http.delete(this.baseUrl + 'gpsdevice/' + gpsdeviceId);
  }
}
