import { EventSelectorService } from './../global-services/event-selector.service';
import { LaufSponsorEditService } from './lauf-sponsor-edit.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Subscription } from 'rxjs';
import { Sponsor } from '../classes/Events/Sponsor';


import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';



@Component({
  selector: 'app-page-lauf-sponsor-edit',
  templateUrl: './page-lauf-sponsor-edit.component.html',
  styleUrls: ['./page-lauf-sponsor-edit.component.scss'],
  providers: [
    LaufSponsorEditService
  ]
})
export class PageLaufSponsorEditComponent implements OnInit, OnDestroy {

  public id: number;
  public sponsorForm: FormGroup;

  public triedToSubmit: boolean;

  public  DROPZONE_CONFIG: DropzoneConfigInterface = {
    // Change this to your upload POST address:
     url: 'https://files.trailscout.at/upload-sponsorlogos.php',
   /*  uploadMultiple: false,
     hiddenInputContainer: true,
     addRemoveLinks: true*/
     maxFilesize: 50,
     acceptedFiles: 'image/*'
   };

  private eventSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private toasterService: ToasterService,
              private eventService: EventSelectorService,
              private sponsorService: LaufSponsorEditService ) {
    this.triedToSubmit = false;

    this.sponsorForm = this.fb.group({
      'id': [0, Validators.compose([Validators.required])],
      'logo': ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      'name': ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      'website': ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      'eventId': [0, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId: number) => {
      this.sponsorForm.patchValue({eventId: eventId});
    });

    this.routerSubscription = this.route.params.subscribe((params) => {
      this.id = +params.sponsorsId;
      if (this.id !== 0) {
        this.loadSponsor(this.id);
      }
    });
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  onUploadError(event) {
    this.toasterService.pop('error', 'Logo upload fehlgeschlagen!', '');
    console.error(event);
  }

  onUploadSuccess(args: Array<any>) {

    args.forEach((arg: any) => {
      if (arg.name ) {
        this.sponsorForm.patchValue({
          logo: arg.name
        });
      } else {
      }
    });
    console.log(event);
  }

  private loadSponsor(id: number) {
    this.sponsorService.get(id).subscribe((success: Sponsor) => {
      this.sponsorForm.patchValue(success);
    });
  }

  public changeSponsor() {
    const valid = this.sponsorForm.valid;
    const value = this.sponsorForm.value;

    if (valid) {
      if (value.id === 0) {
        this.sponsorService.add(value).subscribe((success) => {
          this.router.navigate(['laufverwaltung/sponsors']);
        });
      } else {
        this.sponsorService.edit(value).subscribe((success) => {
          this.router.navigate(['laufverwaltung/sponsors']);
        });
      }
    } else {
      this.toasterService.pop('error', 'Bitte füllen Sie alle benötigten Felder aus!', '');
    }
    this.triedToSubmit = true;
  }


}
