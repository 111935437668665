import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';
import { Checkpoint } from '../classes/Checkpoints/Checkpoint';
import { Discipline } from '../classes/Disciplines/Discipline';

@Injectable({
  providedIn: 'root'
})
export class LaufDisciplineEditService {

  constructor(private http: HttpClient) { }


  listCheckpoints() {
    return this.http.post(environment.baseUrl + 'checkpoints/list-by-event/{:eventId}', new FilteredOrderedListRequest(), {}).pipe(first());
  }

  get(id: number) {
    return this.http.get(environment.baseUrl + 'disciplines/with-details/' + id, {}).pipe(first());
  }
  add(value: Discipline) {
    return this.http.post(environment.baseUrl + 'disciplines', value, {}).pipe(first());
  }

  edit(value: Discipline) {
    return this.http.put(environment.baseUrl + 'disciplines', value, {}).pipe(first());
  }

  reopen(id: number) {
    return this.http.put(environment.baseUrl + 'disciplines/reopen-disciplinecheckpoint/' + id, {}).pipe(first());
  }
}
