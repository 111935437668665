import {AbstractControl} from '@angular/forms';

export class PasswordValidation{

  static matchPassword(AC: AbstractControl) {
       let password = AC.get('password').value;
       let confirmPassword = AC.get('password_repeat').value;
       /*
       console.log(password);
       console.log(confirmPassword);

       console.log((password === null || password === '') && (confirmPassword !== null && confirmPassword !== ''));
       console.log((confirmPassword === null || confirmPassword === '') && (password !== null && password !== ''));
       console.log(password != confirmPassword);

      console.log(AC);
       console.log();*/
        if((password === null || password === '') && (confirmPassword !== null && confirmPassword !== '') ||
            (confirmPassword === null || confirmPassword === '') && (password !== null && password !== '') || 
            (password != confirmPassword)) {
          AC.get('password_repeat').setErrors( {matchPassword: true} )
        }else{
          return null;
        }
    }
}
