import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AthleteListService } from './athlete-list.service';
import { AthleteListItem } from '../classes/Athlete/AthleteListItem';
import { first } from 'rxjs/operators';
import { Athlete } from '../classes/Athlete/Athlete';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';
import { stringify } from '@angular/compiler/src/util';


declare var Swal;

@Component({
  selector: 'app-page-lauf-athlete-list',
  templateUrl: './page-lauf-athlete-list.component.html',
  styleUrls: ['./page-lauf-athlete-list.component.scss'],
  providers: [AthleteListService]
})


export class PageLaufAthleteListComponent implements OnInit {
  public loading:boolean;
  public athletes:AthleteListItem[];
  public filteredOrderedListRequest:FilteredOrderedListRequest;
  public searchString:string;

  constructor(private athleteListService: AthleteListService,
              private router: Router) {
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
  }

  ngOnInit(){
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
    this.loadAthletes();
  }

  public loadAthletes(){
    this.athletes = new Array();
    this.loading = true;
    var reqFilterParam = this.filteredOrderedListRequest;

    this.athleteListService.list(reqFilterParam).pipe(first()).subscribe(success => {
      this.athletes = success;
      this.loading = false;
    }, error => {});
  }

  public edit(athlete: Athlete) {
    this.router.navigate(['laufverwaltung/athletes/edit/' + athlete.id]);
  }

  onSearchStrChange() {
    this.filteredOrderedListRequest.searchstring = this.searchString;
    if (this.filteredOrderedListRequest.searchstring === undefined) {
      this.filteredOrderedListRequest.searchstring = '';
    }
    this.loadAthletes();
  }

  public deleteAthlete(athleteId:number){
    Swal.fire({
              title: 'Sind Sie sicher',
              text: "Möchten Sie den athleten wirklich löschen?",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#D32F2F',
              cancelButtonColor: '#bdbdbd',
              confirmButtonText: 'Löschen',
              cancelButtonText: 'Abbrechen'
              }).then((result) => {
                this.athleteListService.delete(athleteId).pipe(first()).subscribe(success => {
                  this.athletes = new Array();
                  this.loadAthletes();
                }, error => {});
              });
  }
  
}
