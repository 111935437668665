import { Injectable } from '@angular/core';
import { BaseService } from '../global-services/base-service';
import { HttpClient } from '@angular/common/http';
import { CheckpointpassReport } from '../classes/Checkpointpasses/CheckpointpassReport';

@Injectable({
  providedIn: 'root'
})
export class FastInputService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  listCheckpoints(eventId: number) {
    return this.http.get(this.baseUrl + 'checkpoints/list-by-event/' + eventId);
  }

  fastInput(checkpointpass: CheckpointpassReport) {
    console.log("Here" + this.baseUrl);
    return this.http.post(this.baseUrl + 'checkpointpasses/fastinput/add', checkpointpass);
  }

}
