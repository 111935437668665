import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { BaseService } from './base-service';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthenticationService extends BaseService {
  static counter2 = 0;

  private loggedIn = new BehaviorSubject<boolean>(this.isLoggedIn());
  private isAdmin = new BehaviorSubject<boolean>(false);
  private isMasterAdmin = new BehaviorSubject<boolean>(false);
  private isMaster = new BehaviorSubject<boolean>(false);
  private isOperator = new BehaviorSubject<boolean>(false);

  private serviceUrl = 'users';
  private mandantServiceUrl = 'mandants';

  private authorizedMandants: any[];
  private selectedMandant: any;
  private user: any;

  public counter = 0;

  private checkUserRole = false;
  private userNeededRole = false;
  private userRole = false;

  private getUserRoleSub;
  private userNeededRoleSub;
  private userProfileServiceSub;
  private loginSubscription;

  private usersUrl = 'users';
  private loggingUrl = '/logging_entries';

  private isLoggingIn = false;
  private loggedInSubscription;

  constructor(protected http: HttpClient) {
      super();

    //  this.loadUserRoleFromServer();
   // this.loadUserRole();
  }

  public login(user: any) {
    const body = JSON.stringify(user);

    return new Promise((resolve, reject) => {
                  this.http.post(this.baseUrl + this.serviceUrl + '/login', body).pipe(
                    map((res: Response) => res))
                    .subscribe(
                      (success: any) => {
                        console.log('We got here after the login with');
                        console.log(success);
                        localStorage.setItem('mandantToken', success.eventId);
                        localStorage.setItem('authToken', success.password);
                        this.loggedIn.next(true);
                        resolve(success);
                      },
                      error => {
                        console.log(error);
                        console.log('login() error');
                        this.loggedIn.next(false);
                        reject(error);
                      });
                  });
  }

  public isLoggedIn() {
    return localStorage.getItem('authToken') != null && localStorage.getItem('authToken') !== '';
  }

  getLoggedIn(): any {
    // if(this.isLoggingIn === false){
    //   this.isLoggingIn = true;
    //   this.loggedInSubscription = this.http.get<any>(this.baseUrl + this.usersUrl  + '/loggedin' + '.json').pipe(
    //     map((res) => {
    //       this.isLoggingIn = false;
    //       return res;
    //     })
    //   );
    // }
    //
    // return this.loggedInSubscription;
  }

  public isAuthenticated(): Observable<boolean> {
   return this.loggedIn.asObservable();
  }

  public isUserAdmin(): Observable<boolean> {
    return this.isAdmin.asObservable();
  }

  public isUserMaster(): Observable<boolean> {
    return this.isMaster.asObservable();
  }

  public isUserOperator(): Observable<boolean> {
    return this.isOperator.asObservable();
  }

  public isUserMasterAdmin(): Observable<boolean> {
    return this.isMasterAdmin.asObservable();
  }

  private loadUserRoleFromServer() {
    //
    // this.loginSubscription = this.loggedIn.subscribe(value => {
    //
    //   this.counter++;
    //   AuthenticationService.counter2++;
    //   console.log(this.counter);
    //   console.log(AuthenticationService.counter2);
    //   if (value === true) {
    //
    //     if (this.checkUserRole === false) {
    //       this.checkUserRole = true;
    //
    //       this.userProfileServiceSub = this.userProfileService.getLoggedIn().subscribe((success) => {
    //         this.checkUserRole = false;
    //         if (this.userProfileServiceSub !== undefined ) {
    //           this.userProfileServiceSub.unsubscribe();
    //         }
    //         switch (success.user.role) {
    //           case 'admin':
    //             this.isAdmin.next(true);
    //             break;
    //           case 'master':
    //             this.isMaster.next(true);
    //             break;
    //           case 'operator':
    //             this.isOperator.next(true);
    //         }
    //
    //         this.user = success.user;
    //       }, (error) => {
    //         console.log('Errorrrr');
    //         console.log(error);
    //         if (this.userProfileServiceSub !== undefined ) {
    //           this.userProfileServiceSub.unsubscribe();
    //         }
    //         this.checkUserRole = false;
    //         this.isAdmin.next(false);
    //         this.isMaster.next(false);
    //         this.isOperator.next(false);
    //       });
    //     }
    //   } else {
    //     this.isAdmin.next(false);
    //     this.isMaster.next(false);
    //     this.isOperator.next(false);
    //   }
    // });

  }

  public loadUserRole() {

    // this.loggedIn.subscribe(value => {
    //   this.counter++;
    //
    //   if (value === true) {
    //
    //     if (this.checkUserRole === false) {
    //       this.checkUserRole = true;
    //
    //       console.log(this.checkUserRole);
    //       this.userProfileService.getLoggedIn().subscribe((success) => {
    //         this.checkUserRole = false;
    //         switch (success.user.role) {
    //           case 'admin':
    //             this.isAdmin.next(true);
    //             break;
    //           case 'master':
    //             this.isMaster.next(true);
    //             break;
    //           case 'operator':
    //             this.isOperator.next(true);
    //             break;
    //           case 'masteradmin':
    //             this.isMasterAdmin.next(true);
    //             break;
    //         }
    //
    //         this.user = success.user;
    //       }, (error) => {
    //         this.checkUserRole = false;
    //         this.isAdmin.next(false);
    //         this.isMasterAdmin.next(false);
    //         this.isMaster.next(false);
    //         this.isOperator.next(false);
    //       });
    //     }
    //   } else {
    //     this.isAdmin.next(false);
    //     this.isMasterAdmin.next(false);
    //     this.isMaster.next(false);
    //     this.isOperator.next(false);
    //   }
    // });

    return false;
  }

  public hasUserNeededRole(role: string) {

    // return new Promise((resolve, reject) => {
    //   if (this.user != null) {
    //     resolve(this.getRoleEqivalent(role) <= this.getRoleEqivalent(this.user.role));
    //   } else {
    //     if (this.userNeededRole === false) {
    //       this.userNeededRoleSub =  this.userProfileService.getLoggedIn();
    //     }
    //
    //     this.userNeededRoleSub.subscribe((success) => {
    //       this.user = success.user;
    //       resolve(this.getRoleEqivalent(role) <= this.getRoleEqivalent(this.user.role));
    //     }, (error) => {
    //       reject(false);
    //     });
    //
    //   }
    // });
  }

  public getUserRole() {
    return new Promise((resolve, reject) => {
      if (this.user != null) {
        resolve(this.user.role);
      } else {
        resolve('admin');
        if (this.userRole === false) {
          this.userRole = true;
          // this.getUserRoleSub = this.userProfileService.getLoggedIn();
        }
        // this.getUserRoleSub.subscribe((success) => {
        //   this.userRole = false;
        //   this.user = success.user;
        //   resolve(this.user.role);
        // }, (error) => {
        //   this.userRole = false;
        //   resolve(false);
        // });

        resolve('admin');
      }
    });
  }

  private getRoleEqivalent(role: string) {
    // switch (role) {
    //   case 'admin':
    //     return 99;
    //   case 'masteradmin':
    //     return 69;
    //   case 'master':
    //     return 49;
    //   case 'operator':
    //     return 1;
    // }
    //
    // return 0;
  }

  public logout() {
    return new Promise((resolve, reject) => {
      console.log('logout()');
      localStorage.removeItem('mandantToken');
      localStorage.removeItem('authToken');
      this.loggedIn.next(false);
      this.isAdmin.next(false);
      this.isMaster.next(false);
      this.isOperator.next(false);
      resolve(true);
    });
  }
}
