export class LaufmanagementAthletesTranslation {
    translation = {
        de: {
            'NO FILTER' : 'Keine Filter',
            'RUNNING_IN_TIME' : 'In Zeit',
            'RUNNING_OUT_OF_TIME' : 'Die Zeit läuft aus',
            'GIVEN_UP' : 'Aufgegeben',
            'PASSED' : 'Durchgekommen',
            'PASSING_TIME' : 'Passierzeit',
            'NEXT_CHECKPOINT' : 'Nächster Checkpoint',
            'ERWARTET IN' : 'Erwartet in',
            'UEBERFÄLLIG' : 'Überfällig',
            'RUN_TROUGH' : 'Durchlauf',
            'GIVE_UP' : 'Aufgeben',
            'AKTIONEN' : 'Aktionen',
            'WEITER_GELAUFEN' : 'Weiter gelaufen',
            'AUFGEBEN' : 'Aufgeben',
            'SHOW_MORE' : 'Mehr zeigen',
            'EDIT_ATHLETE' : 'Athleten bearbeiten',
            'ATHLETES_IMPORT' : 'Athleten importieren'
        }
      };
}
