export class NutzerverwaltungTranslation {
    translation = {
        de: {
            'NUTZERVERWALTUNG' : 'Nutzerverwaltung',
            'NUTZERVERWALTUNG_BESCHREIBUNG' : 'Beschreibung der Nutzerverwaltung',
            'UEBERSICHT' : 'Übersicht',
            'NEW_USER' : 'Neuer Nutzer',
            'ADDRESS' : 'Adresse',
            'AKTIONEN' : 'Aktionen',
            'VERANSTALTUNG' : 'Veranstaltung',
            'ROLE' : 'Rolle',
            'EMAIL' : 'Email',
            'TELEFON' : 'Telefon',
            'BITTE_BENUTZERNAME_EINGEBEN' : 'Bitte Benutzername eingeben',
            'BENUTZERNAME' : 'Benutzername',
            'BITTE_EMAIL_EINGEBEN' : 'Bitte Email eingeben',
            'BITTE_TELEFON_EINGEBEN' : 'Bitte Telefon eingeben',
            'BITTE_PASSWORT_EINGEBEN' : 'Bitte Passwort eingeben',
            'BITTE_PASSWORT_WIEDERHOLEN_EINGEBEN' : 'Bitte Passwort wiederholen',
            'BITTE_ALTES_PASSWORT_EINGEBEN' : 'Bitte altes Passwort eingeben',
            'PASSWORT' : 'Passwort',
            'PASSWORT_WIEDERHOLUNG' : 'Passwort Wiederholung',
            'ALTES_PASSWORT' : 'Altes Passwort',
            'BITTE_VORNAME_EINGEBEN' : 'Bitte Vorname eingeben',
            'BITTE_NACHNAME_EINGEBEN' : 'BItte Nachname eingeben',
            'VORNAME' : 'Vorname',
            'NACHNAME' : 'Nachname',
            'STRASSE' : 'Strasse',
            'HAUSNUMMER' : 'Hausnummer',
            'PLZ' : 'Postleitzahl',
            'TOWN' : 'Stadt',
            'SAVE' : 'Speichern',
            'USER_SURNAME_ALERT' : 'Bitte geben Sie einen Vornamen an',
            'USER_LASTNAME_ALERT' : 'Bitte geben Sie einen Nachnamen an',
            'USER_PHONE_ALERT' : 'Bitte geben Sie eine Telefonnummer an',
            'USER_EMAIL_ALERT' : 'Bitte geben Sie eine Email-Adresse an',
            'USER_POSTCODE_ALERT' : 'Bitte geben Sie eine Postleitzahl an',
            'USER_TOWN_ALERT' : 'Bitte geben Sie eine Ort an',
            'USER_STREET_ALERT' : 'Bitte geben Sie eine Straße an',
            'USER_HOUSENUMBER_ALERT' : 'Bitte geben Sie eine Hausnummer an',
        }
    };
}
