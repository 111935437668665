import { Component, OnInit, OnDestroy } from '@angular/core';
import { Gpsdeviceallocation } from '../classes/Gpsdevices/Gpsdeviceallocation';
import { EventSelectorService } from '../global-services/event-selector.service';
import { GpsDeviceAllocationService } from './gps-device-allocation.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Athlete } from '../classes/Athlete/Athlete';
import { Gpsdevice } from '../classes/Gpsdevices/Gpsdevice';
import { ToasterService } from 'angular2-toaster';

declare var Swal;
@Component({
  selector: 'app-page-lauf-gps-device-allocation',
  templateUrl: './page-lauf-gps-device-allocation.component.html',
  styleUrls: ['./page-lauf-gps-device-allocation.component.scss'],
  providers: [
    GpsDeviceAllocationService
  ]
})
export class PageLaufGpsDeviceAllocationComponent implements OnInit, OnDestroy {

  public eventId: number;
  public gpsdeviceAllocations: Array<Gpsdeviceallocation>;
  public athleteList: Array<Athlete>;
  public deviceList: Array<Gpsdevice>;

  public inputType = 'random';

  public deviceAllocationForm: FormGroup;
  public allocationRandomForm: FormGroup;

  public athlete_selectize_config = {
    labelField: 'selectizeField',
    valueField: 'id',
    searchField: ['name', 'startnumber']
  };

  public device_selectize_config = {
    labelField: 'selectizeField',
    valueField: 'id',
    searchField: ['name', 'imei', 'imei2', 'type']
  };

  private eventSubscription: Subscription;

  constructor(private allocationService: GpsDeviceAllocationService,
              private fb: FormBuilder,
              private toasterService: ToasterService,
              private eventService: EventSelectorService) {
    this.gpsdeviceAllocations = new Array();

    this.deviceAllocationForm = this.fb.group({
      'athlete': [0, Validators.compose([Validators.required, Validators.min(1)])],
      'device': [0, Validators.compose([Validators.required, Validators.min(1)])],
    });

    this.allocationRandomForm = this.fb.group({
      'athleteList': ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      'reallocate': [false, Validators.compose([])]
    });

    this.athleteList = new Array();
  }

  ngOnInit() {
    this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId) => {
      this.eventId = eventId;
      this.loadList();
      this.loadSelectizeLists();
    });
  }

  changeSelectType(type: string) {
    this.inputType = type;
  }

  loadList() {
    this.allocationService.list().subscribe((allcoations: Array<Gpsdeviceallocation>) => {
      this.gpsdeviceAllocations = allcoations;
    });
  }

  loadSelectizeLists() {
    this.allocationService.athleteList().subscribe((athletes: Array<Athlete>) => {
      this.athleteList = athletes;

      this.athleteList.forEach((athlet) => {
        (athlet as any).selectizeField = athlet.startnumber + ', ' + athlet.surname + ' ' + athlet.lastname;
      });
      console.log(this.athleteList[0]);
    });

    this.allocationService.deviceList().subscribe((gpsedvices: Array<Gpsdevice>) => {
      console.log(gpsedvices);
      this.deviceList = gpsedvices;

      this.deviceList.forEach((device) => {
        (device as any).selectizeField = device.name + ' ' + device.imei;
        if (device.imei2 != null && device.imei2.length > 0) {
          (device as any).selectizeField +=  ' | ' + device.imei2;
        }
      });
    });

  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  allocateDevice() {
    const value =  this.deviceAllocationForm.value;
    const valid =  this.deviceAllocationForm.valid;

    this.allocationService.allocateDevice(value.device, value.athlete).subscribe((success) => {
      this.toasterService.pop('success', 'Device wurde erfolgreich zugeordnet!', '');
      this.loadList();
    });
  }

  allocateRandomDevices() {
    if (this.allocationRandomForm.valid) {
      const value = this.allocationRandomForm.value;
      this.allocationService.allocateRandom(value.athleteList.split(new RegExp('\,|\;')), value.reallocate).subscribe((success) => {
        this.toasterService.pop('success', 'Athleten wurden erfolgreich zugeordnet!', '');
        this.loadList();
      });
    } else {
      this.toasterService.pop('error', 'Geben Sie eine gültige Läuferliste ein!', '#');
    }
  }

  deleteSwal(gpsdeviceAllocation: Gpsdeviceallocation, index: number) {
    Swal.fire({
      title: 'Zuordnung löschen?',
      text: 'Sind Sie sicher das sie die Zuordnung löschen wollen?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#D32F2F',
      cancelButtonColor: '#bdbdbd',
      confirmButtonTet: 'Löschen',
      cancelButtonText: 'Abbrechen',
      focusCancel: true,
    }).then((result) => {
      this.allocationService.delete(gpsdeviceAllocation.id).subscribe((success) => {
        this.gpsdeviceAllocations = new Array(),
        this.loadList();
      });
    });
  }
}
