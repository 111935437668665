import {Injectable} from '@angular/core';

import {TranslationLoader} from 'angular-translator';
import { HttpClient } from '@angular/common/http';
import { EventTranslation } from './../assets/i18n/event/event-translation';
import { GeneralTranlsation } from './../assets/i18n/general/general-translation';
import { GpsdeviceverwaltungTranslation } from './../assets/i18n/gpsdeviceverwaltung/gpsdeviceverwaltung-translation';
import { LaufmanagementAthletesTranslation } from './../assets/i18n/laufmanagement-athletes/laufmanagement-athletes-translation';
import { LaufmanagementCheckpointmanagersTranslsation } from './../assets/i18n/laufmanagement-checkpointmanagers/laufmanagement-checkpointmanagers-translation';
import { LaufmanagementCheckpointsTranslation } from './../assets/i18n/laufmanagement-checkpoints/laufmanagement-checkpoints-translation';
import { LaufmanagementDisciplineoverviewTranslation } from './../assets/i18n/laufmanagement-disciplineoverview/laufmanagement-disciplineoverview-translation';
import { LaufmanagementEventselectionTranslation } from './../assets/i18n/laufmanagement-eventselection/laufmanagement-eventselection-translation';
import { LaufverwaltungTranslation } from './../assets/i18n/laufverwaltung/laufverwaltung-translation';
import { LaufverwaltungAthletesTranslation } from './../assets/i18n/laufverwaltung-athletes/laufverwaltung-athletes-translation';
import { LaufverwaltungContactsTranslation } from './../assets/i18n/laufverwaltung-contacts/laufverwaltung-contacts-translation';
import { LivemanagementTranslation } from './../assets/i18n/livemanagement/livemanagement-translation';
import { LoginTranslation } from './../assets/i18n/login/login';
import { MessagesTranslation } from './../assets/i18n/messages/messages-translation';
import { NutzerverwaltungTranslation } from './../assets/i18n/nutzerverwaltung/nutzerverwaltung-translation';
import { SmsprotocolTranslation } from './../assets/i18n/smsprotocol/smsprotocol-translation';
import { NotificationsTranslation } from './../assets/i18n/notifications/notifications-translation';
import { LaufmanagementGpsdeviceallocationTranslation } from './../assets/i18n/laufmanagement-gpsdeviceallocation/laufmanagement-gpsdeviceallocation-translation';
import { LaufverwaltungSponsorsTranslation } from './../assets/i18n/laufverwaltung-sponsors/laufverwaltung-sponsors-translation';

declare var $;
@Injectable()
export class TranslationLoaderStatic extends TranslationLoader {

    private translations: any = {
        en: {
        },
        de: {
        },
    };

    public Translations = [
        new EventTranslation(),
        new GeneralTranlsation(),
        new GpsdeviceverwaltungTranslation(),
        new LaufmanagementAthletesTranslation(),
        new LaufmanagementCheckpointmanagersTranslsation(),
        new LaufmanagementCheckpointsTranslation(),
        new LaufmanagementDisciplineoverviewTranslation(),
        new LaufmanagementEventselectionTranslation(),
        new LaufmanagementGpsdeviceallocationTranslation(),
        new LaufverwaltungTranslation(),
        new LaufverwaltungAthletesTranslation(),
        new LaufverwaltungContactsTranslation(),
        new LaufverwaltungSponsorsTranslation(),
        new LivemanagementTranslation(),
        new LoginTranslation(),
        new MessagesTranslation(),
        new NutzerverwaltungTranslation(),
        new SmsprotocolTranslation(),
        new NotificationsTranslation()
    ];

    public languages = [
        'de',
        'en'
    ];
    constructor(protected http: HttpClient) {
        super();

        this.Translations.forEach((translation: any) => {
            this.languages.forEach((language) => {
               this.translations[language] = $.extend({}, this.translations[language], translation.translation[language]);
            });
        });
    }

    public load({language}: any): Promise<object> {
        if (!this.translations[language]) {
            Promise.reject('Language unknown');
        }
        return Promise.resolve(this.translations[language]);
    }
}
