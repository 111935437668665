import { Injectable } from '@angular/core';
import { AuthenticationService } from '../global-services/authentication.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationFactoryService } from '../global-services/authentication-factory.service';
import { EventSelectorService } from '../global-services/event-selector.service';

declare var Swal;

@Injectable()
export class AuthorizationGuard implements CanActivate {

  private authService: AuthenticationService;

  constructor(private authFactory: AuthenticationFactoryService,
              public eventSelectorService: EventSelectorService,
              private router: Router) {
    this.authService = authFactory.getAuthenticationService();
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
        if(next.data.eventIdNeeded && !this.eventSelectorService.isEventSelected()){
          Swal.fire({
                      title: "Sie müssen zuerst eine Verantaltung auswählen.",
                      type: 'error',
                      showCancelButton: false,
                      showConfirmButton: false,
                      timer: 3500
                    }).then((result) => {
                      console.log("test");
                    });

          this.router.navigate(['/dashboard']);
          resolve(false);
        }

        // if(this.authService.isLoggedIn()){
        //   this.authService.hasUserNeededRole(next.data.role).then((rightRole) => {
        //     if(rightRole){
        //       resolve(true);
        //     }else{
        //       if(next.routeConfig.path == 'dashboard'){
        //         this.router.navigate(["/login"]);
        //       }else{
        //         this.router.navigate(['/dashboard']);
        //       }
        //       resolve(false);
        //     }
        //   }, (reject)=> {
        //     resolve(false);
        //   });
        // }else{
        //   if(next.routeConfig.path == 'login'){
        //     resolve(true);
        //   }else{
        //     this.router.navigate(["/login"]);
        //     resolve(false);
        //   }
        // }
        resolve(true);
    });
  }
}
