export class SmsprotocolTranslation {
    translation = {
        de: {
            'SMSPROTOCOLUEBERSICHT' : 'SMS-Protkollübersicht',
            'SMSPROTOCOLUEBERSICHT_BESCHREIBUNG' : 'Beschreibung der SMS-Protokollübersicht',
            'RUN_THROUGH' : 'Durchlauf',
            'GIVE_UP' : 'Aufgabe',
            'FASTINPUT_HEADER' : 'Schnelleingabe',
            'ERLEDIGT' : 'Erledigt',
        }
    };
}
