import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TableSortFilterPaginate } from '../table-sort-filter-paginate';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss']
})
export class TableSearchComponent implements OnInit {
  @Input() searchFilerPagination : TableSortFilterPaginate;
  @Output() searchChanged : EventEmitter<TableSortFilterPaginate> = new EventEmitter<TableSortFilterPaginate>();

  constructor(private route: ActivatedRoute,
              private router: Router){}

  ngOnInit(){}

  searchStringChanged(){
    if(this.searchFilerPagination.search != undefined){

      if(this.searchFilerPagination.search.length >= 3){
        this.searchFilerPagination.page=1;
        this.searchChanged.emit(this.searchFilerPagination);
      }else if(this.searchFilerPagination.search.length == 0){
        this.searchChanged.emit(this.searchFilerPagination);
      }

      this.router.navigate([this.route.snapshot.url[0].path, this.searchFilerPagination.getNavigationParameter()]);
    }
  }

}
