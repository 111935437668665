import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToasterService } from 'angular2-toaster';
import { FastInputService } from './fast-input.service';
import { DatePipe } from '@angular/common';
import { first } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Checkpoint } from '../classes/Checkpoints/Checkpoint';
import { Checkpointpass } from '../classes/Checkpointpasses/Checkpointpass';
import { CheckpointpassReport } from '../classes/Checkpointpasses/CheckpointpassReport';
import { EventSelectorService } from '../global-services/event-selector.service';

@Component({
  selector: 'app-fast-input-formular',
  templateUrl: './fast-input-formular.component.html',
  styleUrls: ['./fast-input-formular.component.scss']
})
export class FastInputFormularComponent implements OnInit, OnDestroy {

  public fastInputForm: FormGroup;

  public checkpoints: Array<Checkpoint>;

  public checkpointSubscription: Subscription;
  private eventSubscription: Subscription;

  private eventid;

  constructor(private fb: FormBuilder,
              private router: ActivatedRoute,
              private toasterService: ToasterService,
              private eventService: EventSelectorService,
              private fastInputService: FastInputService) {
    this.fastInputForm = this.fb.group({
      startnummer: ['', Validators.compose([Validators.required])],
      checkpointId: [0, Validators.compose([Validators.required])]
    });

    this.checkpoints = new Array();
  }

  private loadCheckpoints() {
    if (this.checkpointSubscription !== undefined) {
      this.checkpointSubscription.unsubscribe();
    }
    this.checkpointSubscription = this.fastInputService.listCheckpoints(this.eventid)
                                                        .subscribe((checkpoints: Array<Checkpoint>) => {
      this.checkpoints = checkpoints;
    });

  }

  ngOnInit() {
    this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((event: number) => {
      this.eventid = event;
      this.loadCheckpoints();
    });
  }

  ngOnDestroy() {
    if (this.checkpointSubscription !== undefined) {
      this.checkpointSubscription.unsubscribe();
    }

    this.eventSubscription.unsubscribe();
  }

  fastInputKeyPressed(keyEvent: any) {
    if (this.fastInputForm.value.startnummer !== '') {
      if (keyEvent.which === 13) {
        this.sendCheckpointpass(true);
      } else if (keyEvent.which === 32) {
        this.sendCheckpointpass(false);
      }
    }

  }

  sendCheckpointpass(status: boolean) {
    const datePipe = new DatePipe('en-US');

    const checkpointpass: CheckpointpassReport = {
      athleteStatus: status,
      enteredRetrospectively: true,
      startnumber: this.fastInputForm.value.startnummer,
      checkpointId: parseInt(this.fastInputForm.value.checkpointId, 10),
      passingtime: datePipe.transform(new Date(), environment.JAVA_STRING_FORMAT),
      checkpointmanagerId: null
    };
    this.fastInputService.fastInput(checkpointpass).pipe(first()).subscribe((success) => {
      this.toasterService.pop('success', 'Input wurde erfolgreich gespeichert', '');
      this.fastInputForm.patchValue({
        startnummer: ''
      });
    });
  }

}
