import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { first } from 'rxjs/operators';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

@Injectable({
  providedIn: 'root'
})
export class GpsDeviceAllocationService {
  constructor(public http: HttpClient) {

  }

  list() {
    return this.http.post(environment.baseUrl + 'gpsdeviceallocation/list-by-event/{:eventId}', new FilteredOrderedListRequest())
                    .pipe(first());
  }
  delete(id: number) {
    return this.http.delete(environment.baseUrl + 'gpsdeviceallocation/' + id, {}).pipe(first());
  }

  athleteList() {
    return this.http.get(environment.baseUrl + 'athletes/list-all-by-event/{:eventId}', {}).pipe(first());
  }

  deviceList() {
    return this.http.get(environment.baseUrl + 'gpsdevice/list-all', {}).pipe(first());
  }

  allocateDevice(deviceId: any, athleteId: any) {
    return this.http.get(environment.baseUrl + 'gpsdeviceallocation/allocateDevice/{:eventId}/' + athleteId + '/' + deviceId).pipe(first());
  }

  allocateRandom(athleteList: Array<string>, realocate: boolean) {
    const url = environment.baseUrl + 'gpsdeviceallocation/allocateDevices/{:eventId}/' + ((realocate === true) ? '1' : '10');
    return this.http.post(url, athleteList, {});
  }


}
