import { first } from 'rxjs/operators';
import { Contact } from './../classes/Events/Contact';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LaufContactEditService {
  constructor(private http: HttpClient) { }

  edit(value: Contact) {
    return this.http.put(environment.baseUrl + 'contacts', value, {}).pipe(first());
  }
  add(value: Contact) {
    return this.http.post(environment.baseUrl + 'contacts', value, {}).pipe(first());
  }

  get(id: number) {
    return this.http.get(environment.baseUrl + 'contacts/' + id,{}).pipe(first());
  }

}
