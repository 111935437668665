import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { TableSortFilterPaginate } from '../table-sort-filter-paginate';

@Component({
  selector: '[app-table-select-all]',
  templateUrl: './table-select-all.component.html',
  styleUrls: ['./table-select-all.component.scss']
})
export class TableSelectAllComponent implements OnInit {
  @Input() selectionModel : TableSortFilterPaginate;
  @Input() selectables : number[];

  constructor(){}

  ngOnInit(){}

  toggleAll(){
    if(this.allSelected()){
      this.selectionModel.selected = new Array();
    }else{
      this.selectionModel.selected = new Array();
      this.selectionModel.selected = this.selectionModel.selected.concat(this.selectables);
    }
  }

  allSelected(){
    return this.selectionModel.selected.length == this.selectables.length ? true : false;
  }

}
