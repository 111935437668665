import { Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import { PasswordValidation } from './password-validation';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../global-services/authentication.service';
import { AuthenticationFactoryService } from '../../global-services/authentication-factory.service';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements OnInit {
  public passwordInput: FormGroup;
  public securelevel: number;
  public passwordFieldType: string;
  public passwordGotGenerated: boolean;
  public lastGeneratedValue: string;

  private authenticationService: AuthenticationService;
  private _id: number;

  @Input() triedToSubmit = false;
  @Input()
  set id(id: number) {
    this._id = id;
    if (this._id === 0) {
    }
  }
  @Input() row = false;
  @Output() password = new EventEmitter<FormGroup>();

  constructor(private fb: FormBuilder,
            private authFactory: AuthenticationFactoryService) {
    this.passwordGotGenerated = false;
    this.lastGeneratedValue = '';
    this.securelevel = 0;
    this.passwordFieldType = 'password';

    this.authenticationService = authFactory.getAuthenticationService();

    this.passwordInput = this.fb.group({
      'password' : [null, Validators.compose([Validators.maxLength(1024)])],
      'password_repeat' : [null, Validators.compose([Validators.maxLength(1024)])]
    }, {
      validator: PasswordValidation.matchPassword
    });

    this.passwordInput.valueChanges.subscribe( group => {
      this.password.emit(this.passwordInput);
    } );
  }

  ngOnInit() {
    this.passwordInput.valueChanges.subscribe(() => {
       if (this.lastGeneratedValue !== this.passwordInput.controls['password'].value) {
         this.passwordFieldType = 'password';
         this.passwordGotGenerated = false;
       }

       this.calculatePasswordSecureLevel();
     });

     this.authenticationService.getUserRole().then((resolve) => {
       if (resolve === 'operator') {
        this.passwordInput.disable();
       }
     });
  }

  generatePassword() {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz!?=)()/&%$§{[]}##~';
    const string_length = 16;
    let randomstring = '';

    for (let i = 0; i < string_length; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    this.passwordGotGenerated = true;
    this.passwordFieldType = 'text';
    this.lastGeneratedValue = randomstring;
    this.passwordInput.setValue({'password' : randomstring, 'password_repeat' : randomstring});
    this.calculatePasswordSecureLevel();
  }

  calculatePasswordSecureLevel() {
    let newSecureLevel = 0;
    let newPassword = this.passwordInput.controls['password'].value;

    if (newPassword === null || newPassword === undefined) {
      newPassword =  '';
    }

    if (newPassword.length > 10) newSecureLevel += 30;
    if (newPassword.length > 15) newSecureLevel += 10;
    if (/\d/.test(newPassword)) newSecureLevel += 15;
    if (/[A-Z]/.test(newPassword)) newSecureLevel += 15;
    if (/[a-z]/.test(newPassword)) newSecureLevel += 10;
    if (/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword)) newSecureLevel += 20;

    this.securelevel = newSecureLevel;
  }

  getPasswordSecureLevelText(secureLevel: number) {
    if (secureLevel > 80) {
      return 'sehr gut';
    } else if (secureLevel > 60) {
      return 'gut';
    } else if (secureLevel > 45) {
      return 'mittel';
    } else if (secureLevel > 25) {
      return 'schwach';
    } else{
      return 'sehr schwach';
    }
  }

  getPasswordSecureLevelClass(secureLevel:number, context:string){
    if(secureLevel > 80){
      return context == 'progress' ? 'bg-success' : 'text-success';
    }else if(secureLevel > 60){
      return context == 'progress' ? 'bg-info' : 'text-info';
    }else if(secureLevel > 45){
      return context == 'progress' ? 'bg-primary' : 'text-primary';
    }else if(secureLevel > 25){
      return context == 'progress' ? 'bg-warning' : 'text-warning';
    }else{
      return context == 'progress' ? 'bg-danger' : 'text-danger';
    }
  }
}
