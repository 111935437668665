import { DeviceEditService } from './device-edit.service';
import { Gpsdevice } from './../classes/Gpsdevices/Gpsdevice';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationFactoryService } from '../global-services/authentication-factory.service';
import { ToasterService } from 'angular2-toaster';
import { AuthenticationService } from '../global-services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-page-gps-device-edit',
  templateUrl: './page-gps-device-edit.component.html',
  styleUrls: ['./page-gps-device-edit.component.scss'],
  providers: [
    DeviceEditService
  ]
})
export class PageGpsDeviceEditComponent implements OnInit {

  private sub: any;

  public id: number;
  public editDevice: any;
  private user: any;

  public triedToSubmit = false;
  public activeUserIsAdmin = false;


  public possibleTypes: Array<any>;

  public type_selectize_config = {
    labelField: 'name',
    valueField: 'name',
    searchField: ['name']
  };
  constructor(private fb: FormBuilder,
    private toasterService: ToasterService,
    private authFactory: AuthenticationFactoryService,
    private deviceService: DeviceEditService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) {

      this.authenticationService = this.authFactory.getAuthenticationService();
      this.editDevice = this.fb.group({
        'id': [0, Validators.compose([])],
        'imei': ['', Validators.compose([Validators.maxLength(255)])],
        'imei2': ['', Validators.compose([Validators.maxLength(255)])],
        'name':['', Validators.compose([Validators.required, Validators.maxLength(255)])],
        'bic': ['', Validators.compose([Validators.maxLength(255)])],
        'iccid': ['', Validators.compose([Validators.maxLength(255)])],
        'msisdn': ['', Validators.compose([Validators.maxLength(255)])],
        'type':['', Validators.compose([Validators.maxLength(255)])]
      });

      this.possibleTypes = new Array();
    }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];

      if (this.id !== 0) {
        this.loadDevice(this.id);
      }
     });

     this.deviceService.possibleTypes().subscribe((success: Array<string>) => {
      this.possibleTypes = new Array();
      console.log(success);
      success.forEach((type: string) => {
        this.possibleTypes.push({name: type});
      });

      console.log(this.possibleTypes);
     });
  }

  loadDevice(id: number) {
    this.deviceService.get(id).subscribe((gpsdevice: Gpsdevice) => {
      this.editDevice.patchValue(gpsdevice);
    });
  }

  changeUser() {
    const value = this.editDevice.value;
    const valid = this.editDevice.valid;

    if (valid) {
      // Do something
      if (value.id === 0) {
        this.deviceService.add(value).subscribe((success) => {
          this.toasterService.pop('success', 'GPS Gerät wurde erfolgreich gespeichert!', '');
          this.router.navigate(['gpsdevices']);
        });

      } else {
        this.deviceService.edit(value).subscribe((success) => {
          this.toasterService.pop('success', 'GPS Gerät wurde erfolgreich gespeichert!', '');
          this.router.navigate(['gpsdevices']);
        });
      }
    } else {
      this.toasterService.pop('error', 'Füllen Sie alle benötigten Felder aus', '');
    }

    this.triedToSubmit = true;
   }

  hasUserDeviceAssigned(deviceid: number) {
    let retVal = false;
    if (this.user !== undefined) {
      this.user.user_devices.forEach(user_device => {
          if (deviceid === user_device.device_id) {
            retVal = true;
          }
      });
    }

    return retVal;
  }

  hasUserAssigned(type: string, deviceid: number) {
    let retVal = false;
    let arr = new Array();

    if (this.user === undefined) {
      return false;
    }
    if (this.user.user_indicators === undefined) {
      this.user.user_indicators = [];
    }

    if (this.user !== undefined) {
      if (type === 'device') {
        arr = this.user.user_devices;
      } else if (type === 'location') {
        arr = this.user.user_locations;
      } else if (type === 'indicator') {
        arr = this.user.user_indicators;
      }

      if(arr != null){
        arr.forEach(el => {
            if (deviceid === el.device_id || deviceid === el.location_id || deviceid === el.indicators_id) {
              retVal = true;
            }
        });
      }
    }

    return retVal;
  }

  assignDeviceToUser(deviceid: number) {
    this.user.user_devices.push(
      {
        id: 0,
        user_id: this.user.id,
        device_id: deviceid
      }
    );
  }

  assignLocationToUser(locationid: number) {
    this.user.user_locations.push(
      {
        id: 0,
        user_id: this.user.id,
        location_id: locationid
      }
    );
  }

  assignIndicatorToUser(indicatorid: number) {
    if (this.user.user_indicators === undefined) {
      this.user.user_indicators = [];
    }
    this.user.user_indicators.push(
      {
        id: 0,
        user_id: this.user.id,
        indicators_id: indicatorid
      }
    );

  }

  removeDeviceFromUser(deviceid: number) {
    let device = null;

    this.user.user_devices.forEach(user_device => {
      if (user_device.device_id === deviceid) {
        device = user_device;
      }
    });

    if (device !== null) {
      const i = this.user.user_devices.indexOf(device);
      this.user.user_devices.splice(i, 1);
    }
  }

  removeIndicatorFromUser(indicatorid: number) {
    let indicator = null;

    if (this.user.user_indicators === undefined) {
      this.user.user_indicators = [];
    }

    this.user.user_indicators.forEach(user_indicator => {

      if (user_indicator.indicators_id === indicatorid) {
        indicator = user_indicator;
      }
    });

    if (indicator !== null) {
      const i = this.user.user_indicators.indexOf(indicator);
      this.user.user_indicators.splice(i, 1);
    }
  }

  removeLocationFromUser(locationid: number) {
    let location = null;

    this.user.user_locations.forEach(user_location => {
      if (user_location.location_id === locationid) {
        location = user_location;
      }
    });

    if (location !== null) {
      const i = this.user.user_locations.indexOf(location);
      this.user.user_locations.splice(i, 1);
    }
  }

  passwordChanged(obj) {
    this.editDevice.password = obj;
  }

  onDrop(event: any, assign: boolean, type: string) {
    if (event.data.assigned === false && assign === true) {
      if (type === 'device') {
        this.assignDeviceToUser(event.data.device.id);
      } else if (type === 'location') {
        this.assignLocationToUser(event.data.location.id);
      } else if (type === 'indicator') {
        this.assignIndicatorToUser(event.data.indicator.id);
      }
    } else if (event.data.assigned === true && assign === false) {
      if (type === 'device') {
        this.removeDeviceFromUser(event.data.device.id);
      } else if (type === 'location') {
        this.removeLocationFromUser(event.data.location.id);
      } else if (type === 'indicator') {
        this.removeIndicatorFromUser(event.data.indicator.id);
      }
    } else {
    }

  }
}
