import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Login } from '../classes/Login/Login';
import { NewPasswordOrder } from '../classes/Login/NewPasswordOrder';
import { AuthenticationFactoryService } from '../global-services/authentication-factory.service';
import { AuthenticationService } from '../global-services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
  public loginForm: FormGroup;
  public orderNewPasswordForm: FormGroup;
  public isOrderNewPasswordVisible: boolean;

  public message: string;
  public showMessage = false;

  private sub: any;
  private authenticationService: AuthenticationService;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private toasterService: ToasterService,
              private authFactory: AuthenticationFactoryService) {
    this.authenticationService = this.authFactory.getAuthenticationService();
    this.isOrderNewPasswordVisible = false;
    this.loginForm = fb.group({
        'username' : [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
        'password' : [null, Validators.compose([Validators.required, Validators.maxLength(255)])]
      });

    this.orderNewPasswordForm = fb.group({
        'username' : [null, Validators.compose([Validators.required, Validators.maxLength(255)])]
    });

  }

  ngOnInit() {
    this.sub = this.route.paramMap.subscribe((params: any) => {

        if (params.params.message !== null && params.params.message !== undefined) {
        this.showMessage = true;
          this.message = params.params.message;
        }
      }
    );
  }

  login(valid: boolean, form: Login) {
    if (valid) {
      this.authenticationService.login(form).then(resolve => {
        this.router.navigate(['/dashboard']);
      }, reject => {
        this.toasterService.pop('error', 'Login fehlgeschlagen', 'Bitte überprüfen Sie Username und Passwort!');
      });
    }
  }

  orderNewPassword(valid: boolean, form: any) {

  }

  startNewPasswordOrder() {
    this.isOrderNewPasswordVisible = true;
  }
}
