import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { HeaderService } from './header.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../global-services/authentication.service';
import { AuthenticationFactoryService } from '../global-services/authentication-factory.service';
import { EventSelectorService } from '../global-services/event-selector.service';
import { Event } from '../classes/Events/Event';
import { NotificationService } from '../socket-services/notification.service';
import { Subscription } from 'rxjs';
import { Notification } from '../classes/Notifications/Notification';
import { isArray } from 'util';
import { SmsService } from '../socket-services/sms.service';
import { Smsprotocolentry } from '../classes/Sms/Smsprotocolentry';
import { first } from 'rxjs/operators';

declare var jQuery;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public user: any;
  public selectedCompany: any;
  public showNavigationElements = {'mandatare' : false};
  public notifications: Notification[];
  public smss: Array<Smsprotocolentry>;

  public selectedEventName = 'Veranstaltung wählen';
  public events: Event[];

  private eventId: number;

  private smsSubscription: Subscription;
  private notificationSubscription: Subscription;
  private eventSubscription: Subscription;
  private eventNameSubscribtion: Subscription;
  private eventListSubscription: Subscription;
  private authenticationService: AuthenticationService;

  public loadingEvents: boolean;
  public loadingNotifications: boolean;
  public loadingSMS: boolean;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private ref: ChangeDetectorRef,
              private headerService: HeaderService,
              private notificationService: NotificationService,
              private smsService: SmsService,
              private eventSelectorService: EventSelectorService,
              private authFactory: AuthenticationFactoryService) {

      this.authenticationService = authFactory.getAuthenticationService();
      this.notifications = new Array();
      this.smss = new Array();
      this.reloadNotifications();

      this.loadingEvents = false;
      this.loadingNotifications = false;
      this.loadingSMS = false;
  }

  ngOnInit() {
    this.authenticationService.getUserRole().then((resolve) => {
      if (resolve === 'admin') {
        this.showNavigationElements.mandatare = true;
      }
    });

    this.authenticationService.isAuthenticated().subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        this.eventSelectorService.reloadAllEvents();
      }
    });

    this.eventSubscription = this.eventSelectorService.subscribeEventSelection().subscribe((eventId: number) => {
      this.eventId = eventId;
      this.subscribeToNotifiactionsAndSms();
    });

    this.eventNameSubscribtion = this.eventSelectorService.subscribeEventName().subscribe((eventName: string) => {
      this.selectedEventName = eventName;
    });

    this.eventListSubscription = this.eventSelectorService.subscribeEventList().subscribe((eventsList: Event[]) => {
      this.events = eventsList;

      if (this.events.length > 0) {
        this.loadingEvents = false;
      }
    });

    this.loadingEvents = true;
    this.eventSelectorService.reloadAllEvents();
  }

  subscribeToNotifiactionsAndSms() {
    this.loadingNotifications = true;
    this.loadingSMS = true;
    if (this.notificationSubscription !== undefined && this.notificationSubscription !== null) {
      this.notificationSubscription.unsubscribe();
    }

    if (this.smsSubscription !== undefined && this.smsSubscription !== null) {
      this.smsSubscription.unsubscribe();
    }
    this.notificationSubscription = this.notificationService.subscribeToEvent(this.eventId)
                                                            .subscribe((notifications: Array<Notification>) => {
                                                              this.loadingNotifications = false;
                                                              this.notifications = notifications;
                                                            });

    this.smsSubscription = this.smsService.subscribeToEvent(this.eventId)
                                                            .pipe(first())
                                                            .subscribe((smss: Array<Smsprotocolentry>) => {
                                                              this.loadingSMS = false;
                                                              this.smss = smss;
                                                            });


  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
    this.smsSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
    this.eventNameSubscribtion.unsubscribe();
    this.eventListSubscription.unsubscribe();
  }

  getLastNotifications(count: number) {
    return this.notifications.filter((not: Notification) => {
      return not.finished === false;
    }).sort((a: Notification, b: Notification) => {
      if (a.timestamp > b.timestamp) {
        return 1;
      } else {
        return -1;
      }
    }).filter((not: Notification, index: number) => {
      return index < count;
    });
  }

  getLastSMS(count: number) {
    return this.smss.filter((not: Smsprotocolentry) => {
      return not.processed === false;
    }).sort((a: Smsprotocolentry, b: Smsprotocolentry) => {
      if (a.id > b.id) {
        return 1;
      } else {
        return -1;
      }
    }).filter((sms: Smsprotocolentry, index: number) => {
      return index < count;
    });
  }
  private reloadNotifications() {
    this.headerService.listNotifications().subscribe((success: Notification[]) => {
      this.notifications = success;
    });
  }


  toggleSideBarMenu() {
    jQuery('body').toggleClass('show-sidebar');
    jQuery('.nav-toggler i').toggleClass('ti-menu');
    jQuery('.nav-toggler i').addClass('ti-close');
  }

  selectEvent(eventId: number) {
    this.eventSelectorService.selectEvent(eventId);
  }

  logout() {
    this.authenticationService.logout().then(
      resolve => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('mandantToken');
        this.eventSelectorService.clearSelection();
        this.router.navigate(['/login']);
      },
      reject => {});
  }

  getDateFromTimestamp(not: Notification) {
    return new Date(not.timestamp);
  }
}
