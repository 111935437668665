import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveDisciplineService {

  constructor(private http: HttpClient) { }

  getDisciplineDetails(id: number) {
      return this.http.get(environment.baseUrl + 'disciplines/with-details/' + id,{}).pipe(first());
  }

  getLiveDisciplineInfos(id: number) {
      return this.http.get(environment.baseUrl + 'disciplineliveinfos/' + id,{}).pipe(first());
  }
}
