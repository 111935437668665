import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { BaseService } from './base-service';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';
import { Event } from '../classes/Events/Event';

@Injectable({
  providedIn: 'root'
})
export class EventSelectorService extends BaseService {

  private selectedEvent = new BehaviorSubject<number>(this.initalLoad());
  private selectedEventName = new BehaviorSubject<string>('');
  private availableEvents = new BehaviorSubject<Event[]>(new Array());

  constructor(protected http: HttpClient) {
    super();
  }

  private initalLoad() {
    if (localStorage.getItem('selected_event_id') != null) {
      return +localStorage.getItem('selected_event_id');
    }

    return 59;
  }

  public reloadAllEvents() {
    this.http.post<Event[]>(this.baseUrl + 'events/list', new FilteredOrderedListRequest()).subscribe(success => {
        this.availableEvents.next(success);

        if (this.selectedEvent.value && this.selectedEvent.value !== 0) {
            console.log('we loaded all events and found');
            console.log(this.selectedEvent);
            this.selectedEventName.next(this.getEventNameById(this.selectedEvent.value));
        } else if (success.length > 0) {
          console.log('We found nothing and try to select');
          console.log(success[0].id);
          this.selectEvent(success[0].id);
        }
    }, error => {});
  }

  public selectEvent(eventId: number) {
    this.selectedEvent.next(eventId);
    this.selectedEventName.next(this.getEventNameById(eventId));
    localStorage.setItem('selected_event_id', String(eventId));
  }

  public getSelectedEvent() {
    return this.selectedEvent.value;
  }

  public isEventSelected() {
    return this.selectedEvent.value != null;
  }

  public subscribeEventSelection(): Observable<number> {
    return this.selectedEvent.asObservable();
  }

  public subscribeEventList(): Observable<Event[]> {
    return this.availableEvents.asObservable();
  }

  public subscribeEventName(): Observable<string> {
    return this.selectedEventName.asObservable();
  }

  public clearSelection() {
    this.selectedEvent.next(null);
    localStorage.removeItem('selected_event_id');
  }

  private getEventNameById(eventId: number) {
    for (const event of this.availableEvents.value) {
      if (event.id === eventId) {
          return event.name;
      }
    }

    return null;
  }
}
