import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';

import { TranslatorModule } from 'angular-translator';
import { NgSelectizeModule } from 'ng-selectize';
import { DndModule } from 'ngx-drag-drop';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { NgxMapboxGLModule, MapService as MapBoxService} from 'ngx-mapbox-gl';
import { AppRoutingModule } from './app-routing.module';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NouisliderModule } from 'ng2-nouislider';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PasswordInputComponent } from './form-components/password-input/password-input.component';

import { AuthorizationGuard } from './guards/authorization.guard';
import { AuthenticationService } from './global-services/authentication.service';
import { AuthInterceptor } from './Interceptors/auth.interceptor';
import { AuthenticationFactoryService } from './global-services/authentication-factory.service';

import { HeaderService } from './header/header.service';
import { EventSelectorService } from './global-services/event-selector.service';
import { PageBreadcrumbHeaderComponent } from './page-breadcrumb-header/page-breadcrumb-header.component';
import { PageLoginComponent } from './page-login/page-login.component';
import { PageLaufDisciplineEditComponent } from './page-lauf-discipline-edit/page-lauf-discipline-edit.component';
import { PageLaufCheckpointmanagerEditComponent } from './page-lauf-checkpointmanager-edit/page-lauf-checkpointmanager-edit.component';
import { PageLaufCheckpointEditComponent } from './page-lauf-checkpoint-edit/page-lauf-checkpoint-edit.component';
import { PageLaufAthleteEditComponent } from './page-lauf-athlete-edit/page-lauf-athlete-edit.component';
import { PageLaufverwaltungComponent } from './page-laufverwaltung/page-laufverwaltung.component';
import { PageLaufEditComponent } from './page-lauf-edit/page-lauf-edit.component';
import { PageLaufAthleteListComponent } from './page-lauf-athlete-list/page-lauf-athlete-list.component';
import { PageLaufDisciplineListComponent } from './page-lauf-discipline-list/page-lauf-discipline-list.component';
import { PageLaufCheckpointListComponent } from './page-lauf-checkpoint-list/page-lauf-checkpoint-list.component';
import { PageLaufCheckpointmanagerListComponent } from './page-lauf-checkpointmanager-list/page-lauf-checkpointmanager-list.component';
import { PageLaufAthleteImportComponent } from './page-lauf-athlete-import/page-lauf-athlete-import.component';
import { PageLaufContactListComponent } from './page-lauf-contact-list/page-lauf-contact-list.component';
import { PageLaufContactEditComponent } from './page-lauf-contact-edit/page-lauf-contact-edit.component';
import { PageLaufSponsorListComponent } from './page-lauf-sponsor-list/page-lauf-sponsor-list.component';
import { PageLaufSponsorEditComponent } from './page-lauf-sponsor-edit/page-lauf-sponsor-edit.component';
import { PageLaufGpsDeviceAllocationComponent } from './page-lauf-gps-device-allocation/page-lauf-gps-device-allocation.component';
import { PageUserEditComponent } from './page-user-edit/page-user-edit.component';
import { PageGpsDeviceListComponent } from './page-gps-device-list/page-gps-device-list.component';
import { PageGpsDeviceEditComponent } from './page-gps-device-edit/page-gps-device-edit.component';
import { PageNotificationsComponent } from './page-notifications/page-notifications.component';
import { PageSmsProtocolComponent } from './page-sms-protocol/page-sms-protocol.component';
import { PageLiveAthleteListComponent } from './page-live-athlete-list/page-live-athlete-list.component';
import { PageLiveDisciplineDetailsComponent } from './page-live-discipline-details/page-live-discipline-details.component';
import { PageLiveCheckpointDetailsComponent } from './page-live-checkpoint-details/page-live-checkpoint-details.component';
import { PageLiveAthleteRecordingComponent } from './page-live-athlete-recording/page-live-athlete-recording.component';
import { PageLiveDashboardComponent } from './page-live-dashboard/page-live-dashboard.component';
import { PageUserListComponent } from './page-user-list/page-user-list.component';

import { TablePaginationComponent } from './table-directives/table-pagination/table-pagination.component';
import { TableSortheaderComponent } from './table-directives/table-sortheader/table-sortheader.component';
import { TableSelectRowComponent } from './table-directives/table-select-row/table-select-row.component';
import { TableSelectAllComponent } from './table-directives/table-select-all/table-select-all.component';
import { TableSearchComponent } from './table-directives/table-search/table-search.component';
import { TableMassoperationSelectionComponent } from './table-directives/table-massoperation-selection/table-massoperation-selection.component';
import { LiveDisciplineListCheckpointsComponent } from './live-discipline-list-checkpoints/live-discipline-list-checkpoints.component';

import { OwlModule } from 'ngx-owl-carousel';
import { FastInputFormularComponent } from './fast-input-formular/fast-input-formular.component';
import { SmsService } from './socket-services/sms.service';
import { DisciplineLiveinfoService } from './socket-services/discipline-liveinfo.service';
import { NotificationService } from './socket-services/notification.service';
import { TranslationLoaderStatic } from './TranslationLoaderStatic';
import { DropzoneModule, DROPZONE_CONFIG, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SortAndFilterByPipe } from './table-directives/pipes/sort-and-filter-by.pipe';
import { AthleteLiveinfoService } from './socket-services/athlete-liveinfo.service';
import { FixGeojsonService } from './geography/fix-geojson.service';

import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time.module';
// import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DB_TIME_FORMAT } from './datepicker';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    SpinnerComponent,
    PasswordInputComponent,
    PageBreadcrumbHeaderComponent,
    PageLoginComponent,
    PageLaufDisciplineEditComponent,
    PageLaufCheckpointmanagerEditComponent,
    PageLaufCheckpointEditComponent,
    PageLaufAthleteEditComponent,
    PageLaufverwaltungComponent,
    PageLaufEditComponent,
    PageLaufAthleteListComponent,
    PageLaufAthleteImportComponent,
    PageLaufDisciplineListComponent,
    PageLaufCheckpointListComponent,
    PageLaufCheckpointmanagerListComponent,
    PageLaufContactListComponent,
    PageLaufContactEditComponent,
    PageLaufSponsorListComponent,
    PageLaufSponsorEditComponent,
    PageLaufGpsDeviceAllocationComponent,
    PageUserListComponent,
    PageUserEditComponent,
    PageGpsDeviceListComponent,
    PageGpsDeviceEditComponent,
    PageNotificationsComponent,
    PageSmsProtocolComponent,
    PageLiveAthleteListComponent,
    PageLiveDisciplineDetailsComponent,
    PageLiveCheckpointDetailsComponent,
    PageLiveAthleteRecordingComponent,
    FastInputFormularComponent,
    LiveDisciplineListCheckpointsComponent,
    TableSortheaderComponent,
    TablePaginationComponent,
    TableSelectRowComponent,
    TableSelectAllComponent,
    TableSearchComponent,
    TableMassoperationSelectionComponent,
    SortAndFilterByPipe,
    PageLiveDashboardComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgDatepickerModule,
    DropzoneModule,
    NouisliderModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoibGF1ZndlcmtzdGF0dCIsImEiOiJ2bzhYSnNVIn0.uRV8nBO5gVxd9kTQ4GFIFA',
    }),
    HttpClientModule,
    TranslatorModule.forRoot({
      providedLanguages: ['de', 'en'],
      loader: TranslationLoaderStatic,
      defaultLanguage: 'de',
      detectLanguage: true
    }),
    ToasterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectizeModule,
    OwlModule,
    DragDropModule,
    DndModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    OwlNativeDateTimeModule
  ],
  providers: [
    Title,
    AuthorizationGuard,
    TranslationLoaderStatic,
    AuthenticationService,
    AuthenticationFactoryService,
    ToasterService,
    HeaderService,
    SmsService,
    DisciplineLiveinfoService,
    MapBoxService,
    NotificationService,
    EventSelectorService,
    FixGeojsonService,
    AthleteLiveinfoService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    // { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'de'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
