import { environment } from './../../environments/environment';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Checkpointmanager } from '../classes/Checkpointmanagers/Checkpointmanager';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

@Injectable({
  providedIn: 'root'
})
export class CheckpointmanagerListService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient){}

  public list(filteredOrderedListRequest:FilteredOrderedListRequest): Observable<Checkpointmanager[]>{
    return this.http.post<Checkpointmanager[]>(this.baseUrl + 'checkpointmanagers/list-by-event/{:eventId}', filteredOrderedListRequest);
  }

  public delete(checkpointmanagerId:number){
    return this.http.delete(this.baseUrl + 'checkpointmanagers/' + checkpointmanagerId);
  }
}
