import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationFactoryService {

  private static authenticationService: AuthenticationService;

  constructor(private autService: AuthenticationService) {
    if (AuthenticationFactoryService.authenticationService === undefined) {
      this.autService.loadUserRole();
      AuthenticationFactoryService.authenticationService = this.autService;
    }
  }

  public getAuthenticationService() {
    return AuthenticationFactoryService.authenticationService;
  }

}
