import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../global-services/authentication.service';
import { AuthenticationFactoryService } from '../global-services/authentication-factory.service';
import { LaufEditService } from './lauf-edit.service';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { EventSelectorService } from '../global-services/event-selector.service';


@Component({
  selector: 'app-page-lauf-edit',
  templateUrl: './page-lauf-edit.component.html',
  styleUrls: ['./page-lauf-edit.component.scss'],
  providers: [
    LaufEditService
  ]
})
export class PageLaufEditComponent implements OnInit {
  private sub: any;

  public id: number;
  public editEvent: any;

  public triedToSubmit = false;

  public logoIsUploading = false;
  public activeUserIsAdmin = false;

  public  DROPZONE_CONFIG: DropzoneConfigInterface = {
     url: 'https://files.trailscout.at/upload-eventlogos.php',
     maxFilesize: 50,
     acceptedFiles: 'image/*'
   };

  public draggables = {
    effectAllowed: 'move',
    disable: false,
    handle: false,
  };

  private authenticationService: AuthenticationService;

  constructor(private fb: FormBuilder,
              private router: Router,
              private toasterService: ToasterService,
              private authFactory: AuthenticationFactoryService,
              private laufEditService: LaufEditService,
              private eventSelectorService: EventSelectorService,
              private route: ActivatedRoute) {

      this.authenticationService = this.authFactory.getAuthenticationService();
      this.editEvent = this.fb.group({
        id: [0, Validators.compose([])],
        name: ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
        startdate: ['', Validators.compose([])],
        enddate: ['', Validators.compose([Validators.maxLength(255)])],
        teilnehmerurl:  ['', Validators.compose([])],
        phonenumber: ['', Validators.compose([])],
        logo: ['', Validators.compose([])],
      });

      globalThis.editEvent = this.editEvent;
    }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];

      this.authenticationService.getUserRole().then(resolve => {
        this.activeUserIsAdmin = (resolve === 'admin');
      });
     });

  }

  onUploadError(event) {
    this.toasterService.pop('error', 'Logo upload fehlgeschlagen!', '');
  }

  onUploadSuccess(args: Array<any>) {
    args.forEach((arg: any) => {
      if (arg.name ) {
        this.editEvent.patchValue({
          logo: 'https://files.trailscout.at/eventlogos/' + arg.name
        });
      } else {
      }
    });
  }

  changeEvent() {
    const value = this.editEvent.value;
    const valid = this.editEvent.valid;

    if (value.startdate.format !== undefined) {
      value.startdate = value.startdate.format('YYYYMMDDHHmmssZZ');
    }
    if (value.enddate.format !== undefined) {
      value.enddate = value.enddate.format('YYYYMMDDHHmmssZZ');
    }

    if (valid && !this.triedToSubmit) {
        this.triedToSubmit = true;

        if (value.id === 0 || value.id === undefined || value.id === null) {
            this.laufEditService.add(value).subscribe((success) => {
              this.triedToSubmit = false;
              this.eventSelectorService.reloadAllEvents();
              this.router.navigate(['laufverwaltung/athletes']);
            }, () => {
              this.triedToSubmit = false;
            });
        }
    } else {
        this.toasterService.pop('error', 'Füllen Sie alle benötigten Felder aus', '');
    }
   }
}
