// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api.trailscout.at/livetracking-api-server-rest-services/',
  baseWsUrl: 'wss://api.trailscout.at/livetracking-api-server-rest-services/',
  JAVA_STRING_FORMAT: 'DD.MM.YYYY H:mm:ss',
  nationLink: 'assets/nations.json'
};
