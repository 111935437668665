export class LivemanagementTranslation {
    translation = {
        de: {
            'M_HOEHE' : 'm Höhe',
            'VOM_VORHERIGEN_CHECKPOINT' : 'vom vorherigen Checkpoint',
            'ZUM_NAECHSTEN_CHECKPOINT' : 'zum nächsten Checkpoint',
            'CHECKPOINTMANAGER' : 'Checkpointmanager',
            'LETZTER_ATHLETE_ERWARTET_IN' : 'Letzter Athlete erwartet in',
            'ATHLETES' : 'Athleten',
            'ERWARTET IN' : 'erwartet in',
            'UEBERFAELLIG' : 'überfällig',
            'FASTEST_TIME' : 'frühster',
            'SLOWEST_TIME' : 'spätester'
        }
    };
}
