import { Component, OnInit, Input } from '@angular/core';
import { LiveDisciplinecheckpointService } from './live-disciplinecheckpoint.service';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-live-discipline-list-checkpoints',
  templateUrl: './live-discipline-list-checkpoints.component.html',
  styleUrls: ['./live-discipline-list-checkpoints.component.scss'],
  providers: [
      LiveDisciplinecheckpointService
  ]
})
export class LiveDisciplineListCheckpointsComponent implements OnInit {

  public checkpointLiveinfos: any;
  public overviewCount = 20;

  @Input()
  set checkpoint(infos: any) {
    if (infos.overview === undefined) {
      infos.overview = true;
    }
    console.log(infos);
    this.checkpointLiveinfos = infos;
  }

  constructor(private toasterService: ToasterService,
            private liveDisciplinecheckpointService: LiveDisciplinecheckpointService) { }

  ngOnInit() {}

  toggleOverview(element) {
    element.overview = !element.overview;
  }

  getAthletes() {
    if (this.checkpointLiveinfos.overview === true) {
      return this.checkpointLiveinfos.athletes.filter((element, index) => {
        return index < this.overviewCount;
      });
    } else {
      return this.checkpointLiveinfos.athletes;
    }
  }

  finishDisciplineCheckpoint(checkpointId: number) {
    this.liveDisciplinecheckpointService.finishDisciplineCheckpoint(checkpointId).subscribe((success: any) => {
        this.toasterService.pop('success', 'Checkpoint erfolgreich beendet', '');
    }, (error: any) => {
        this.toasterService.pop('error', 'Checkpoint konnte nicht geändert werden', '');
    });
  }

  showPhoneNumber(checkpointmanager) {
    // TODO: do something
  }

  setCheckpointpassToAthleteState(checkpointpassId: number, status: boolean) {
    this.liveDisciplinecheckpointService.setCheckpointpassToAthleteState(checkpointpassId, status).subscribe((success: any) => {
        this.toasterService.pop('success', 'Checkpointpass erfolgreich geändert', '');
    }, (error: any) => {
        this.toasterService.pop('error', 'Checkpointpass konnte nicht geändert werden', '');
    });
  }

  deleteCheckpointpass(checkpointpassId: number) {
    this.liveDisciplinecheckpointService.deleteCheckpointpass(checkpointpassId).subscribe((success: any) => {
        this.toasterService.pop('success', 'Checkpointpass gelöscht', '');
    }, (error: any) => {
        this.toasterService.pop('error', 'Checkpointpass konnte nicht gelöscht werden', '');
    });
  }
}
