import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../socket-services/notification.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Notification } from '../classes/Notifications/Notification';
import { first } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { isArray } from 'util';
import { EventSelectorService } from '../global-services/event-selector.service';

@Component({
  selector: 'app-page-notifications',
  templateUrl: './page-notifications.component.html',
  styleUrls: ['./page-notifications.component.scss']
})
export class PageNotificationsComponent implements OnInit, OnDestroy {

  private notificationSubscription: Subscription;
  private eventSubscription: Subscription;
  public eventId: number;

  public notifications: Array<Notification>;

  constructor(private notificationService: NotificationService,
              private router: ActivatedRoute,
              private eventService: EventSelectorService,
              private toasterService: ToasterService) {
    this.notifications = new Array<Notification>();
  }

  ngOnInit() {

    this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId) => {
      this.eventId = eventId;
      if (this.notificationSubscription !== null && this.notificationSubscription !== undefined) {
        this.notificationSubscription.unsubscribe();
      }
      this.notificationSubscription = this.notificationService.subscribeToEvent(this.eventId)
                                                              .subscribe((notifications) => {
                                                                this.notifications = notifications;
                                                              });
    });
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

  getDateFromTimestamp(not: Notification) {
    return new Date(not.timestamp);
  }

  getAllNotificationsWithoutRemember() {
    return this.notifications.filter((notification: Notification) => {
      return notification.remember !== true && notification.finished === false;
    });
  }
  getAllRememberNotifications() {
    return this.notifications.filter((notification: Notification) => {
      return notification.remember === true && notification.finished === false;
    });
  }

  setOnRememberList(notification: Notification) {
    this.notificationService.setToRemember(notification.id).pipe(first()).subscribe((success: Notification) => {
      notification = success;
      this.toasterService.pop('success', 'Notification wurde erfolgreich auf die Merkliste gesetzt!', '');
    });
  }

  markNotificationAsFinished(notification: Notification) {
    console.log(notification);
    this.notificationService.setAsFinsihed(notification.id).pipe(first()).subscribe((success: Notification) => {
      notification = success;
      this.toasterService.pop('success', 'Notification wurde erfolgreich auf die Merkliste gesetzt!', '');
    });

  }
}
