import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SponsorListService } from './sponsor-list.service';
import { Sponsor} from '../classes/Events/Sponsor';
import { first } from 'rxjs/operators';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

declare var Swal;

@Component({
  selector: 'app-page-lauf-sponsor-list',
  templateUrl: './page-lauf-sponsor-list.component.html',
  styleUrls: ['./page-lauf-sponsor-list.component.scss']
})
export class PageLaufSponsorListComponent implements OnInit {
  public loading:boolean;
  public sponsors:Sponsor[];
  public filteredOrderedListRequest:FilteredOrderedListRequest;
  public searchString:string;

  constructor(private sponsorListService : SponsorListService,
              private router: Router){
                this.filteredOrderedListRequest = new FilteredOrderedListRequest();
              }

  ngOnInit(){
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
    this.loadSponsors();
  }

  public loadSponsors(filteredOrderedListRequest?:FilteredOrderedListRequest){
    this.loading = true;
    var reqFilterParam = filteredOrderedListRequest != null ? filteredOrderedListRequest : this.filteredOrderedListRequest;

    this.sponsorListService.list(reqFilterParam).pipe(first()).subscribe(success => {
      this.sponsors = success;
      this.loading = false;
    }, error => {});
  }

  public edit(sponsor: Sponsor) {
    this.router.navigate(['laufverwaltung/sponsors/edit/' + sponsor.id]);
  }

  onSearchStrChange() {
    this.filteredOrderedListRequest.searchstring = this.searchString;
    if (this.filteredOrderedListRequest.searchstring === undefined) {
      this.filteredOrderedListRequest.searchstring = '';
    }
    this.loadSponsors();
  }

  public deleteSponsor(sponsorId:number){
    Swal.fire({
              title: 'Sind Sie sicher',
              text: "Möchten Sie den sponsorn wirklich löschen?",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#D32F2F',
              cancelButtonColor: '#bdbdbd',
              confirmButtonText: 'Löschen',
              cancelButtonText: 'Abbrechen'
              }).then((result) => {
                this.sponsorListService.delete(sponsorId).pipe(first()).subscribe(success => {
                  this.sponsors = new Array();
                  this.loadSponsors();
                }, error => {});
              });
  }
}
