export class LaufmanagementDisciplineoverviewTranslation {
    translation = {
        de: {
            'STARTNUMMER' : 'Startnummer',
            'RUN_TROUGH' : 'Durchlauf',
            'GIVE_UP' : 'Aufgeben',
            'STARTTIME' : 'Startzeit',
            'SLOWSPEED' : 'Langsamste Geschwindigkeit',
            'FASTSPEED' : 'Schnellste Geschwindigkeit',
            'SLOWHEIGHTSPEED' : 'Langsamste Steigungs Geschwindigkeit',
            'FASTHEIGHTSPEED' : 'Schnellste Steigungs Geschwindigkeit',
            'SLOWDOWNFAKTOR' : 'Verlangsamungsfactor',
            'EXHAUSTIONFAKTOR' : 'Ermüdungsfaktor',
            'TRACKLENGTH' : 'Wegstrecke',
            'TOTALALTITUDE' : 'Gesamte Höhenmeter',
            'STARTNUMBERBEGIN' : 'Startnummern Start',
            'STARTNUMBEREND' : 'Startnummern Ende',
            'GPXTRACK' : 'GPX Strecke',
            'TRACKCOLOR' : 'Streckenfarbe',
            'DISZIPLIN_STAMMDATEN' : 'Stammdaten Disziplin',
            'STARTTIME_ALERT' : 'Bitte geben Sie eine Startzeitpunkt an',
            'ENDTIME_ALERT' : 'Bitte geben Sie eine Endzeitpunkt an',
            'SLOWSPEED_ALERT' : 'Bitte geben Sie die Langsamste Geschwindigkeit an',
            'FASTSPEED_ALERT' : 'Bitte geben Sie die Schnellste Geschwindigkeit an',
            'SLOWHEIGHTSPEED_ALERT' : 'Bitte geben Sie die Langsamste Steigungs Geschwindigkeit an',
            'FASTHEIGHTSPEED_ALERT' : 'Bitte geben Sie die Schnellste Steigungs Geschwindigkeit an',
            'SLOWDOWNFAKTOR_ALERT' : 'Bitte geben Sie den Verlangsamungsfaktor an',
            'EXHAUSTIONFAKTOR_ALERT' : 'Bitte geben Sie den Ermüdungsfaktor an',
            'TRACKLENGTH_ALERT' : 'Bitte geben Sie die Streckenlänge an',
            'TOTALALTITUDE_ALERT' : 'Bitte geben Sie die gesamten Höhenmeter an',
            'STARTNUMBERBEGIN_ALERT' : 'Bitte geben Sie die erste Startnummer an',
            'STARTNUMBEREND_ALERT' : 'Bitte geben Sie die letzte Startnummer an',
            'TRACKCOLOR_ALERT' : 'Bitte geben Sie eine Streckenfarbe an',
        }
      };
}
