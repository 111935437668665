
export enum FilterType {
	EQUALS,
	IN,
	LIKE,
	BETWEEN,
	GREATER,
	LOWER
}

export class FieldFilter{
  public filterType:FilterType;
	public filtervalues:string[];
}
