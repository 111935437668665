import { Router } from '@angular/router';
import { Athlete } from './../classes/Athlete/Athlete';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../global-services/authentication.service';
import { AuthenticationFactoryService } from '../global-services/authentication-factory.service';

import { NgSelectizeModule} from 'ng-selectize';
import { Discipline } from '../classes/Disciplines/Discipline';
import { LaufAthleteEditService } from './lauf-athlete-edit.service';
import { Subscription, Subscribable } from 'rxjs';

@Component({
  selector: 'app-page-lauf-athlete-edit',
  templateUrl: './page-lauf-athlete-edit.component.html',
  styleUrls: ['./page-lauf-athlete-edit.component.scss'],
  providers: [
    LaufAthleteEditService
  ]
})
export class PageLaufAthleteEditComponent implements OnInit {
  public nations: Array<any>;
  public disciplines: Array<Discipline>;
  public teams: Array<any>;

  private sub: any;

  public id: number;
  public editUser: any;
  private user: any;

  public mandantList: any[];

  public deviceList: any[];
  public locationList: any[];
  public indicatorList: any[];

  public triedToSubmit = false;

  public activeUserIsAdmin = false;

  public draggables = {
    effectAllowed: 'move',
    disable: false,
    handle: false,
  };

  public nation_selectize_config = {
    labelField: 'selectizeLabel',
    valueField: 'alpha-3',
    searchField: ['name', 'region', 'alpha-2', 'alpha-3', 'sub-region'],
    render: {
      option: (item, escape) => {
        return '<div class="option">' +
          '<span style=" margin-right:1em; height:3em; width:3em; line-height:3em;" class="flag-icon flag-icon-' + item['alpha-2'].toLowerCase() + '"></span>' +
          '<span>' + escape(item.name) + '</span>' +
          '</div>';
      }, item: (item, escape) => {
        return '<div class="item">' +
          '<span style=" margin-right:1em; height:3em; width:3em; line-height:3em;" class="flag-icon flag-icon-' + item['alpha-2'].toLowerCase() + '"></span>' +
          escape(item.name) +
          '</div>';
      }
    }
  };

  public discipline_selectize_config = {
    labelField: 'name',
    valueField: 'id',
    searchField: ['name']
  };

  public teams_selectize_config = {
    create: true,
    labelField: 'name',
    valueField: 'name',
    searchField: ['name']
  };

  private disciplineSubscription: Subscription;
  private nationubscription: Subscription;

  constructor(private fb: FormBuilder,
    private toasterService: ToasterService,
    private authFactory: AuthenticationFactoryService,
    private athleteService: LaufAthleteEditService,
    private router: Router,
    private route: ActivatedRoute) {

      this.editUser = this.fb.group({
        'id': [0, Validators.compose([])],
        'appallowed': [true, Validators.compose([])],
        'appstatus': [1, Validators.compose([])],
        'teamname': ['', Validators.compose([])],
        'startnumber': ['', Validators.compose([Validators.required, Validators.maxLength(10)])],
        'disciplineId': ['', Validators.compose([])],
        'surname': ['', Validators.compose([Validators.maxLength(255)])],
        'lastname': ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
        'phone': ['', Validators.compose([Validators.maxLength(255)])],
        'postcode':['', Validators.compose([Validators.maxLength(5)])],
        'town':['', Validators.compose([Validators.maxLength(255)])],
        'street':['', Validators.compose([Validators.maxLength(255)])],
        'housenumber':['', Validators.compose([Validators.maxLength(255)])],
        'nation':['', Validators.compose([Validators.maxLength(255)])],
        'email':['', Validators.compose([Validators.maxLength(255)])]
      });
    }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['athleteId'];

      if (this.id !== 0) {
        this.loadAthlete(this.id);
      }
     });

     this.athleteService.listDisciplines().subscribe((disciplines: Array<Discipline>) => {
       console.log(disciplines);
      this.disciplines = disciplines;
      });

      this.athleteService.listNations().subscribe((nations: Array<any>) => {
        this.nations = nations;
      });

      this.athleteService.listTeams().subscribe((teams: Array<any>) => {
        console.log(teams);
       this.teams = teams;
       });

  }

  public loadAthlete(id: number) {
    this.athleteService.get(id).subscribe((athlete: Athlete) => {
      this.editUser.patchValue(athlete);

      this.editUser.patchValue({
        disciplineId: athlete.discipline.id
      });
    });
  }

  changeUser() {
    const value = this.editUser.value;
    const valid = this.editUser.valid;

    this.disciplines.forEach((disc) => {
      if (+value.disciplineId === disc.id) {
        value.discipline = disc;
        delete value.disciplineId;
      }
    });

    if (valid) {
      if (this.id === 0) {
        delete value.id;
        this.athleteService.add(value).subscribe((success) => {
          this.router.navigate(['laufverwaltung/athletes']);
        });
      } else {
        this.athleteService.edit(value).subscribe((success) => {
          this.router.navigate(['laufverwaltung/athletes']);
        });
      }
    } else {
      console.log(this.editUser);
      this.toasterService.pop('error', 'Bitte füllen sie alle benötigten Felder aus!', '');
    }


    this.triedToSubmit = true;
   }

  hasUserDeviceAssigned(deviceid: number) {
    let retVal = false;
    if (this.user !== undefined) {
      this.user.user_devices.forEach(user_device => {
          if (deviceid === user_device.device_id) {
            retVal = true;
          }
      });
    }

    return retVal;
  }

  hasUserAssigned(type: string, deviceid: number) {
    let retVal = false;
    let arr = new Array();

    if (this.user === undefined) {
      return false;
    }
    if (this.user.user_indicators === undefined) {
      this.user.user_indicators = [];
    }

    if (this.user !== undefined) {
      if (type === 'device') {
        arr = this.user.user_devices;
      } else if (type === 'location') {
        arr = this.user.user_locations;
      } else if (type === 'indicator') {
        arr = this.user.user_indicators;
      }

      if(arr != null){
        arr.forEach(el => {
            if (deviceid === el.device_id || deviceid === el.location_id || deviceid === el.indicators_id) {
              retVal = true;
            }
        });
      }
    }

    return retVal;
  }

  assignDeviceToUser(deviceid: number) {
    this.user.user_devices.push(
      {
        id: 0,
        user_id: this.user.id,
        device_id: deviceid
      }
    );
  }

  assignLocationToUser(locationid: number) {
    this.user.user_locations.push(
      {
        id: 0,
        user_id: this.user.id,
        location_id: locationid
      }
    );
  }

  assignIndicatorToUser(indicatorid: number) {
    if (this.user.user_indicators === undefined) {
      this.user.user_indicators = [];
    }
    this.user.user_indicators.push(
      {
        id: 0,
        user_id: this.user.id,
        indicators_id: indicatorid
      }
    );

  }

  removeDeviceFromUser(deviceid: number) {
    let device = null;

    this.user.user_devices.forEach(user_device => {
      if (user_device.device_id === deviceid) {
        device = user_device;
      }
    });

    if (device !== null) {
      const i = this.user.user_devices.indexOf(device);
      this.user.user_devices.splice(i, 1);
    }
  }

  removeIndicatorFromUser(indicatorid: number) {
    let indicator = null;

    if (this.user.user_indicators === undefined) {
      this.user.user_indicators = [];
    }

    this.user.user_indicators.forEach(user_indicator => {

      if (user_indicator.indicators_id === indicatorid) {
        indicator = user_indicator;
      }
    });

    if (indicator !== null) {
      const i = this.user.user_indicators.indexOf(indicator);
      this.user.user_indicators.splice(i, 1);
    }
  }

  removeLocationFromUser(locationid: number) {
    let location = null;

    this.user.user_locations.forEach(user_location => {
      if (user_location.location_id === locationid) {
        location = user_location;
      }
    });

    if (location !== null) {
      const i = this.user.user_locations.indexOf(location);
      this.user.user_locations.splice(i, 1);
    }
  }

  passwordChanged(obj) {
    this.editUser.password = obj;
  }

  onDrop(event: any, assign: boolean, type: string) {
    if (event.data.assigned === false && assign === true) {
      if (type === 'device') {
        this.assignDeviceToUser(event.data.device.id);
      } else if (type === 'location') {
        this.assignLocationToUser(event.data.location.id);
      } else if (type === 'indicator') {
        this.assignIndicatorToUser(event.data.indicator.id);
      }
    } else if (event.data.assigned === true && assign === false) {
      if (type === 'device') {
        this.removeDeviceFromUser(event.data.device.id);
      } else if (type === 'location') {
        this.removeLocationFromUser(event.data.location.id);
      } else if (type === 'indicator') {
        this.removeIndicatorFromUser(event.data.indicator.id);
      }
    } else {
    }

  }

  valuechange(newValue) {
    for(let disziplin of this.disciplines){
      if(disziplin['startnumberbegin']<newValue&& disziplin['startnumberend']>newValue){
        console.log(disziplin['name']);
        this.editUser.controls['disciplineId'].setValue(disziplin['id']);
      }
    }
  }
}
