import { Component, OnInit } from '@angular/core';
import { Event } from '../classes/Events/Event';
import { Discipline } from '../classes/Disciplines/Discipline';
import { Checkpoint } from '../classes/Checkpoints/Checkpoint';
import { IMapDataCollection } from '../geography/imap-data-collection';
import { LaufverwaltungService } from '../page-laufverwaltung/laufverwaltung.service';
import { DisciplineListService } from '../page-lauf-discipline-list/discipline-list.service';
import { CheckpointListService } from '../page-lauf-checkpoint-list/checkpoint-list.service';
import { FixGeojsonService } from '../geography/fix-geojson.service';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

@Component({
  selector: 'app-page-live-dashboard',
  templateUrl: './page-live-dashboard.component.html',
  styleUrls: ['./page-live-dashboard.component.scss']
})
export class PageLiveDashboardComponent implements OnInit {
  public loading: boolean;
  public loadingDisciplines: boolean;
  public loadingCheckpoints: boolean;

  public event: Event;
  public disciplines: Discipline[];
  public checkpoints: Checkpoint[];
  public mapZoom = 12;
  public mapZenter: {lng: number, lat: number} = {lng: 11.393970497172887, lat: 47.262024107782594};

  public disciplinesGeoJson: IMapDataCollection;

  constructor(private laufverwaltungService: LaufverwaltungService,
              private disciplineListService: DisciplineListService,
              private checkpointListService: CheckpointListService,
              private fixGeojsonService: FixGeojsonService) {
                this.disciplinesGeoJson = {
                  data: new Array()
                };
  }

  ngOnInit() {
      this.loadEventInfos();
      this.loadDisciplines();
  }

  private loadEventInfos() {
    this.loading = true;

    this.laufverwaltungService.get().pipe(first()).subscribe(success => {
      success.startdate = moment(success.startdate).toISOString();
      success.enddate = moment(success.enddate).toISOString();

        this.event = success;
        this.loading = false;
    }, error => {});
}

private loadDisciplines() {
    this.loadingDisciplines = true;

    this.disciplineListService.list(new FilteredOrderedListRequest()).pipe(first()).subscribe(success => {
        this.disciplines = success;

        this.disciplinesGeoJson.data = new Array();
        this.disciplinesGeoJson.data = this.fixGeojsonService.fixGeoJson(success);
        this.loadingDisciplines = false;
    }, error => {});
}

}
