import { Component, OnInit, OnDestroy } from '@angular/core';
import { DisciplineLiveinfoService } from '../socket-services/discipline-liveinfo.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LiveDisciplineService } from './live-discipline.service';

@Component({
  selector: 'app-page-live-discipline-details',
  templateUrl: './page-live-discipline-details.component.html',
  styleUrls: ['./page-live-discipline-details.component.scss'],
  providers: [
    LiveDisciplineService
  ]
})
export class PageLiveDisciplineDetailsComponent implements OnInit, OnDestroy {

  public owlOptions = {
    dots: false,
    navigation: false,
    items: 3,
    autoWidth: true
  };

  public disciplineId: number;
  public checkpoints: Array<any>;

  private routeSubscription: Subscription;
  private intervallRef: any;

  constructor(private liveDisciplineService: LiveDisciplineService,
              private titleService: Title,
              private route: ActivatedRoute) {
    this.checkpoints = new Array();
  }

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.disciplineId = +params.disciplineId;

      if (this.disciplineId !== 0) {
          this.reloadInfos();

          this.intervallRef = setInterval(() => {
              this.reloadInfos();
          }, 60000);
      }
    });
  }

  reloadInfos() {
      this.liveDisciplineService.getDisciplineDetails(this.disciplineId).subscribe((disciplineDetails: any) => {
          console.log(disciplineDetails);
          this.titleService.setTitle('Live ' + disciplineDetails.name);
      });
      this.liveDisciplineService.getLiveDisciplineInfos(this.disciplineId).subscribe((liveInfos: any) => {
          console.log(liveInfos);
          this.checkpoints = liveInfos;
      });
  }

  ngOnDestroy() {
      this.routeSubscription.unsubscribe();
      clearInterval(this.intervallRef);
      this.titleService.setTitle('Livetracking Administration');
  }

  getCheckpointsByDisciplinId() {
    return this.checkpoints.sort((a, b) => {
      if (a.sequencenumber > b.sequencenumber) {
        return 1;
      } else {
        return -1;
      }
    });
  }
}
