import { environment } from './../../environments/environment';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Event } from '../classes/Events/Event';

@Injectable({
  providedIn: 'root'
})
export class LaufverwaltungService {
  private baseUrl = environment.baseUrl;
  
  constructor(private http: HttpClient){}

  public get(){
    return this.http.get<Event>(this.baseUrl + 'events/{:eventId}');
  }
}
