import { Component, OnInit } from '@angular/core';
import { DisciplineListService } from './discipline-list.service';
import { Discipline } from '../classes/Disciplines/Discipline';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';
import { IMapDataCollection } from '../geography/imap-data-collection';
import { IMapData } from '../geography/imap-data';
import { FixGeojsonService } from '../geography/fix-geojson.service';

declare var Swal;

@Component({
  selector: 'app-page-lauf-discipline-list',
  templateUrl: './page-lauf-discipline-list.component.html',
  styleUrls: ['./page-lauf-discipline-list.component.scss'],
  providers: [DisciplineListService]
})
export class PageLaufDisciplineListComponent implements OnInit {
  public loading: boolean;
  public disciplines: Discipline[];
  public filteredOrderedListRequest: FilteredOrderedListRequest;
  public searchString: string;

  public geoJson: IMapDataCollection;

  constructor(private disciplineListService: DisciplineListService,
              private fixGeojsonService: FixGeojsonService,
              private router: Router) {
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
    this.geoJson = {
      data: new Array()
    };
  }

  ngOnInit() {
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
    this.loadDisciplines();
  }

  public loadDisciplines(filteredOrderedListRequest?: FilteredOrderedListRequest) {
    this.loading = true;
    const reqFilterParam = this.filteredOrderedListRequest;

    this.disciplineListService.list(reqFilterParam).pipe(first()).subscribe(success => {
      this.disciplines = success;

      this.geoJson.data = new Array();

      this.geoJson.data = this.fixGeojsonService.fixGeoJson(success);
      this.loading = false;
    }, error => {});
  }

  onSearchStrChange() {
    this.filteredOrderedListRequest.searchstring = this.searchString;
    if (this.filteredOrderedListRequest.searchstring === undefined) {
      this.filteredOrderedListRequest.searchstring = '';
    }
    this.loadDisciplines();
  }

  trackFn(idx: number, data: IMapData) {
    return data.id;
  }

  public edit(discipline: Discipline) {
    this.router.navigate(['laufverwaltung/disciplines/edit/' + discipline.id]);
  }

  public deleteDiscipline(disciplineId: number) {
    Swal.fire({
              title: 'Sind Sie sicher',
              text: 'Möchten Sie die Disziplin wirklich löschen?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#D32F2F',
              cancelButtonColor: '#bdbdbd',
              confirmButtonText: 'Löschen',
              cancelButtonText: 'Abbrechen'
              }).then((result) => {
                this.disciplineListService.delete(disciplineId).pipe(first()).subscribe(success => {
                  this.disciplines = new Array();
                  this.loadDisciplines();
                }, error => {});
              });
  }
}
