import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { AthleteImportService } from './athlete-import.service';
import { ToasterService } from 'angular2-toaster';
import { Discipline } from '../classes/Disciplines/Discipline';
import { EventSelectorService } from '../global-services/event-selector.service';
declare var jQuery;

@Component({
  selector: 'app-page-lauf-athlete-import',
  templateUrl: './page-lauf-athlete-import.component.html',
  styleUrls: ['./page-lauf-athlete-import.component.scss'],
  providers: [
    AthleteImportService
  ]
})
export class PageLaufAthleteImportComponent implements OnInit {
  public importFileForm: FormGroup;
  public importstarted: boolean;
  public importProcessed: boolean;

  public isLoading = false;


  public athletesToImport: any[];
  public importFoundCols: any[];
  public importInformations: any;

  public selectableDisciplines: Discipline[];

  public possibleEncodings = new Array({'name' : 'UTF8', 'filereaderkennung' : 'UTF8'},
                                      {'name' : 'ISO-8859-1', 'filereaderkennung' : 'ISO-8859-1'},
                                      {'name' : 'ANSI', 'filereaderkennung' : 'ANSI'});

  public possibleValuetypes = new Array({'field' : 'ignore', 'name' : 'Ignore'},
                                        {'field' : 'startnumber', 'name' : 'Startnummer'},
                                        {'field' : 'surname', 'name' : 'Vorname'},
                                        {'field' : 'lastname', 'name' : 'Nachname'},
                                        {'field' : 'street', 'name' : 'Straße'},
                                        {'field' : 'housenumber', 'name' : 'Hausnummer'},
                                        {'field' : 'postcode', 'name' : 'PLZ'},
                                        {'field' : 'town', 'name' : 'Ort'},
                                        {'field' : 'nation', 'name' : 'Nation'},
                                        {'field' : 'teamname', 'name' : 'Teamname'},
                                        {'field' : 'phone', 'name' : 'Telefon'},
                                        {'field' : 'email', 'name' : 'E-Mail'},
                                        {'field' : 'appallowed', 'name' : 'App_erlaubt'});

  constructor(private athleteImportService: AthleteImportService,
              private eventSelectorService: EventSelectorService,
              private router: Router,
              private toasterService: ToasterService,
              private formBuilder: FormBuilder) {

    this.importFileForm = this.formBuilder.group({
        importencoding: [this.possibleEncodings[0].filereaderkennung, Validators.compose([Validators.required])],
        headlineinfile: [true],
        importfile: [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {}

  public startImportSubmit(valid: boolean, values: any) {
    if (valid) {
      console.log('HERE');
      const reader = new FileReader();

      console.log(values.importencoding);
      reader.readAsText(values.importfile, values.importencoding);
      reader.onload = (evt) => {
        console.log(evt);
        console.log((<any>evt.target).result);
        this.athletesToImport = this.csv2Array((<any>evt.target).result);

        if (values.headlineinfile) {
          this.athletesToImport.splice(0, 1);
        }

        this.importFoundCols = new Array();

        for (let i = 0; i < this.athletesToImport[0].length; i++) {
          this.importFoundCols.push({'index' : i, 'valuetype' : this.possibleValuetypes[0].field});
        }

        this.importstarted = true;
      };
    } else {
      this.toasterService.pop('error', 'Füllen Sie das Formular zur gänze aus!', '');
      console.log(this.importFileForm);
    }
    return false;
  }

  public selectFile(event: any) {
    event.preventDefault();
    jQuery('#hidden-csv-file-input').trigger('click');
  }

  public selectedFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      this.importFileForm.patchValue({importfile: event.target.files[0]});
    }
    // this.importFileForm.controls.importfile.setValue((<any>document.getElementById('hidden-csv-file-input')).files[0].name);
  }

  public csv2Array(csv: string) {

    const allTextLines = csv.split(/\r|\n|\r/);
    const headers = allTextLines[0].split(';');
    const lines = [];

    for (let i = 0; i < allTextLines.length; i++) {
      const data = allTextLines[i].split(';');
      if (data.length === headers.length) {
        const tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j]);
        }

        lines.push(tarr);
      }
    }
    // all rows in the csv file
    return lines;
  }

  public finishImportLaeuferFromFile() {
    if (this.isLoading === true) {
      return;
    }

    const valueRelations = new Array();
    const athleteImport = {
      'athletes' : new Array(),
      'eventid' : this.eventSelectorService.getSelectedEvent()
    };

    for (const importFoundCol of this.importFoundCols) {
      valueRelations[importFoundCol.valuetype] = importFoundCol.index;
    }

    for (const athleteToImport of this.athletesToImport) {
      const newLaeufer = {
        startnumber : athleteToImport[valueRelations['startnumber']],
        teamname : athleteToImport[valueRelations['teamname']],
        surname : athleteToImport[valueRelations['surname']],
        lastname : athleteToImport[valueRelations['lastname']],
        phone : athleteToImport[valueRelations['phone']],
        email : athleteToImport[valueRelations['email']],
        nation : athleteToImport[valueRelations['nation']],
        street : athleteToImport[valueRelations['street']],
        housenumber : athleteToImport[valueRelations['housenumber']],
        postcode : athleteToImport[valueRelations['postcode']],
        town : athleteToImport[valueRelations['town']],
        appallowed : athleteToImport[valueRelations['appallowed']],
        appstatus : 1
      };

      athleteImport.athletes.push(newLaeufer);
    }

    this.isLoading = true;
    this.athleteImportService.importAthletes(athleteImport).subscribe(success => {
      this.importInformations = success;
      this.importProcessed = true;
      this.isLoading = false;

      if (this.importInformations.faultyAthletes.length < 1) {
        this.router.navigate(['laufverwaltung/athletes/']);
      } else {
        this.loadAllForFaultyAthletes();
      }
    }, error => {
      this.isLoading = false;
      this.toasterService.pop('error', 'ES_GAB_EINEN_FEHLER_BEIM_IMPORTIEREN_DER_ATHLETEN');
    });
  }

  private loadAllForFaultyAthletes() {
    this.athleteImportService.listDisciplinesByEvent().subscribe(success => {
      this.selectableDisciplines = success;
    }, error => {
      this.toasterService.pop('error', 'ES_GAB_EINEN_FEHLER_BEIM_LESEN_DER_DISZIPLINEN');
    });
  }

  public saveNotImportetLaeufer(athlete, index) {
    this.athleteImportService.saveNotImportedLaeufer(athlete).subscribe(success => {
      this.toasterService.pop('success', 'LAEUFER_ERFOLGREICH_GESPEICHERT');
      this.importInformations.faultyAthletes.splice(index, 1);
    }, error => {
      this.toasterService.pop('error', 'ES_GAB_EINEN_FEHLER_BEIM_SPEICHERN_DES_LAEUFERS');
    });
  }

  public removeNotImportetLaeufer(index) {
    this.importInformations.faultyAthletes.splice(index, 1);
  }
}
