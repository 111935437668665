import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { environment } from './../../environments/environment';
import { EventSelectorService } from './../global-services/event-selector.service';
import { LaufDisciplineEditService } from './lauf-discipline-edit.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Checkpoint } from '../classes/Checkpoints/Checkpoint';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Discipline } from '../classes/Disciplines/Discipline';
import { ToasterService } from 'angular2-toaster';
import { DB_TIME_FORMAT } from '../datepicker';

declare var toGeoJSON: any;
// @NgModule({
//   imports: [
//     ColorPickerModule
//   ]
// })

@Component({
  selector: 'app-page-lauf-discipline-edit',
  templateUrl: './page-lauf-discipline-edit.component.html',
  styleUrls: ['./page-lauf-discipline-edit.component.scss'],
  providers: [
    LaufDisciplineEditService
  ]
})
export class PageLaufDisciplineEditComponent implements OnInit, OnDestroy {
  public triedToSubmit: boolean;
  public disziplineEditForm: FormGroup;
  public eventCheckpoints: Array<Checkpoint>;
  public disciplinCheckpoints: Array<Checkpoint>;

  public mapZoom: number;
  public centerLat: number;
  public centerLon: number;

  private eventSubscription: Subscription;

  constructor(private fb: FormBuilder,
              private disciplineService: LaufDisciplineEditService,
              private route: ActivatedRoute,
              private router: Router,
              private toasterService: ToasterService,
              private eventService: EventSelectorService) {
    this.triedToSubmit = false;
    this.eventCheckpoints = new Array();

    this.disziplineEditForm = this.fb.group({
      id: [0, Validators.compose([])],
      name: ['', Validators.compose([Validators.required])],
      eventId: [0, Validators.compose([Validators.required])],
      starttime: ['', Validators.compose([Validators.required])],
      slowspeed: [12.0, Validators.compose([Validators.required])],
      fastspeed: [15.0, Validators.compose([Validators.required])],
      slowheightspeed: [800.0, Validators.compose([Validators.required])],
      fastheightspeed: [1200.0, Validators.compose([Validators.required])],
      slowdownfaktor: [10, Validators.compose([Validators.required])],
      exhaustionfaktor: [5, Validators.compose([Validators.required])],
      tracklength: [0, Validators.compose([Validators.required])],
      totalaltitude: [0, Validators.compose([Validators.required])],
      startnumberbegin: [0, Validators.compose([Validators.required])],
      startnumberend: [0, Validators.compose([Validators.required])],
      trackcolor: ['#FF5722', Validators.compose([Validators.required])],
      disciplincheckpoints: this.fb.array([]),
      gpxtrack: [null, Validators.compose([])],
      geojson: [{}, Validators.compose([])]
    });

    globalThis.disziplineEditForm = this.disziplineEditForm;

    this.disziplineEditForm.get('tracklength').disable();
    this.disziplineEditForm.get('totalaltitude').disable();

    this.mapZoom = 10;
    this.centerLat = 47.21;
    this.centerLon = 11.24;
  }

  ngOnInit() {
    this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId: number) => {
      this.disziplineEditForm.patchValue({eventId: eventId});
    });

    this.disciplineService.listCheckpoints().subscribe((checkpoints: Array<Checkpoint>) => {
      this.eventCheckpoints = checkpoints;
    });

    if (parseInt(this.route.snapshot.params['disciplineId'], 10) !== 0) {
      this.disciplineService.get(this.route.snapshot.params['disciplineId']).subscribe((discipline: Discipline) => {
        discipline.starttime = moment(discipline.starttime, DB_TIME_FORMAT.parseInput).toDate();
        this.disziplineEditForm.patchValue(discipline);
        console.log(discipline);
        this.setDataOnMap(discipline);

        discipline.disciplincheckpoints.forEach((checkPoint) => {
          const dscCheckpoint = this.createDisciplinecheckpointForm(undefined);
          dscCheckpoint.patchValue(checkPoint);
          (<FormArray>this.disziplineEditForm.get('disciplincheckpoints')).push(dscCheckpoint);
        });
      });

      // this.disciplineService.getCheckpoints(this.route.snapshot.params['disciplineId']).subscribe((checkpoints: Array<Checkpoint>) => {
      //   console.log(checkpoints);
      // });
    } else {

    }
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  getTimeFromMilliseconds(obj) {

    let milliseconds: number | string = obj % 1000;
    let seconds: number | string = ((obj - milliseconds) / 1000) % 60;
    const minutes = (obj - milliseconds - (seconds * 1000)) / 60000;

    if (seconds < 10) {
      if (seconds === 0) {
        seconds = '00';
      } else {
        seconds = '0' + seconds;
      }
    }

    if (milliseconds < 1000) {
      if (milliseconds < 100) {
        if (milliseconds < 10) {
          if (milliseconds === 0) {
            milliseconds = milliseconds + '0';
          }
          milliseconds = milliseconds + '0';
        }
        milliseconds = milliseconds + '0';
      }
      milliseconds = milliseconds + '0';
    }
    return minutes + ':' + seconds + '.' + milliseconds;
    // return obj / 60000.0;
  }

  getGeoJson() {
    return this.disziplineEditForm.value.geojson;
  }

  setDataOnMap(discipline: any) {
    this.centerLat = discipline.centerLatitude;
    this.centerLon = discipline.centerLongitude;
  }

  dropToDiszChp(event: CdkDragDrop<any[]>) {
    console.log(event);

    if (event.previousContainer === event.container) {
      console.log(event.container);
      moveItemInArray((<FormArray>(<any>event.container.data)).controls, event.previousIndex, event.currentIndex);
    } else {
      const classTags = event.item.element.nativeElement.firstElementChild.getAttribute('class').split(' ');
      const checkId = classTags[classTags.length - 1];
      for (const checkp of this.eventCheckpoints) {
        if (checkp.id === parseInt(checkId, 10)) {
          (<FormArray>this.disziplineEditForm.controls.disciplincheckpoints).insert(event.currentIndex, this.createDisciplinecheckpointForm(checkp));
          break;
        }
      }

    }

    for (let i = 0; i < (<FormArray>this.disziplineEditForm.controls.disciplincheckpoints).controls.length; i++) {
      (<FormGroup>(<FormArray>this.disziplineEditForm.controls.disciplincheckpoints).controls[i]).controls.order.setValue(i);
    }

    console.log(this.disziplineEditForm);
  }

  dropToChp(event: CdkDragDrop<any[]>) {
    console.log('DROP');
  }

  dropToChpAllowed() {
    return false;
  }

  changeDiscipline() {
    if (this.disziplineEditForm.valid === true) {
      const succSubscribtion = (discipline: Discipline) => {
        discipline.starttime = moment(discipline.starttime, DB_TIME_FORMAT.parseInput);
        this.disziplineEditForm.patchValue(discipline);
        this.setDataOnMap(discipline);
        (<FormArray>this.disziplineEditForm.get('disciplincheckpoints')).clear();
        discipline.disciplincheckpoints.forEach((checkPoint) => {
          const dscCheckpoint = this.createDisciplinecheckpointForm(undefined);
          dscCheckpoint.patchValue(checkPoint);
          (<FormArray>this.disziplineEditForm.get('disciplincheckpoints')).push(dscCheckpoint);
        });
        this.toasterService.pop('success', 'Disziplin wurde erfolgreich gespeichert');
        this.router.navigate(['laufverwaltung/disciplines']);
      };
      const value = this.disziplineEditForm.value;


      if (value.starttime.format !== undefined) {
        value.starttime = value.starttime.format('YYYYMMDDHHmmssZZ');
      }
      if (this.disziplineEditForm.value.id === 0) {
        this.disciplineService.add(this.disziplineEditForm.value).subscribe(succSubscribtion);
      } else {
        this.disciplineService.edit(this.disziplineEditForm.value).subscribe(succSubscribtion);
      }
    } else {
      console.log(this.disziplineEditForm);
      this.toasterService.pop('error', 'Form is not valid', '');
    }
  }

  print(obj: any) {
    console.log(obj);
  }

  private createDisciplinecheckpointForm(value: any) {
    return this.fb.group({
      id : [0, Validators.compose([Validators.required])],
      timeToNextCheckpoint: [30, Validators.compose([Validators.required])],
      fastestTimeToNextCheckpoint: [30, Validators.compose([Validators.required])],
      climbToNextCheckpoint: [0, Validators.compose([Validators.required])],
      fastPassageTime: [moment().format('H:mm'), Validators.compose([Validators.required])],
      slowPassageTime: [moment().format('H:mm'), Validators.compose([Validators.required])],
      order: [0, Validators.compose([Validators.required])],
      checkpoint: [value, Validators.compose([Validators.required])],
      finished: [false, Validators.compose([Validators.required])],
      averageSpeed: [0.0, Validators.compose([Validators.required])],
      disciplineAltitude: [0.0, Validators.compose([Validators.required])],
      disciplineLatitude: [0.0, Validators.compose([Validators.required])],
      disciplineLongitude: [0.0, Validators.compose([Validators.required])],
      distanceToNextCheckpoint: [0.0, Validators.compose([Validators.required])],
      declineToNextCheckpoint: [0.0, Validators.compose([Validators.required])],
      delayslow: [0.0, Validators.compose([Validators.required])],
      raceResultAlias: ['', Validators.compose([])],
      delayfast: [0.0, Validators.compose([Validators.required])],
      deleteflag: [false, Validators.compose([Validators.required])],
      collapsed: [true]
    });
  }

  public reopenCheckpointFromDisciplin(id: number) {

  }

  public removeDisciplinecheckpoint(index: number) {
    (<FormArray>this.disziplineEditForm.controls.disciplincheckpoints).at(index).get('deleteflag').setValue(true);
  }

  onFileChange(event) {
    if (event.target.files.length === 1) {
      console.log(event.target.files[0]);
      const fileReader = new FileReader();

      fileReader.onload = (e: any) => {
        const gpxXmlDom = (new DOMParser()).parseFromString(fileReader.result.toString(), 'text/xml');

        this.disziplineEditForm.patchValue({'geojson': toGeoJSON.gpx(gpxXmlDom)});
        this.setDataOnMap(this.disziplineEditForm.value);
        console.log(this.disziplineEditForm.value);
      };
      fileReader.readAsText(event.target.files[0]);
    } else {
      this.toasterService.pop('error', 'Wählen Sie eine Datei aus');
    }
  }

  getCheckpointsForm(): FormArray {
    return (this.disziplineEditForm.get('disciplincheckpoints') as FormArray);
  }
}
