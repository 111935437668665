import { first } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Discipline } from '../classes/Disciplines/Discipline';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

@Injectable({
  providedIn: 'root'
})
export class AthleteImportService {

  constructor(private http: HttpClient){}

  public importAthletes(athleteImport:any){
    return this.http.post(environment.baseUrl + 'athletes/import', athleteImport).pipe(first());
  }

  public listDisciplinesByEvent(): Observable<Discipline[]>{
    return this.http.post<Discipline[]>(environment.baseUrl + 'disciplines/list-by-event/{:eventId}', new FilteredOrderedListRequest()).pipe(first());
  }

  public saveNotImportedLaeufer(athlete:any){
    return this.http.post(environment.baseUrl + 'athletes', athlete).pipe(first());
  }
}
