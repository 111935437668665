import { environment } from './../../environments/environment';
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../classes/Users/User';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

@Injectable({
  providedIn: 'root'
})
export class UserListService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient){}

  public list(filteredOrderedListRequest:FilteredOrderedListRequest): Observable<User[]>{
    return this.http.post<User[]>(this.baseUrl + 'users/list/', filteredOrderedListRequest);
  }

  public delete(userId:number){
    return this.http.delete(this.baseUrl + 'users/' + userId);
  }
}
