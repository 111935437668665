import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserListService } from './user-list.service';
import { User} from '../classes/Users/User';
import { first } from 'rxjs/operators';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

declare var Swal;

@Component({
  selector: 'app-page-user-list',
  templateUrl: './page-user-list.component.html',
  styleUrls: ['./page-user-list.component.scss'],
  providers: [UserListService]
})
export class PageUserListComponent implements OnInit {
  public loading:boolean;
  public users:User[];
  public filteredOrderedListRequest:FilteredOrderedListRequest;


  constructor(private userListService : UserListService,
              private router: Router){}

  ngOnInit(){
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
    this.loadUsers();
  }

  public loadUsers(filteredOrderedListRequest?:FilteredOrderedListRequest){
    this.loading = true;
    var reqFilterParam = filteredOrderedListRequest != null ? filteredOrderedListRequest : this.filteredOrderedListRequest;

    this.userListService.list(reqFilterParam).pipe(first()).subscribe(success => {
      this.users = success;
      this.loading = false;
    }, error => {});
  }

  public edit(user: User) {
    this.router.navigate(['users/edit/' + user.id]);
  }

  public deleteUser(userId:number){
    Swal.fire({
              title: 'Sind Sie sicher',
              text: "Möchten Sie den User wirklich löschen?",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#D32F2F',
              cancelButtonColor: '#bdbdbd',
              confirmButtonText: 'Löschen',
              cancelButtonText: 'Abbrechen'
              }).then((result) => {
                this.userListService.delete(userId).pipe(first()).subscribe(success => {
                  this.users = new Array();
                  this.loadUsers();
                }, error => {});
              });
  }
}
