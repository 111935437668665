export class LaufverwaltungAthletesTranslation {
    translation = {
        de: {
            'EXPORT_ATHLETES' : 'Exportiere Athleten',
            'AKTIONS' : 'Aktionen',
            'SHOW_MORE' : 'Mehr zeigen',
            'LAEUFER_HINZUFUEGEN' : 'Läufer hinzufügen',
            'ADDRESS' : 'Adresse',
            'BITTE_STARTNUMMER_EINGEBEN' : 'Bitte Startnummer eingeben',
            'STARTNUMMER' : 'Startnummer',
            'BITTE_VORNAME_EINGEBEN' : 'Bitte Vorname eingeben',
            'BITTE_NACHNAME_EINGEBEN' : 'Bitte Nachname eingeben',
            'BITTE_EMAIL_EINGEBEN' : 'Bitte Email eingeben',
            'BITTE_TELEFON_EINGEBEN' : 'Bitte Telefon eingeben',
            'LAEUFER_IMPORT' : 'Läufer Import',
            'BITTE_DATEI_AUSWAEHLEN' : 'Bitte Datei auswählen',
            'ENCODING' : 'Dekodieren',
            'ERSTE_ZEILE_VERGESSEN' : 'Erste Zeile vergessen',
            'DATEI_CSV' : 'Datei CSV',
            'DATEI_AUSWAEHLEN' : 'Datei auswählen',
            'IMPORT_FERTIGSTELLEN' : 'Import fertigstellen',
            'ERFOLGREICH_IMPORTIERT' : 'Erfolgreich Importiert',
            'TELEFON' : 'Telefon',
            'AKTIONEN' : 'Aktionen',
            'TEAMNAME_ERROR' : 'Bitte einen gültigen Teamnamen eingeben'
        }
      };
}
