import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckpointEditService {
  constructor(private http: HttpClient) { }

  edit(value: any) {
    return this.http.put(environment.baseUrl + 'checkpoints', value, {}).pipe(first());
  }
  add(value: any) {
    return this.http.post(environment.baseUrl + 'checkpoints', value, {}).pipe(first());
  }
  get(id: number) {
    return this.http.get(environment.baseUrl + 'checkpoints/' + id, {}).pipe(first());
  }


}
