export class GpsdeviceverwaltungTranslation {
    translation = {
        de: {
            'BITTE_NAME_EINGEBEN' : 'Bitte Namen eingeben',
            'BITTE_IMEI_EINGEBEN' : 'Bitte IMEI eingeben',
            'BITTE_TYPE_WAEHLENE' : 'Bitte Typ wählen',
            'BEZEICHNUNG' : 'Bezeichnung',
            'IMEI' : 'IMEI',
            'DEIVCE_TYPE' : 'Geräte-Typ',
            'SAVE' : 'Speichern',
            'USER_TYPE_ALERT' : 'Bitte geben Sie einen Gerätetypen an',
            'USER_IMEI_ALERT' : 'Bitte geben Sie eine IMEI Adresse an',
            'USER_IMEI_2_ALERT' : 'Bitte geben Sie eine IMEI Adresse an',
            'USER_BIC_ALERT' : 'Bitte geben Sie einen BIC an',
            'USER_ICCID_ALERT' : 'Bitte geben Sie eine ICC ID an',
            'SIM_DATA' : 'Sim Daten',
            'GPSDEVICE_STAMMDATEN' : 'GPS Gerät Stammdaten'

        }
    };
}
