import { Injectable } from '@angular/core';
import { Checkpointmanager } from '../classes/Checkpointmanagers/Checkpointmanager';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckpointmanagerEditService {
  constructor(private http: HttpClient) {

  }

  add(value: Checkpointmanager) {
    return this.http.post(environment.baseUrl + 'checkpointmanagers', value, {}).pipe(first());
  }

  edit(value: Checkpointmanager) {
    return this.http.put(environment.baseUrl + 'checkpointmanagers', value, {}).pipe(first());
  }

  get(id: number) {
    return this.http.get(environment.baseUrl + 'checkpointmanagers/' + id, {}).pipe(first());
  }


  listCheckpoints() {
    return this.http.get(environment.baseUrl + 'checkpoints/list-by-event/{:eventId}').pipe(first());
  }
}
