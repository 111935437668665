import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LaufEditService {

  constructor(private http: HttpClient) { }

  add(body: Event) {
    return this.http.post(environment.baseUrl + 'events', body).pipe(first());
  }

  uploadLogo(formData: FormData, fileName: string) {
    return this.http.post('https://files.trailscout.at/upload-eventlogos.php', formData).pipe(first());
  }
}
