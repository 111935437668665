import { Gpsdevice } from './../classes/Gpsdevices/Gpsdevice';
import { first } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeviceEditService {

  constructor(private http: HttpClient) {
  }

  get(id: number) {
    return this.http.get(environment.baseUrl + 'gpsdevice/' + id, {}).pipe(first());
  }

  edit(value: Gpsdevice) {
    return this.http.put(environment.baseUrl + 'gpsdevice', value, {}).pipe(first());
  }
  add(value: Gpsdevice) {
    return this.http.post(environment.baseUrl + 'gpsdevice', value, {}).pipe(first());
  }

  possibleTypes() {
    return this.http.get(environment.baseUrl + 'gpsdevice/possible-types', {}).pipe(first());
  }
}
