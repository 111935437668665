import { Router } from '@angular/router';
import { Checkpoint } from './../classes/Checkpoints/Checkpoint';
import { Component, OnInit } from '@angular/core';
import { CheckpointListService } from './checkpoint-list.service';
import { first } from 'rxjs/operators';
import { Route } from '@angular/compiler/src/core';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';
import { stringify } from '@angular/compiler/src/util';
import { ToasterService } from 'angular2-toaster';

declare var Swal;

@Component({
  selector: 'app-page-lauf-checkpoint-list',
  templateUrl: './page-lauf-checkpoint-list.component.html',
  styleUrls: ['./page-lauf-checkpoint-list.component.scss'],
  providers: [CheckpointListService]
})
export class PageLaufCheckpointListComponent implements OnInit {
  public loading: boolean;
  public checkpoints: Checkpoint[];
  public filteredOrderedListRequest: FilteredOrderedListRequest;
  public searchString: string;

  constructor(private checkpointListService: CheckpointListService,
              private toasterService: ToasterService,
              private router: Router) {
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
  }

  ngOnInit() {
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
    this.loadCheckpoints();
  }

  public loadCheckpoints(filteredOrderedListRequest?: FilteredOrderedListRequest) {
    this.loading = true;
    const reqFilterParam = this.filteredOrderedListRequest;

    this.checkpointListService.list(reqFilterParam).pipe(first()).subscribe(success => {
      this.checkpoints = success;
      this.loading = false;
    }, error => {
      this.toasterService.pop('error', 'Checkpoints konnten nicht geladen werden', '');
      this.loading = false;
    });
  }

  public deleteCheckpoint(checkpointId: number) {
    Swal.fire({
              title: 'Sind Sie sicher',
              text: 'Möchten Sie den Checkpoint wirklich löschen?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#D32F2F',
              cancelButtonColor: '#bdbdbd',
              confirmButtonText: 'Löschen',
              cancelButtonText: 'Abbrechen'
              }).then((result) => {
                this.checkpointListService.delete(checkpointId).pipe(first()).subscribe(success => {
                  this.checkpoints = new Array();
                  this.loadCheckpoints();
                }, error => {});
              });
  }

  public editCheckpoint(checkpoint: Checkpoint) {
    this.router.navigate(['laufverwaltung/checkpoints/edit/' + checkpoint.id]);
  }

  onSearchStrChange() {

  }
}
