import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../global-services/base-service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HeaderService extends BaseService {
  private serviceUrl = 'users';

  constructor(protected http: HttpClient) {
    super();
  }

  public listNotifications() {
    return this.http.get(this.baseUrl + this.serviceUrl + '/notifications.json').pipe(map((res) => res));
  }

}
