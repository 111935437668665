import { Component, OnInit, OnDestroy } from '@angular/core';
import { SmsService } from '../socket-services/sms.service';
import { Subscription } from 'rxjs';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute } from '@angular/router';
import { Smsprotocolentry } from '../classes/Sms/Smsprotocolentry';
import { EventSelectorService } from '../global-services/event-selector.service';

@Component({
  selector: 'app-page-sms-protocol',
  templateUrl: './page-sms-protocol.component.html',
  styleUrls: ['./page-sms-protocol.component.scss']
})
export class PageSmsProtocolComponent implements OnInit, OnDestroy {

  public eventId: number;

  private smss: Array<Smsprotocolentry>;

  private eventSubscription: Subscription;
  private smsServiceSubscription: Subscription;

  constructor(private smsService: SmsService,
              private router: ActivatedRoute,
              private eventService: EventSelectorService,
              private toasterService: ToasterService) {
    this.smss = new Array();
  }

  ngOnInit() {

    this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId: number) => {
      this.eventId = eventId;
      if (this.smsServiceSubscription !== null && this.smsServiceSubscription !== undefined) {
        this.smsServiceSubscription.unsubscribe();
      }

      this.smsServiceSubscription = this.smsService.subscribeToEvent(this.eventId).subscribe((smss: Array<Smsprotocolentry>) => {
        this.smss = smss;
      });



    });
  }

  getAllUnprocessedSms() {
    return this.smss.filter((sms: Smsprotocolentry) => {
      return sms.processed === false;
    });
  }

  markSmsAsProcessed(sms: Smsprotocolentry) {
    this.smsService.setAsProcessed(sms.id).subscribe((success) => {
      this.toasterService.pop('success', 'Sms wurde erfolgreich als bearbeitet markiert!', '');
    });
  }

  ngOnDestroy() {
    this.smsServiceSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }
}
