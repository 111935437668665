import { Contact } from './../classes/Events/Contact';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { EventSelectorService } from '../global-services/event-selector.service';
import { Subscription } from 'rxjs';
import { LaufContactEditService } from './lauf-contact-edit.service';

@Component({
  selector: 'app-page-lauf-contact-edit',
  templateUrl: './page-lauf-contact-edit.component.html',
  styleUrls: ['./page-lauf-contact-edit.component.scss'],
  providers: [
    LaufContactEditService
  ]
})
export class PageLaufContactEditComponent implements OnInit, OnDestroy {

  public id: number;

  public contactForm: FormGroup;

  public triedToSubmit: boolean;

  private eventSubscription: Subscription;
  private routeSubscription: Subscription;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private eventService: EventSelectorService,
              private contactService: LaufContactEditService,
              private toasterService: ToasterService) {
    this.triedToSubmit = false;

    this.contactForm = this.fb.group({
      'id' : [0, Validators.compose([])],
      'eventId': [0, Validators.compose([Validators.required])],
      'title' : ['', Validators.compose([Validators.maxLength(255)])],
      'surname' : ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      'lastname' : ['', Validators.compose([Validators.required, Validators.maxLength(255)])],
      'phone' : ['', Validators.compose([Validators.required, Validators.maxLength(45)])],
      'sms' : ['', Validators.compose([Validators.required, Validators.maxLength(45)])],
      'email' : ['', Validators.compose([Validators.email])],
      'postcode' : ['', Validators.compose([])],
      'town' : ['', Validators.compose([])],
      'street' : ['', Validators.compose([])],
      'housenumber' : ['', Validators.compose([])],
      'forCheckpointmanagers' : [true, Validators.compose([Validators.required])],
      'forAthletes' : [false, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId: number) => {
      this.contactForm.patchValue({eventId: eventId});
    });

    this.routeSubscription = this.route.params.subscribe((params) => {
      this.id = +params.contactsId;
      if (this.id !== 0) {
        console.log(this.id);
        this.loadContact(this.id);
      }
    });
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  public loadContact(id: number) {
    this.contactService.get(id).subscribe((contact: Contact) => {
      this.contactForm.patchValue(contact);
    });
  }

  public changeContact() {
    const valid = this.contactForm.valid;
    const value = this.contactForm.value;

    if (valid) {
      if (value.id === 0) {
        this.contactService.add(value).subscribe((success) => {
          this.router.navigate(['laufverwaltung/contacts']);
        });
      } else {
        this.contactService.edit(value).subscribe((success) => {
          this.router.navigate(['laufverwaltung/contacts']);
        });
      }
    } else {
      this.toasterService.pop('error', 'Bitte füllen Sie alle benötigten Felder aus!', '');
    }

    this.triedToSubmit = true;
  }

}
