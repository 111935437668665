export class LaufverwaltungTranslation {
    translation = {
        de: {
            'LAUFVERWALTUNG' : 'Laufverwaltung',
            'LAUFVERWALTUNG_BESCHREIBUNG' : 'Beschreibung der Laufverwaltung',
            'UEBERSICHT' : 'Übersicht',
            'DISZIPLINEN' : 'Diziplinen',
            'CHECKPOINTS' : 'Checkpoints',
            'CHECKPOINTMANAGERS' : 'Checkpoint-Manager',
            'LAEUFER' : 'Läufer',
            'CONTACTS' : 'Kontakt',
            'SPONSORS' : 'Sponsoren',
            'IMPORT_LAEUFER' : 'Import der Läufer',
            'LAUFVERWALTUNG - UEBERSICHT' : 'Laufverwaltungsübersicht',
            'LAUFVERWALTUNG_UEBERSICHT_BESCHREIBUNG' : 'Beschreibung der Laufverwaltungsübersicht',
            'VERANSTALTUNG_HINZUFUEGEN' : 'Veranstaltung hinzufügen',
            'BITTE_NAME_EINGEBEN' : 'Bitte Name eingeben',
            'NAME' : 'Name',
            'BITTE_WEBSITE_EINGEBEN' : 'Bitte Website eingeben',
            'WEBSITE' : 'Website',
            'SAVE' : 'Speichern',
            'STARTDATE' : 'Startdatum',
            'ENDDATE' : 'Enddatum',
            'EVENT_STAMMDATEN' : 'Event-Stammdaten',
            'BITTE_BEZEICHNUNG_EINGEBEN' : 'Bitte Bezeichnung eingeben',
            'BEZEICHNUNG' : 'Bezeichnung',
            'STARTTIME' : 'Startzeit',
            'ENDTIME' : 'Endzeit',
            'WEBSMS_PHONENUMBER' : 'WEB-SMS-Telefonnummer',
            'TEILNEHMER_URL' : 'Teilnehmer-URL',
            'STRECKENLAENGE' : 'Streckenlänge',
            'HOEHENMETER' : 'Höhenmeter',
            'STARTNUMMERNBEGIN' : 'Startnummernanfang',
            'STARTNUMMERNENDE' : 'Startnummernende',
            'CHECKPOINTS_AN_DER_STRECKE' : 'Checkpoints an der Strecke',
            'VERPFLEGUNG' : 'Verpflegung',
            'WC' : 'WC',
            'TOILETTE' : 'Toilette',
            'AKTIONS' : 'Aktion',
            'JA'	:	'Ja',
            'NEIN'	: 'Nein',
            'STARTDATUM' : 'Startdatum',
            'ENDDATUM' : 'Enddatum',
            'DISZIPLIN_HINZUFUEGEN' : 'Diziplin hinzufügen',
            'CHECKPOINT_HINZUFUEGEN' : 'Checkpoint hinzufügen',
            'PHONE' : 'Telefon',
            'TO_THE_MAP' : 'Zur Onlinekarte',
            'EDIT' : 'Bearbeiten',
            'GPS_DEVICE_ALLOCATION': 'GPS Tracker zuweisen',
            'ZUWEISEN': 'Zuweisen',
            'ZUWEISUNGEN_LOESCHEN': 'Alle Entfernen',
            'DEVICE' : 'Gerät',
            'ATHLETE'  : 'Athlet',
            'URL_ALERT' : 'BItte geben Sie eine Url an'
        }
    };
}
