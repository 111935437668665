import { Injectable, OnDestroy } from '@angular/core';
import { BaseService } from '../global-services/base-service';
import { Subject, Observable, Observer, BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Notification } from '../classes/Notifications/Notification';
import { isArray } from 'util';
import { Smsprotocolentry } from '../classes/Sms/Smsprotocolentry';

export class SmsService extends BaseService implements OnDestroy {
  private socket: Array<BehaviorSubject<Array<Smsprotocolentry>>>;

  private smsSubscription: Subscription;
  private smss: Array<Smsprotocolentry>;

  constructor(private http: HttpClient) {
    super();

    this.socket = new Array();
    this.smss = new Array();
  }

  subscribeToEvent(eventId: number) {
    return this.connect(eventId);
  }

  ngOnDestroy() {

  }

  public connect(eventId: number): BehaviorSubject<Array<Smsprotocolentry>> {
    if (!this.socket[eventId]) {
      this.socket[eventId] = this.create(eventId);
    }

    return this.socket[eventId];
  }

  public create(eventId: number): BehaviorSubject<Array<Smsprotocolentry>> {
    const ws = new WebSocket(this.BASE_WS_URL + 'sms/socket/' + eventId);

    const observable = Observable.create((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);

      return ws.close.bind(ws);
    });

    const observer = {
      next: (data: any) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    };

    this.smsSubscription = (Subject.create(observer, observable) as Subject<MessageEvent>)
                                            .subscribe((data: MessageEvent) => {
      const smss = JSON.parse(data.data);
      if ( isArray(smss)) {
        smss.forEach((sms: Smsprotocolentry) => {
          this.checkMessageInArray(sms);
        });
      } else {
        this.checkMessageInArray(smss);
      }
      this.socket[eventId].next(this.smss);
    });
    return new BehaviorSubject<Array<Smsprotocolentry>>([]); // Subject.create(observer, observable);
  }

  checkMessageInArray(newSms: Smsprotocolentry) {
    let found = false;
    this.smss.forEach((oldSms: Smsprotocolentry) => {
      if (newSms.id === oldSms.id) {
        found = true;
        oldSms.processed = newSms.processed;
      }
    });
    if (found === false) {
      this.smss.push(newSms);
    }
  }

  setAsProcessed(id: number) {
    return this.http.put(this.baseUrl + 'sms/' + id, null, {});
  }

}
