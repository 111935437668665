export class LaufmanagementCheckpointmanagersTranslsation {
    translation = {
        de: {
            'BITTE_ENTRYCODE_EINGEBEN' : 'Bitte Entrycode eingeben',
            'ENTRYCODE' : 'Entrycode',
            'CHECKPOINTS' : 'Checkpoints',
            'AKTIONS' : 'Aktionen',
            'CHECKPOINTMANAGER_HINZUFUEGEN' : 'Checkpointmanager hinzufügen',
            'SURNAME' : 'Vorname',
            'LASTNAME' : 'Nachname',
            'USER_Entrycode_ALERT' : 'Bitte einen Entrycode angeben',
        }
      };
}
