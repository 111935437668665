import { Params } from '@angular/router';
import { FieldFilter } from './field-filter';
import { ListSorter } from './list-sorter';

export class FilteredOrderedListRequest{
  public searchstring:string;
	public maximumresults:number;
	public filters:FieldFilter[];
	public sorters:ListSorter[];

  //Internal Stuff
  //private _urlParameterFields:string[] = ['limit', 'sort', 'direction', 'search', 'filter' , 'filterValues','finder'];

  constructor(){
    this.filters = new Array();
  	this.sorters = new Array();
  	this.maximumresults = 80;
    this.searchstring = '';
  }

  public changeFieldSorter(datafield:string){
    if(this.sorterForFieldExists(datafield)){
      var sorter = this.getSorterForField(datafield);

      if(!sorter.direction){
        this.removeSorterForField(datafield);
      }else{
        sorter.direction = false;
      }
    }else{
      this.sorters.push(new ListSorter(datafield, true));
    }
  }

  public sorterForFieldExists(datafield:string){
    return this.getSorterForField(datafield) != null;
  }

  public getSorterForField(datafield:string):ListSorter{
    for(let sorter of this.sorters){
      if(sorter.datafield == datafield){
        return sorter;
      }
    }

    return null;
  }

  private removeSorterForField(datafield:string){
    for(var i = 0; i < this.sorters.length; i++){
      if(this.sorters[i].datafield == datafield){
        this.sorters.splice(i, 1);
      }
    }
  }

  public setFromUrlParams(queryParams:Params){
    // for(let parameter of this._urlParameterFields){
    //   if(this.checkParameterValue(queryParams[parameter])){
    //     this[parameter] = queryParams[parameter];
    //   }
    // }
  }

  public removeFilters(){

  }

  public processServerResponse(serverPagination:any){

  }

  public getNavigationParameter():any{
    var result = {};
    // for(let parameter of this._urlParameterFields){
    //
    //   if(this.checkParameterValue(this[parameter])){
    //     result[parameter] = this[parameter];
    //   }
    // }
    //
    return result;
  }

  public getAsUrlParamters():string{
    var firstFlag = true;
    var paginationUrlParameters = "";

    // for(let parameter of this._urlParameterFields){
    //   if(this.checkParameterValue(this[parameter])){
    //     if(firstFlag){
    //       paginationUrlParameters += "?";
    //       firstFlag = false;
    //     }else{
    //       paginationUrlParameters += "&";
    //     }
    //
    //     if(parameter == 'search'){
    //       console.log("Search writing");
    //     }
    //
    //     paginationUrlParameters += parameter + "=" + this[parameter];
    //   }
    // }
    //
    // console.log(paginationUrlParameters);
    //
    return encodeURI(paginationUrlParameters);
  }

  public checkParameterValue(value:any):boolean{
    let re = (value != null) && (value != '');

    return re;
  }
}
