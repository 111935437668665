import { environment } from './../../environments/environment';

declare var Settings;

export class BaseService {
  protected baseUrl = environment.baseUrl;
  protected BASE_WS_URL = environment.baseWsUrl;

  constructor(){}
}
