import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { TableSortFilterPaginate } from '../table-sort-filter-paginate';

@Component({
  selector: '[app-table-select-row]',
  templateUrl: './table-select-row.component.html',
  styleUrls: ['./table-select-row.component.scss']
})
export class TableSelectRowComponent implements OnInit {
  @Input() selectionModel : TableSortFilterPaginate;
  @Input() selectable : number;

  constructor() { }

  ngOnInit(){}

  toggleSelection(){
    if(this.isSelected()){
      this.selectionModel.selected.splice(this.selectionModel.selected.indexOf(this.selectable), 1);
    }else{
      this.selectionModel.selected.push(this.selectable);
    }
  }

  isSelected(){
    return this.selectionModel.selected.indexOf(this.selectable) < 0 ? false : true;
  }
}
