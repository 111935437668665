import { Component, OnInit } from '@angular/core';
import { Event } from '../classes/Events/Event';
import { LaufverwaltungService } from './laufverwaltung.service';
import { first } from 'rxjs/operators';
import { Discipline } from '../classes/Disciplines/Discipline';
import { Checkpoint } from '../classes/Checkpoints/Checkpoint';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';
import { DisciplineListService } from '../page-lauf-discipline-list/discipline-list.service';
import { FixGeojsonService } from '../geography/fix-geojson.service';
import { IMapDataCollection } from '../geography/imap-data-collection';
import { IMapData } from '../geography/imap-data';
import { CheckpointListService } from '../page-lauf-checkpoint-list/checkpoint-list.service';
import * as moment from 'moment';

@Component({
  selector: 'app-page-laufverwaltung',
  templateUrl: './page-laufverwaltung.component.html',
  styleUrls: ['./page-laufverwaltung.component.scss'],
  providers: [LaufverwaltungService]
})
export class PageLaufverwaltungComponent implements OnInit {
  public loading: boolean;
  public loadingDisciplines: boolean;
  public loadingCheckpoints: boolean;

  public event: Event;
  public disciplines: Discipline[];
  public checkpoints: Checkpoint[];
  public checkpointmanagers: any[];
  public athletes: any[];
  public mapZoom = 12;
  public mapZenter: {lng: number, lat: number} = {lng: 11.393970497172887, lat: 47.262024107782594};

  public disciplinesGeoJson: IMapDataCollection;

  constructor(private laufverwaltungService: LaufverwaltungService,
              private disciplineListService: DisciplineListService,
              private checkpointListService: CheckpointListService,
              private fixGeojsonService: FixGeojsonService){

                this.disciplinesGeoJson = {
                  data: new Array()
                };
  }

  ngOnInit(){
    this.loadEventInfos();
    this.loadDisciplines();
    this.loadCheckpoints();
  }

  private loadEventInfos(){
      this.loading = true;

      this.laufverwaltungService.get().pipe(first()).subscribe(success => {
        success.startdate = moment(success.startdate).toISOString();
        success.enddate = moment(success.enddate).toISOString();

          this.event = success;
          this.loading = false;
      }, error => {});
  }

  private loadDisciplines() {
      this.loadingDisciplines = true;

      this.disciplineListService.list(new FilteredOrderedListRequest()).pipe(first()).subscribe(success => {
          this.disciplines = success;

          this.disciplinesGeoJson.data = new Array();
          this.disciplinesGeoJson.data = this.fixGeojsonService.fixGeoJson(success);
          this.loadingDisciplines = false;
      }, error => {});
  }

  public loadCheckpoints() {
      this.loadingCheckpoints = true;

      this.checkpointListService.list(new FilteredOrderedListRequest()).pipe(first()).subscribe(success => {
        this.checkpoints = success;
        this.loadingCheckpoints = false;
      }, error => {
        this.loadingCheckpoints = false;
      });
  }

  trackFn(idx: number, data: IMapData) {
    return data.id;
  }

}
