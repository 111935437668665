export class ListSorter {

  public datafield:string;
	public direction:boolean;

  constructor(datafield:string, direction:boolean){
    this.datafield = datafield;
    this.direction = direction;
  }

}
