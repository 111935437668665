import { Component, OnInit } from '@angular/core';
import { GpsdeviceListService } from './gpsdevice-list.service';
import { Gpsdevice } from '../classes/Gpsdevices/Gpsdevice';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '../classes/Users/User';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';

declare var Swal;

@Component({
  selector: 'app-page-gps-device-list',
  templateUrl: './page-gps-device-list.component.html',
  styleUrls: ['./page-gps-device-list.component.scss'],
  providers: [GpsdeviceListService]
})
export class PageGpsDeviceListComponent implements OnInit {
  public loading:boolean;
  public gpsdevices:Gpsdevice[];
  public filteredOrderedListRequest:FilteredOrderedListRequest;
  public searchString:string;

  constructor(private gpsdeviceListService : GpsdeviceListService,
              private router: Router){
                this.filteredOrderedListRequest = new FilteredOrderedListRequest();
              }

  ngOnInit(){
    this.filteredOrderedListRequest = new FilteredOrderedListRequest();
    this.loadGpsdevices();
  }

  public loadGpsdevices(filteredOrderedListRequest?:FilteredOrderedListRequest){
    this.loading = true;
    var reqFilterParam = this.filteredOrderedListRequest;

    this.gpsdeviceListService.list(reqFilterParam).pipe(first()).subscribe(success => {
      this.gpsdevices = success;
      this.loading = false;
    }, error => {});
  }

  public edit(device: Gpsdevice) {
    this.router.navigate(['gpsdevices/edit/' + device.id]);
  }

  onSearchStrChange() {
    this.filteredOrderedListRequest.searchstring = this.searchString;
    if (this.filteredOrderedListRequest.searchstring === undefined) {
      this.filteredOrderedListRequest.searchstring = '';
    }
    this.loadGpsdevices();
  }

  public deleteGpsdevice(gpsdeviceId:number){
    Swal.fire({
              title: 'Sind Sie sicher',
              text: "Möchten Sie den gpsdevicen wirklich löschen?",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#D32F2F',
              cancelButtonColor: '#bdbdbd',
              confirmButtonText: 'Löschen',
              cancelButtonText: 'Abbrechen'
              }).then((result) => {
                this.gpsdeviceListService.delete(gpsdeviceId).pipe(first()).subscribe(success => {
                  this.gpsdevices = new Array();
                  this.loadGpsdevices();
                }, error => {});
              });
  }
}
