import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TableSortFilterPaginate } from '../table-sort-filter-paginate';

@Component({
  selector: '[app-table-pagination]',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit {
  @Input() paginationData:TableSortFilterPaginate;

  public selectedValue: number;
  private sub: any;

  constructor(private route: ActivatedRoute,
              private router: Router){

  }

  ngOnInit(){
    console.log(this.paginationData);
  }

  navigateToFirstPage(){
    this.navigateToPage(1);
  }

  navigateToPrevPage(){
    if(this.paginationData.page > 1){
      this.navigateToPage(this.paginationData.page - 1);
    }
  }

  navigateToPage(page:number){
    // console.log(page)
    this.paginationData.page = page;
    this.router.navigate([this.route.snapshot.url[0].path, this.paginationData.getNavigationParameter()]);
  }

  navigateToNextPage(){
    // console.log('next Page');
    //console.log(this.paginationData.pageCount);
    //console.log(this.paginationData.page);
    // console.log(typeof this.paginationData.page );
    // console.log(this.paginationData.page + 1);
    if(this.paginationData.page < this.paginationData.pageCount){
      this.navigateToPage(Number(this.paginationData.page) + 1);
      // console.log("=====");
      // console.log(this.paginationData);
      // console.log(this.paginationData.page);
      // console.log(this.paginationData);
    }
    else if(this.paginationData.page==undefined&&this.paginationData.pageCount>1){
        this.paginationData.page= 1;
          this.navigateToPage(this.paginationData.page + 1);
    }
    console.log("nope");
  }

  navigateToLastPage(){
    this.navigateToPage(this.paginationData.pageCount);
  }

  ngOnChanges(changes: any){
    if(this.paginationData.page!=undefined){
      this.selectedValue=this.paginationData.page;
    }else{
      this.selectedValue=1;
    }
    // console.log("Set Pages");
    // console.log(changes.paginationData.currentValue);
    // console.log(changes.paginationData.currentValue.pageCount);
    // this.pages = new Array();
    // this.paginationData = changes.paginationData.currentValue;
    // console.log("============");
    // console.log(this.paginationData);

    // console.log(this.paginationData.getPageCount());
    // console.log(this.paginationData);
    // console.log(this.paginationData.selected);
    // console.log("============");
    // if(this.paginationData != null){
    //
    //   for(let i = 1; i < changes.paginationData.pageCount; i++){
    //     console.log("jf");
    //     console.log(i);
    //     this.pages.push(i);
    //   }
    //       console.log(this.pages);
    // }
    // else
    // {
    //     console.log("PAginationData is NULL");
    // }
  //  console.log(this.pages);
  }

  changePage(){
      this.navigateToPage(this.selectedValue);
    console.log("a");
  }

}
