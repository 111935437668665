import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveDisciplinecheckpointService {

  constructor(private http: HttpClient) {}

  setCheckpointpassToAthleteState(checkpointpassId: number, newAthleteState: boolean) {
      var data = {
        chekpointpassId: checkpointpassId,
        newStatus: newAthleteState
      };

      return this.http.put(environment.baseUrl + 'checkpointpasses', data).pipe(first());
  }

  deleteCheckpointpass(id: number) {
      return this.http.delete(environment.baseUrl + 'checkpointpasses/' + id).pipe(first());
  }

  finishDisciplineCheckpoint(id: number) {
      return this.http.put(environment.baseUrl + 'disciplines/finish-disciplinecheckpoint/' + id, {}).pipe(first());
  }
}
