import { Component, OnInit } from '@angular/core';
import { Checkpoint } from '../classes/Checkpoints/Checkpoint';
import { CheckpointmanagerEditService } from '../page-lauf-checkpointmanager-edit/checkpointmanager-edit.service';
import { ToasterService } from 'angular2-toaster';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DB_TIME_FORMAT } from '../datepicker';
import * as moment from 'moment';
import { LiveAthleteRecordingService } from './live-athlete-recording.service';

@Component({
  selector: 'app-page-live-athlete-recording',
  templateUrl: './page-live-athlete-recording.component.html',
  styleUrls: ['./page-live-athlete-recording.component.scss'],
  providers: [
    LiveAthleteRecordingService
  ]
})
export class PageLiveAthleteRecordingComponent implements OnInit {


  public checkpoints_selectize_config = {
    labelField: 'name',
    valueField: 'id',
    searchField: ['name']
  };

  public checkpoints: Checkpoint[];

  public recordingForm: FormGroup;
  public triedToSubmit: boolean;

  constructor(private checkpointManagerService: CheckpointmanagerEditService,
              private toasterService: ToasterService,
              private fb: FormBuilder,
              private liveAthleteRecordingService: LiveAthleteRecordingService) {
    this.recordingForm = this.fb.group({
      athleteStatus: [false, Validators.compose([])],
      enteredRetrospectively: [false, Validators.compose([])],
      startnumber: ['', Validators.compose([Validators.required])],
      checkpointId: [null, Validators.compose([Validators.required])],
      passingtime: [null, Validators.compose([Validators.required])]
    });

    this.triedToSubmit = false;
  }

  ngOnInit() {
    this.checkpointManagerService.listCheckpoints().subscribe((checkpoints: Checkpoint[]) => {
      this.checkpoints = checkpoints;
      console.log(checkpoints);
    }, (error) => {
      this.toasterService.pop('error', 'Fehler beim Laden der Checkpoints', ' ');
      console.log('Error ', error);
    });
  }

  runThrough() {
    this.recordingForm.patchValue({
        passingtime: moment().format(DB_TIME_FORMAT.parseInput),
        athleteStatus: true,
        enteredRetrospectively: true
      });

    this.sendCheckpointPass();
    console.log('Run Through');
  }

  sendCheckpointPass() {
    this.triedToSubmit = true;
    if (this.recordingForm.valid === true) {
      const value = this.recordingForm.value;
      value.checkpointId = parseInt(value.checkpointId, 10);
      this.liveAthleteRecordingService.sendCheckpointPass(this.recordingForm.value).subscribe((success) => {
        this.toasterService.pop('success', 'Checkpointpass wurde erfolgreich hinzugefügt', '');
        this.recordingForm.patchValue({
          checkpointId: null,
          startnumber: null,
          passingtime: null
        });

        this.triedToSubmit = false;
      }, (error) => {
        this.toasterService.pop('error');
      });

    } else {
      console.log(this.recordingForm);
      this.toasterService.pop('error', 'Füllen Sie Alle benötigten Felder aus', '');
    }
  }

  giveUp() {
    this.recordingForm.patchValue({
        passingtime: moment().format(DB_TIME_FORMAT.parseInput),
        athleteStatus: false,
        enteredRetrospectively: true
      });

    this.sendCheckpointPass();
    console.log('Give Up');
  }
}
