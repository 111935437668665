export class LaufverwaltungContactsTranslation {
    translation = {
        de: {
            'EDIT_CONTACT' : 'Kontakt Bearbeiten',
            'AKTIONEN' : 'Aktionen',
            'CONTACT_HINZUFUEGEN' : 'Kontakt Hinzufügen',
            'BITTE_TITLE_EINGEBEN' : 'Bitte Titel eingeben',
            'TITLE' : 'Titel',
            'BITTE_VORNAME_EINGEBEN' : 'Bitte Vorname eingeben',
            'BITTE_NACHNAME_EINGEBEN' : 'Bitte Nachname eingeben',
            'SURNAME' : 'Vorname',
            'LASTNAME' : 'Nachname',
            'BITTE_PHONE_EINGEBEN' : 'Bitte Handy eingeben',
            'BITTE_SMS_EINGEBEN' : 'Bitte SMS eingeben',
            'PHONE' : 'Telefon',
            'SMS' : 'Sms',
            'BITTE_EMAIL_EINGEBEN' : 'Bitte EMAIL eingeben',
            'POSTCODE' : 'Postcode',
            'STREET' : 'Straße',
            'HOUSENUMBER' : 'Hausnummer',
            'FORCHECKPOINTMANAGERS' : 'Für den Checkpointmanager',
            'FORATHLETES' : 'Für den Athleten',
            'TITLE_ALERT' : 'Bitte geben Sie einen Titel (sofern vorhanden) an',
            'SMS_ALERT' : 'Bitte geben Sie eine Telefonnummer für SMS an',
        }
      };
}
