import { Component, OnInit } from '@angular/core';
import { Athlete } from '../classes/Athlete/Athlete';
import { AthletLiveinfo } from '../classes/Athlete/AthleteLiveinfo';
import { AthleteLiveinfoService } from '../socket-services/athlete-liveinfo.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilteredOrderedListRequest } from '../table-directives/filtered-ordered-list-request';
import { ThrowStmt } from '@angular/compiler';
import { isArray } from 'util';
import { EventSelectorService } from '../global-services/event-selector.service';
import { FilterType } from '../table-directives/field-filter';



@Component({
  selector: 'app-page-live-athlete-list',
  templateUrl: './page-live-athlete-list.component.html',
  styleUrls: ['./page-live-athlete-list.component.scss']
})
export class PageLiveAthleteListComponent implements OnInit {
  public eventId: number;
  public filteredOrderedListRequest: FilteredOrderedListRequest;
  public liveList: Array<any>;
  private liveInfoSubscription: Subscription;
  private eventSubscription: Subscription;
  public closed: boolean;
  public filter: Array<any>;
  public filterfields: Array<any>;
  public loading: boolean;

  constructor(private athletelive: AthleteLiveinfoService,
              private eventService: EventSelectorService,
              private route: ActivatedRoute, ) {
    this.closed = true;
    this.filter = [false, false, false, false];
    this.filterfields = ['RUNNING_IN_TIME', 'RUNNING_OUT_OF_TIME', 'GIVEN_UP', 'PASSED'];
    this.liveList = [];

    this.loading = false;

  }

  ngOnInit() {

    this.eventSubscription = this.eventService.subscribeEventSelection().subscribe((eventId: number) => {
      this.eventId = eventId;
      if (this.liveInfoSubscription !== null && this.liveInfoSubscription !== undefined) {
        this.liveInfoSubscription.unsubscribe();
      }

      this.liveInfoSubscription = this.athletelive.subscribeToEvent(this.eventId).subscribe((message) => {
        if (isArray(message)) {
          message.forEach((entry) => {
            this.checkMessageInArray(entry);
          });

        } else {
          this.checkMessageInArray(message);
        }


      }, (error) => {

      }, () => {
        console.log('Completed');
      });



    });

    this.filteredOrderedListRequest = new FilteredOrderedListRequest();

  }

  sortChanged() {

  }

  toggleFilterOverlay() {
    this.closed = !this.closed;
  }

  toggleSelection(index: number) {
    if (this.filter[index]) {
      this.filter[index] = false;
    } else {
      this.filter[index] = true;
    }
  }

  applyfilter() {
    const filterfield: Array<any> = [];
    for (let i = 0; i < this.filter.length; i++) {
      if (this.filter[i]) {
        filterfield.push({ filterType: FilterType.IN,
          filtervalues: [this.filterfields[i]]
        });
      }
      this.filteredOrderedListRequest.filters = filterfield;
    }
  }

  checkMessageInArray(newMessage: any) {
    let found = false;
    this.liveList.forEach((oldMessage: any) => {
      if (newMessage.startnumber === oldMessage.startnumber) {
        console.log('sd');
        found = true;
        oldMessage = newMessage;

      }
    });
    if (found === false) {
      this.liveList.push(newMessage);
    }
  }

  getExpectedInTime(time: number) {
    const seconds = parseInt((time / 1000).toString(), 10) % 60;
    const minutes = parseInt((time / 60000).toString(), 10);

    return minutes + ':' + seconds;
  }

}
