import { Params } from '@angular/router';

export class TableSortFilterPaginate {
  //Pagination

  public limit:number;



  //die page number wird manchmal als String übergeben, deshalb wird hier die page number immer zur number konvertiert
  set page(value) {
    if(typeof value !== 'number') {
      this._page = Number(value);
    } else {
      this._page = value;
    }
  }
    get page() {
      return this._page;
    }

  set pageCount(value){
    this.pages=new Array();

    for(let i = 1; i <= value; i++){
      this.pages.push(i);
      this._pageCount=value;
    }
    
    console.log(this._pageCount);
  }

  get pageCount() {
    return this._pageCount;
  }

  //BaseFilter
  public baseFilter:string;
  public finder:string;

  //Sorting
  public sort:string;
  public direction:string;

  //Selection
  public selected:number[];

  //Searching
  public search:string;

  //Filtering
  public filter:string;
  public filterValues:any[];

  //ServicePaginationStuff

  public pages:number[];

  //Internal Stuff
  private _pageCount:number=0;
  private _urlParameterFields:string[] = ['page', 'limit', 'baseFilter', 'sort', 'direction', 'search', 'filter' , 'filterValues','finder'];
  private _page:number;

  constructor(){
    this.selected = new Array();
  }

  public setFromUrlParams(queryParams:Params){
    for(let parameter of this._urlParameterFields){
      if(this.checkParameterValue(queryParams[parameter])){
        this[parameter] = queryParams[parameter];
      }
    }
  }

  public setBaseFilter(baseFilter:string){
    this.baseFilter = baseFilter;
  }

  public removeFilters(){
    this.filter = null;
    this.filterValues = new Array();
  }

  public processServerResponse(serverPagination:any){
    this.pageCount = serverPagination.pageCount;
  }

  public getNavigationParameter():any{
    var result = {};
    for(let parameter of this._urlParameterFields){

      if(this.checkParameterValue(this[parameter])){
        result[parameter] = this[parameter];
      }
    }

    return result;
  }

  public getAsUrlParamters():string{
    var firstFlag = true;
    var paginationUrlParameters = "";
    for(let parameter of this._urlParameterFields){
      if(this.checkParameterValue(this[parameter])){
        if(firstFlag){
          paginationUrlParameters += "?";
          firstFlag = false;
        }else{
          paginationUrlParameters += "&";
        }

        if(parameter == 'search'){
          console.log("Search writing");
        }

        paginationUrlParameters += parameter + "=" + this[parameter];
      }
    }

    console.log(paginationUrlParameters);

    return encodeURI(paginationUrlParameters);
  }

  public checkParameterValue(value:any):boolean{
    let re = (value != null) && (value != '');

    return re;
  }

  getPageCount(){
    return this.pageCount;
  }
}
