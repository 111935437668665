export class GeneralTranlsation {
    translation = {
        de : {
            'DASHBOARD' : 'Dashboard',
            'LIVEUEBERWACHUNG' : 'Liveüberwachung',
            'LAEUFE' : 'Läufe',
            'LAUFDETAILS' : 'Laufdetails',
            'DISZIPLINEN' : 'Disziplinen',
            'LAEUFER' : 'Läufer',
            'NUTZERVERWALTUNG' : 'Nutzerverwaltung',
            'EINSTELLUNGEN' : 'Einstellungen',
            'SUPPORT' : 'Support',
            'LOGOUT' : 'Logout',
            'BENUTZERNAME' : 'Benutzername',
            'PASSWORT' : 'Passwort',
            'LOGIN' : 'Login',
            'SAVE' : 'Speichern',
            'DISZIPLIN' : 'Disziplin',
            'NATION' : 'Nation',
            'TEAM' : 'Team',
            'EMAIL' : 'Email',
            'TELEFON' : 'Telefon',
            'VORNAME' : 'Vorname',
            'NACHNAME' : 'Nachname',
            'STRASSE' : 'Straße',
            'HAUSNUMMER' : 'Hausnummer',
            'PLZ' : 'Postleitzahl',
            'TOWN' : 'Stadt',
            'JA' : 'Ja',
            'NEIN' : 'Nein',
            'VERANSTALTUNGEN' : 'Veranstaltungen',
            'GPS_DEVICES' : 'GPS Geräte',
            'ZEIGE_ALLE' : 'Alle zeigen',
            'WICHTIGE_MELDUNGEN' : 'wichtige Meldungen',
            'DASHBOARD_DESCRIPTION' : 'Übersicht über alle Events',
            'AKTIONEN' : 'Aktionen',
            'LAENGE' : 'Länge',
            'STARTNUMMER' : 'Startnummer',
            'APPCODE' : 'Appcode',
            'APP_STATUS' : 'Appstatus',
            'EXPORT_ATHLETES' : 'Export',
            'VERANSTALTUNGSVERWALTUNG' : 'Veranstaltungsverwaltung',
            'TRACKER' : 'Tracker',
        }
    };
}
