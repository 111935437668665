export class LaufmanagementGpsdeviceallocationTranslation {
    translation = {
        de: {
           'EVENT_GPS_DEVICE_ALLOCATION' : 'GPS Geräte Zuweisung',
           'EVENT_GPS_DEVICE_ALLOCATION_LIST' : 'GPS Geräte Zuweisungsliste',
           'NEUZUWEISEN' : 'Neu zuweisen',
           'ATHLETELIST_ERROR' : 'Bitte geben Sie einen Namen für die Athletenliste an',
           '' : '',
        }
      };
}
