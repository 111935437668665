import { Component, OnInit, OnChanges, Input, ErrorHandler } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TableSortFilterPaginate } from '../table-sort-filter-paginate';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-table-massoperation-selection',
  templateUrl: './table-massoperation-selection.component.html',
  styleUrls: ['./table-massoperation-selection.component.scss']
})
export class TableMassoperationSelectionComponent implements OnInit {
  @Input() possibleOperations: any
  @Input() pagination: TableSortFilterPaginate;
  @Input() rootElement: any;

  public selectedOperation: any;
  public options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    useBom: true
  };

  constructor(private toasterService: ToasterService,
              private http: HttpClient) {
  }

  ngOnInit() {
    console.log(this.possibleOperations);
  }

  executeMassOperation() {
    if(this.selectedOperation!=undefined){
      if(this.pagination.selected.length>0){
        this.possibleOperations.service.executeMassOperation(this.selectedOperation, this.pagination).subscribe(success => {

            this.toasterService.pop('success', 'Massenoperation erfolgreich ausgeführt', success.message);


          this.rootElement.refreshTable(this.pagination);
        }, error => {
            this.toasterService.pop('error', 'Massenoperation konnten nicht ausgeführt werden', error);
        });
      }else{
        this.toasterService.pop('error', 'Bitte wählen Sie mindestens einen Datensatz aus');
      }
    }else{
      this.toasterService.pop('error', 'Bitte wählen Sie eine Massenoperation aus');
    }

  }

}
