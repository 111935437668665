import { Pipe, PipeTransform } from '@angular/core';
import { FilteredOrderedListRequest } from '../../table-directives/filtered-ordered-list-request';
import * as _ from 'lodash';

@Pipe({
  name: 'sortAndFilterBy', pure: false
})
export class SortAndFilterByPipe implements PipeTransform {

  transform(value: any[],  filter:FilteredOrderedListRequest): any[] {
    console.log(filter.filters);
    let list=value;
    let orderlist=[];
    let columnlist=[];
    let filtervalues=[];
    for(let sorter of filter.sorters){
      if(sorter.direction){
        orderlist.push("asc");
      }else{
        orderlist.push("desc");
      }     
      columnlist.push(sorter.datafield);
    }
    for(let field of filter.filters){    
        filtervalues.push(field.filtervalues[0]);
    }
    if(filtervalues.length!=0){
      list=_.filter(list, function(o) { 
        return filtervalues.includes(o.athleteStatus);    
      });
    }
   
  
    return _.orderBy(list,columnlist , orderlist);
  }
}

