export class NotificationsTranslation {
    translation = {
        de: {
            'ATHLETE_UNKNOWN' : 'Athlet unbekannt',
            'ATHLETE_NOT_STARTED' : 'Athlet noch nicht gestartet',
            'CHECKPOINTMANAGER_INPUTERROR' : 'Eingabefehler Checkpointmanager',
            'CHECKPOINTMANAGER_CORRECTION' : 'Eingabekorrektur Checkpointmanager',
            'ATHLETE_GIVEUP' : 'Athlet hat aufgegeben',
            'RUN_THROUGH' : 'Durchlauf',
            'GIVE_UP' : 'Aufgabe',
            'FASTINPUT_HEADER' : 'Schnelleingabe',
            'ERLEDIGT' : 'Erledigt',
            'AUF_MERKLISTE_SETZEN' : 'Auf Merkliste Setzen'
        }
    };
}
