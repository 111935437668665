import {tap} from 'rxjs/operators';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { decodeToken, getTokenExpirationDate, isTokenExpired } from 'jwt-node-decoder';
import { ToasterService } from 'angular2-toaster';
import { AuthenticationService } from '../global-services/authentication.service';
import { AuthenticationFactoryService } from '../global-services/authentication-factory.service';
import { EventSelectorService } from '../global-services/event-selector.service';
// const jwtDecode = require('jwt-decode');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authService: AuthenticationService;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private toasterService: ToasterService,
                private eventSelectorService: EventSelectorService,
                private authFactory: AuthenticationFactoryService) {
        this.authService = authFactory.getAuthenticationService();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       // const token = jwtDecode.getTokenExpirationDate(localStorage.getItem('authToken'));
       const token = localStorage.getItem('authToken');

       let headers;
       let normalized_url = request.url;

       if (request.url.includes('{:eventId}')) {
         normalized_url = request.url.replace('{:eventId}', String(this.eventSelectorService.getSelectedEvent()));
       }

       if (token === null || token === undefined) {
         console.log('No token')
            // Prüfe ob dies ein login Request ist, wenn nicht leite weiter auf das Login Formular

            if (!request.url.includes('login')) {
              this.authService.logout().then(
                resolve => {
                  localStorage.removeItem('authToken');
                  localStorage.removeItem('mandantToken');
                  this.router.navigate(['login', {message: 'Sitzung ist abgelaufen!'}]);
                },
                reject => {});
              return;
            }  else if (request.url.includes('livetracking-administration/upload-eventlogos')) {
              console.log('Set Content type to');
              headers = {
                'Content-Type': 'multipart/form-data'
              };
             } else {
              headers = {
                      'Content-Type': 'application/json'
                  };
            }
       } else if (request.url.includes('livetracking-administration/upload-eventlogos')) {
        console.log('Set Content type to');
        // headers = {
        //   'Content-Type': 'application/json'
        // };
       } else {
         headers = {
                 'Content-Type': 'application/json',
                 'Liveadminauthtoken': localStorage.getItem('authToken')
             };
       }

        const authRequest = request.clone({'url' : normalized_url, 'headers': new HttpHeaders(headers)});

        return next.handle(authRequest).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with Response
            } else {
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this.router.navigate(['login']);
                } else {
                    console.error('Unhandeld Response Code', err);
                }
            } else {
                console.error('Unhandeld Http Error', err);
            }
        }));
    }
}
